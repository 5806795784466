/** Dependencies **/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

/** Class object */
import wsActions from './../../../../helpers/webservice/wsActions.class.js';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';

/** Helpers **/
import { getStylesSelectMenu } from './../../../../helpers/ui';
import { getPicto } from './../../../../helpers/pictos';

/** Components **/
import TableCellCheckbox from './../TableCells/Checkbox';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

function Keywords(props) 
{
  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedRowsID = useSelector( state => state.selectedRowsID.value );
  const datas = useSelector( state => state.datas.value );
  const barometerContext = useSelector( state => state.barometerContext.value );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Init status loading **/
  const [openPopin, setOpenPopin] = useState( false );
  const [massActionValue, setMassActionValue] = useState( null );
  const [massActionLaunch, setMassActionLaunch] = useState( false );

  /** Get actions */
  const actions = globalConfig.barometer.action[barometerContext];

  /** Loading options table actions from redux values **/
  let optionsActions = [];
  if ( actions instanceof Array ) 
  {
    actions.forEach(action => {
      optionsActions.push({
        value: action.value,
        label: action.label.toUpperCase()
      })
    });
  }

  /** Instance Webservice Actions class */
  const wsActionsClass = new wsActions(
    selectedFilters,
    'global',
    datas.globalResult,
    dispatch,
    selectedInstance
  );

  /** Call function to add/remove/update value for action for selected keyword **/
  const handleChange = value => 
  {    
    // open popin
    setOpenPopin( true );

    // set mass action values
    setMassActionValue( value );
  }

  const handleResetClick = () => 
  {
    // set mass action values
    setMassActionValue( null );

    // open popin
    setOpenPopin( false );
  }

  const handleConfirmClick = () => 
  {
    // set mass action values
    setMassActionLaunch( true );

    // open popin
    setOpenPopin( false );
  }

  useEffect(() => 
  {
    if( 
      massActionLaunch === true 
      && massActionValue.value !== null
      && selectedRowsID instanceof Array
      && selectedRowsID.length > 0 
    ){
      const entries = selectedRowsID.map( row => ({
        label: row.keywords[0],
        actionID: row?.actionID !== undefined ? row.actionID : null,
        categories: row?.categories[0] !== undefined ? row.categories : [ 'N.C.', 'N.C.', 'N.C.' ],
        actionSelectedLastUpdate: row?.lastUpdate !== undefined ? row.lastUpdate : null,
        actionSelectedUsersIDs: row?.usersIDs !== undefined ? row.usersIDs : null
      }));

      /** Update Status of all keyword */
      wsActionsClass.addOrUpdateActions( 
        entries, 
        parseInt( massActionValue.value ),
        barometerContext,
        true
      );

      /** Actions after updating status of current keywords */
      entries.forEach( entry =>
      {
        // If New status
        if( entry.actionID === null )
          wsActionsClass.actionsForNewStatus( 
            massActionValue.value,
            entry.label, 
            entry.categories, 
            entry.actionSelectedLastUpdate, 
            entry.actionSelectedUsersIDs
          );

        // If Update status
        else
          wsActionsClass.actionsForUpdateStatus( 
            massActionValue.value,
            entry.actionID, 
            entry.label, 
            entry.categories, 
            entry.actionSelectedLastUpdate, 
            entry.actionSelectedUsersIDs
          );
      });

      // reset massActionLaunch value
      setMassActionLaunch( false );

      // reset massActionLaunch value
      setMassActionValue( null );
    }
  }, [massActionLaunch]);

  return (
    <div className="keyword-container">
      <Popup open={openPopin} onClose={() => setOpenPopin(false)} position="right center">
        <div className="description">
          {getPicto('ExclamationTriangleFill', { size: "5rem" })}
          <p>ATTENTION !<br /><br />Vous vous apprêtez à modifier le status de {selectedRowsID.length} ligne{selectedRowsID.length>1?'s':''}, cette opération est irréversible !<br /><br />Êtes-vous sûr·e de vouloir faire cela ?</p>
        </div>
        <div className="confirm-container">
          <button className="reset" onClick={() => handleResetClick()}>annuler</button>
          <button className="confirm" onClick={() => handleConfirmClick()}>OK, je confirme</button>
        </div>
      </Popup>
      <TableCellCheckbox {...props} />
      <label htmlFor={'keyword-massaction'}>action groupée</label>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={optionsActions}
        styles={getStylesSelectMenu()}
        placeholder=''
        value={null}
        components={{
          IndicatorSeparator: () => null
        }}
        onChange={ value => handleChange( value )}
      />
    </div>
  );
}

export default Keywords;