/** Components **/
import PerfFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/PerfFilterToolTip';
import TableHeaderMyPerf from './../../../components/Dashboards/Barometer/TableHeaders/MyPerf';
import PerfsTotalCell from './../../../components/Dashboards/Barometer/TableTotalCells/PerfsTotalCell';
import PerfsTotalCellUrls from './../../../components/Dashboards/Barometer/TableTotalCells/PerfsTotalCellUrls';
import TableCellPerfs from './../../../components/Dashboards/Barometer/TableCells/Perfs';

export const PerfsColumn = ( barometerContext, widthColumns, instanceConfig ) => 
{
  const groups = instanceConfig.groups;

  let perfsColumns = [];  
  if( groups.length > 0 )
  {
    // groups from assets/json/config.json
    perfsColumns = [...perfsColumns, ...groups.map( elem => (
      {
        accessor: "perf" + elem.value,
        width: widthColumns["perf" + elem.value],
        title: elem.label,
        className: "col-perfs",
        Filter: PerfFilterToolTip,
        filter: 'multiFieldsCustomFilter',
        sortDescFirst: true,
        sortType: 'bestPositionSort',
        Header: <TableHeaderMyPerf id={'perf' + elem.value} />,
        Total: props => 
          barometerContext === 'urls' ?
            <PerfsTotalCellUrls id={props.column.id} />
            : <PerfsTotalCell id={props.column.id} />,
        Cell: props => 
          <TableCellPerfs 
            id={elem.value + '-' + props.cell.row.id}
            accessor={elem.value}
            label={props.cell.row.values.label}
            keywords={props.cell.row.original.keywords}
            assignedKeywords={props.cell.row.original['keywords-assigned']}
            category1={props.cell.row.original.category1} 
            category2={props.cell.row.original.category2} 
            category3={props.cell.row.original.category3} 
            values={props.cell.value}
            expectedUrl={props.cell.row.original.expectedUrl}
          />
      }
    ))];
  }

  return perfsColumns;
}