/** Dependencies **/
import React from "react";
import { useSearchParams } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedHeaderFilters } from '../../../../reducers/selectedHeaderFilters';

/** Helpers **/
import { getPicto } from '../../../../helpers/pictos';

function PictoFilterRemove ( props )
{
  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );

  /** Get from props **/
  const id = props.id;
  let value = props.value;

  /** Init Search Params */
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = ( elem ) => 
  {
    // remove value from selected header filters array
    const selectedfilters = {...selectedHeaderFilters[id]};
    selectedfilters.value.splice( selectedfilters.value.indexOf(elem), 1 );
    
    // reset current id filter
    if( selectedfilters.value.length > 0 )
      dispatch( updateSelectedHeaderFilters( { filterName: id, value: selectedfilters } ) ); 
    else
      dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) ); 

    // remove url filter param
    searchParams.delete( 'filters' );
    setSearchParams( searchParams );
  }

  return (
    value.map( ( elem, index ) => (
      <div key={index} className='column-header-reminder picto'>
        <div>{getPicto( elem, { size:"1rem" } )}</div>
        <button onClick={() => handleClick( elem ) }>{getPicto( 'closer', { size:"1rem" } )}</button>
      </div>
    ))
  )
}

export default PictoFilterRemove;