/** Dependencies **/
import React, { useEffect, useState } from 'react';
import Sticky from 'react-sticky-el'
import { Routes, Route } from "react-router-dom";
import { hasAuthenticated, logout } from './../../services/AuthApi';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { revertAll } from './../../store';
import { updateConfig } from './../../reducers/config';
import { updateEnabledInstances } from './../../reducers/enabledInstances';

/** Contexts */
import Auth from "./../../contexts/Auth";

/** JSON */
import globalConfig from './../../assets/json/config.json';

/** SCSS **/
import 'normalize.css';
import './App.scss';

/** Components **/
import HeaderNavAccounts from './../NavFilters/HeaderNavAccounts';
import LeftNavMenu from './../NavFilters/LeftNavMenu';
import AuthenticatedRoute from '../Routes/AuthenticatedRoute';
import Loader from './../Loader';

/** Helpers */
import { callWebservice } from './../../helpers/webservice/webserviceCaller';
import { getItem } from './../../services/LocaleStorage';
import { getPicto } from './../../helpers/pictos';

/** Routes and Contents **/
import Login from './../Dashboards/Login/Login';
import Home from './../Dashboards/Home/Home';
import Barometer from './../Dashboards/Barometer/Barometer';

function App() 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
	const togglerStore = useSelector( state => state.togglers.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );
  const instanceConfig = useSelector( state => state.config.value );  
  
  /** Init state **/
  const [isAuthenticated, setIsAuthenticated] = useState( false );

  /**
   * logout and set authenticated to false on click to logout button
   */
  const handleLogout = () => 
  {
    logout();
    setIsAuthenticated( false );
    dispatch( updateEnabledInstances( [] ) );
  }

  /** Init authenticate */
  useEffect( () => 
  {
    async function initAuthenticate()
    {
      try
      {
        // check authenticate
        const response = await hasAuthenticated();

        if( response.response === true )
        {
          // set authenticated to true
          setIsAuthenticated( true );

          // dispatch enabled instances for user
          dispatch( updateEnabledInstances( response.data.enabled_instances ) );

        } else {

          // logout
          handleLogout();

          // dispatch reset enabled instances
          dispatch( updateEnabledInstances( [] ) );
        }

      } catch ( error ) {
        console.error( error );
      }
    }

    initAuthenticate();

  }, [] );

  /** Load config with selected instance */
  useEffect( () => 
  {
    if( selectedInstance !== null )
    {
      // reset redux store
      dispatch( revertAll() );

      // load instance config
      callWebservice(
        'configuration',
        'get-config',
        'get-config',
        dispatch,
        selectedInstance,
        { clientID: selectedInstance },
        { 
          function: 'updateConfig',
          params: { id: 'config' }
        },
        'Chargement de la configuration'
      );
    }

  }, [selectedInstance]);

  return (
    <React.Fragment>      
      <header>
        <h1>
          <span className="title">Observatoire</span>
        </h1>
        {isAuthenticated === true ?
          <React.Fragment>
            <HeaderNavAccounts />        
            <div className='userinfos'>
              <div className='identity'>{getItem('firstName') + ' ' + getItem('lastName')}</div>
              <button onClick={handleLogout} className='logout'>{getPicto( 'LogOut', {size: '1.5rem'})}</button>
            </div>
          </React.Fragment>
          : false
        }
      </header>
      {isAuthenticated === true ?
        <aside className={(togglerStore['leftNavMenuToggle'] === undefined || !togglerStore['leftNavMenuToggle'])?'inactive':''}>
          <Sticky>
            <LeftNavMenu />
          </Sticky>
        </aside>
        : false
      }
      <section>
        <div className="dashboard-wrapper"> 
          <Loader 
            loaderID="configuration" 
            loaderStyle={{
              width:'66', 
              stroke:'#e43e21', 
              viewBox:'-2 -2 42 42'
            }} 
            callBackFcts={{
              updateConfig: result => dispatch( updateConfig( result ) )
            }}
            globalCallBack={undefined}
          />
          <Auth.Provider value={{isAuthenticated, setIsAuthenticated}}>
            <Routes>
              <Route index 
                path={process.env.PUBLIC_URL + "/login"} 
                element={
                  <Login />
                } 
              />
              <Route 
                path={process.env.PUBLIC_URL}
                element={
                  <AuthenticatedRoute 
                    element={
                      Object.keys( instanceConfig ).length > 0 ? <Home /> : null
                    } 
                  />
                }
              />
              <Route 
                path={process.env.PUBLIC_URL + "/keywords"}
                element={
                  <AuthenticatedRoute 
                    element={
                      Object.keys( instanceConfig ).length > 0 ? <Barometer context={'keywords'} /> : null
                    } 
                  />
                }
              />
              <Route 
                path={process.env.PUBLIC_URL + "/categories"}
                element={
                  <AuthenticatedRoute 
                    element={
                      Object.keys( instanceConfig ).length > 0 ? <Barometer context={'categories'} /> : null
                    } 
                  />
                }
              />
              <Route 
                path={process.env.PUBLIC_URL + "/urls"}
                element={
                  <AuthenticatedRoute 
                    element={
                      Object.keys( instanceConfig ).length > 0 ? <Barometer context={'urls'} /> : null
                    } 
                  />
                }
              />
              <Route 
                path={process.env.PUBLIC_URL + "/gsc-keywords"}
                element={
                  <AuthenticatedRoute 
                    element={
                      Object.keys( instanceConfig ).length > 0 ? <Barometer context={'gsc-keywords'} /> : null
                    } 
                  />
                }
              />
              <Route path="*" element={<div>404</div>} />
            </Routes>
          </Auth.Provider>
        </div>
      </section>      
      <footer>© Copyright EK X TMW - {new Date().getFullYear()}{process.env.REACT_APP_VERSION !== '' ? ' - ' + process.env.REACT_APP_VERSION : ''}</footer>
    </React.Fragment>
  );
}

export default App;