/** Dependencies **/
import React, { useState, useRef, useEffect } from "react";
import Autosuggest from 'react-autosuggest';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../../../reducers/selectedHeaderFilters';

import {
  getSuggestionValue,
  renderSuggestion,
  onSuggestionsFetchRequested
} from './../../../../../helpers/autosuggest';

/**
 * Define a default UI for Range Fitler
 * @param {object} {column: { filterValue, preFilteredRows, setFilter }...}
 * @returns ReactDOM with input
 */
function AutoSuggestFilter ( props )
{
  /** Get props **/
  const id = props.column.id;
  const preFilteredRows = props.column.preFilteredRows;
  const toolTipShown = props.toolTipShown;
  const optionsSelectFilter = props.optionsSelectFilter;
  const filterFct = props.filterFct;
  const filterType = props.filterType;
  const filterParams = props.filterParams;
  
  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );

  // define status for custom values
  const [optionFilter, setOptionFilter] = useState( 'contains' );
  const [suggestions, setSuggestions] = useState( [] );
  const [inputValue, setInputValue] = useState( '' );
  const [selectedValues, setSelectedValues] = useState( [] );

  // define Ref
  const inputElement = useRef( null );

  /**
   * set suggestion array after apply custom filter for string
   * @param {Object} 
   */
  const fetchRequestedValue = value => 
  {
    let suggestions = [];

    if( value !== '' )
    {
      suggestions = [...new Set(
        filterFct( 
          preFilteredRows, 
          id,
          {
            value: value, 
            option: optionFilter,
            usefor: 'autosuggest',
            selectValues: 
              selectedValues.length === 0 
              && optionsSelectFilter instanceof Array ? 
                optionsSelectFilter.map( option => option.value ) 
                : selectedValues   
          },
          filterParams 
        ).slice( 0, 10 ).sort()
      )];
    }

    return suggestions;
  };
  
  /**
   * Valid search filter
   * @param {string} inputValue 
   */
  const validURLSearchFilter = ( inputValue ) => 
  {
    // save value into redux store
    if( inputValue !== '' )
    {
      let options = {
        type: 'text',
        label: inputValue,
        option: optionFilter,
        usefor: 'filter',
        selectValues: 
          selectedValues.length === 0 
          && optionsSelectFilter instanceof Array ? 
            optionsSelectFilter.map( option => option.value ) 
            : selectedValues,
        value: {
          field: id + '-text',
          type: filterType,
          result: inputValue
        },
        params: filterParams
      };
      
      dispatch( updateSelectedHeaderFilters( { filterName: id, value: options } ) ); 
    }
  }

  /**
   * Reset search field
   */
  const resetURLSearchFilter = () => 
  {
    // reste values
    onSuggestionsFetchRequested( { value: '' }, [], setSuggestions );
    setOptionFilter( 'contains' );
    setInputValue( '' );

    // reset value for redux
    dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) )
  }

  /**
   * Update list with selected filter and update class of button
   * @param {DOMElement} elements 
   */
  const updateSelectFilter = ( elements ) =>
  {
    // insert elements into array if not
    if( !( elements instanceof Array ) )
      elements = [elements];
    
    // clone selected snippets array
    let cloneSelectedValues = [...selectedValues];

    elements.forEach( element => 
    {
      const value = element.value;
      
      if( !cloneSelectedValues.includes( value ) )
        cloneSelectedValues = [...cloneSelectedValues, value];
      else
        cloneSelectedValues.splice( cloneSelectedValues.indexOf( value ), 1 );
    });

    setSelectedValues( cloneSelectedValues );
  }

  useEffect( () => 
  {
    if( toolTipShown === true )
      inputElement.current.input.focus();

  }, [toolTipShown]);

  // set Autosuggest input props
  const inputProps = {
    placeholder: '',
    value: inputValue || '',
    type: 'text',    
    onChange: ( e, { newValue } ) => setInputValue( newValue ),
    onKeyDown: e => e.key === 'Enter' ? validURLSearchFilter( inputValue ) : false
  };

  return (
    <React.Fragment>      
      
      <div className="options-container">
        <input 
          onChange={ e => setOptionFilter( e.target.value ) } 
          type="radio" 
          value="contains" 
          name={"filter-type-" + id} 
          id={"option-filter" + id + "-1"} 
          checked={optionFilter === 'contains' ? true : false}
        /><label htmlFor={"option-filter" + id + "-1"}>Contient</label>
        <input 
          onChange={ e => setOptionFilter( e.target.value ) } 
          type="radio" 
          value="notcontains" 
          name={"filter-type-" + id} 
          id={"option-filter" + id + "-2"}
          checked={optionFilter === 'notcontains'? true : false}
        /><label htmlFor={"option-filter" + id + "-2"}>Ne contient pas</label>
        <input 
          onChange={ e => setOptionFilter( e.target.value ) } 
          type="radio" 
          value="exactly" 
          name={"filter-type-" + id} 
          id={"option-filter" + id + "-3"}
          checked={optionFilter === 'exactly' ? true : false}
        /><label htmlFor={"option-filter" + id + "-3"}>Correspondance exacte</label>
        <input 
          onChange={(e) => setOptionFilter( e.target.value ) } 
          type="radio" 
          value="regex" 
          name={"filter-type-" + id} 
          id={"option-filter" + id + "-4"}
          checked={optionFilter === 'regex' ? true : false}
        /><label htmlFor={"option-filter" + id + "-4"}>Expression régulière</label>
      </div>    

      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={ values => onSuggestionsFetchRequested( values, fetchRequestedValue, setSuggestions ) }
        alwaysRenderSuggestions={true}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={ suggestions => renderSuggestion( suggestions, inputValue ) }
        inputProps={inputProps}
        ref={inputElement}
      />

      {optionsSelectFilter && optionsSelectFilter.length > 0 ?
        <ul className="preselect">
          {optionsSelectFilter.map( ( filter, index ) => {
            return (
              <li key={index}>
                <button 
                  className={
                    (
                      (
                        selectedHeaderFilters[id] 
                        && selectedHeaderFilters[id].filter( selectedFilter => 
                          selectedFilter.value.field === id + '-selectFilter' 
                          && selectedFilter.value.result 
                          && selectedFilter.value.result.includes( filter.value )
                        ).length > 0 
                      ) 
                      || 
                      ( selectedValues.includes( filter.value ) )
                    )
                    ? 'on' : '' 
                  }
                  onClick={ e => updateSelectFilter( e.currentTarget ) } 
                  value={filter.value}
                >
                  {filter.label}
                </button>
              </li>
            );
          })}
        </ul>
        : false
      }

      <div className="confirm-container">
        <button className="reset" onClick={() => resetURLSearchFilter()}>annuler</button>
        <button className="confirm" onClick={() => validURLSearchFilter( inputValue )}>APPLIQUER</button>
      </div>
    </React.Fragment>
  )
}

export default AutoSuggestFilter;