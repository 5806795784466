/** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import Loader from '../../../Loader';

/** Helpers **/
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

function ActionsHistory( props ) 
{
	// Get props
	const{
		label,
		id
	} = props;

	/** Get state from redux store */
  const barometerContext = useSelector( state => state.barometerContext.value );  
	const selectedFilters = useSelector( state => state.selectedFilters.value );
	const selectedInstance = useSelector( state => state.selectedInstance.value ); 

	// get actions
	const actions = globalConfig.barometer.action[barometerContext];

	// Define ref
	const tooltipActionsHistory = useRef( null );

  /** Init state **/
	const [history, setHistory] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

	/**
	 * Return data for keyword 12 months trend
	 * @param {string} keyword 
	 * @param {int} id 
	 * @returns data for trend
	 */
	const loadActions = () => 
	{
		if( label.trim() !== '' )
		{
			callWebservice(
				id,
				id,
				'get-actions',
				dispatch,
				selectedInstance,
				{
					entries: [label],
					where: {
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
					context: barometerContext
				},
				{ function: 'setHistory' }
			);
		}
	}

	return (
		<ReactTooltip 
			id={props.id}
			ref={tooltipActionsHistory}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterShow={loadActions}
			afterHide={() => {
				tooltipActionsHistory.current.tooltipRef.style.left = null;
				tooltipActionsHistory.current.tooltipRef.style.top = null;
			}}
			globalEventOff={'click'}
			isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
				let height = node.offsetHeight;
        
        return {
          left: - width / 2 - 170,
          top: - height / 2 + 10
        };
      }}
		>
			<div className='tooltip-container history'>
				<Loader 
					loaderID={id} 
					loaderStyle={{width:'15', stroke:'#e43e21'}} 
					callBackFcts={{
						setHistory: setHistory
					}}
					globalCallBack={undefined} 
				/>
				<h4>Historique des changements d'action</h4>
				{						
					(
						history !== null 
						&& history[label]
					) ?
						<ul>
							{
								history[label][0].date !== null ?
									history[label].map( ( history, index ) => 
										<li key={index}>
											{history.date.toString().substring( 6, 8 )} / {history.date.toString().substring( 4, 6 )}  / {history.date.toString().substring( 0, 4 )} 
											&nbsp;-&nbsp;{history.user_first_name} {history.user_last_name} 
											&nbsp;:&nbsp;{
												history.updated_action_id === null ? 
													'À définir'
													: actions.filter( action => parseInt( action.value ) === parseInt( history.updated_action_id ) )[0]['label']
											}
										</li>
									)
									: false
							}
							{
								history[label][0].automatic_action_id !== null ? 
									<React.Fragment>										
										{history[label][0].date !== null ? <br /> : false}
										<li>Action suggérée : {actions.filter( action => parseInt( action.value ) === parseInt( history[label][0].automatic_action_id ) )[0]['label']}</li>
									</React.Fragment>
									: false
							}
						</ul>
					: <ul>
							<li>Aucun action enregistrée</li>
						</ul>
				}				
			</div>
		</ReactTooltip>
	);
}

export default ActionsHistory;