/** Dependencies **/
import {useEffect, useState, useRef} from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers */
import { formatTickValue } from '../../../../helpers/barometer/charts';

function DataVizTooltipPerfPos( props ) 
{
  /** Get props **/
  const { 
    x, 
    currentPerfTrend, 
    comparePerfTrend, 
    barWidth, 
    selectedGrp, 
    datum,
    compareDisplay,
    pageRangesDisplay
  } = props;

  /** Get state from redux store **/
  const instanceConfig = useSelector( state => state.config.value );  
  const togglers = useSelector( state => state.togglers.value ); 

  /** Init state **/
  const [tooltipOffsetX, setTooltipOffsetX] = useState( 0 );

  /** Set ref **/
  const tooltipContainer = useRef( null );

  /** Define labels for ranges */
  const rangeLabels = {
    top1: 'top 1',
    top23: 'top 2-3',
    top410: 'top 4-10',
    page2: 'page 2',
    page35: 'page 3 à 5',
    page610: 'page 6 à 10',
    nb_unranked_keywords: 'non classés'
  }

  /** Set array with compared date VS current date **/
  let matchesDates = {};
  if( comparePerfTrend.detailGrpDomains )
    Object.keys(currentPerfTrend.detailGrpDomains).forEach( ( currentDate, index ) => {
      matchesDates[currentDate] = parseInt( Object.keys(comparePerfTrend.detailGrpDomains)[index] )
    });
  
  /** Get datas with context multiple grp selected or not **/
  let currentDatas = {};
  let compareDatas = {};

  // single grp selected
  if( selectedGrp.length === 1 )
  {
    const configData = instanceConfig.groups.filter( grp => grp.value === selectedGrp[0] );
    const color = configData[0].currentColor;

    // current datas
    Object.keys( currentPerfTrend.positionsGrpDomains[selectedGrp[0]] )
    .filter( range => pageRangesDisplay[range] )
    .forEach( type => 
    {
      const filteredData = currentPerfTrend.positionsGrpDomains[selectedGrp[0]][type].filter( elem => 
        elem.x === datum.date
      );

      if( filteredData.length > 0 )
        currentDatas[type] = { value: filteredData[0].y, color: color } 
    });

    // compare datas
    if( comparePerfTrend.positionsGrpDomains )
      Object.keys( comparePerfTrend.positionsGrpDomains[selectedGrp[0]] )
      .filter( range => pageRangesDisplay[range] )
      .forEach( type => 
      {
        const filteredData = comparePerfTrend.positionsGrpDomains[selectedGrp[0]][type].filter( elem => 
          elem.x === matchesDates[datum.date]
        );

        if( filteredData.length > 0 )
          compareDatas[type] = { value: filteredData[0].y, color: color } 
      });

  // multiple grp selected
  } else {

    selectedGrp.forEach( grpName => 
    {
      const configData = instanceConfig.groups.filter( grp => grp.value === grpName );
      const label = configData[0].label;
      const color = configData[0].currentColor;

      // current datas
      const filteredCurrentData = currentPerfTrend.top10GrpDomains[grpName].filter( elem => 
        elem.x === datum.date
      );

      if( filteredCurrentData.length > 0 )
        currentDatas[label] = { value: filteredCurrentData[0].y, color: color }

      // compare datas
      let filteredCompareData = []
      if( comparePerfTrend.top10GrpDomains )
        filteredCompareData = comparePerfTrend.top10GrpDomains[grpName].filter( elem => 
          elem.x === matchesDates[datum.date]
        );

      if( filteredCompareData.length > 0 )
        compareDatas[label] = { value: filteredCompareData[0].y, color: color }
    });
  }

  /** Set variation array values **/
  let variationDatas = {};
  if( Object.keys( compareDatas ).length > 0 )
    Object.keys( currentDatas ).forEach( key => 
    { 
      if( compareDatas[key].value >= 0 )
        variationDatas[key] = {
          value: compareDatas[key].value - currentDatas[key].value, 
          color: currentDatas[key].color 
        };
    })

  /** Update x position for tooltip **/
  useEffect( () => 
  {
    if( tooltipContainer !== null )
    {
      const tooltipWidth = tooltipContainer.current.offsetWidth;
      if( x > 1350 / 2 )
        setTooltipOffsetX( - tooltipWidth - ( barWidth / 2 ) - 10 );
      else
        setTooltipOffsetX( ( barWidth / 2 ) + 10 );
    }
  }, []);

  return (
    <foreignObject x={x + tooltipOffsetX} y={30} width="500" height="200">
      <div className="tooltip grey position" ref={tooltipContainer} style={tooltipOffsetX === 0 ? {visibility: 'hidden'} : {}}>
        <div className="tooltip-container">
          {Object.keys( currentDatas ).length > 0 ?
            <ul>
              <li>{datum.tickValue}</li>
              {Object.keys( currentDatas ).reverse().map( ( key, index ) =>               
                <li key={index}>   
                  <hr style={{backgroundColor: currentDatas[key].color}} />
                  <span className='label'>{rangeLabels[key] ? rangeLabels[key] : key}</span>&nbsp;:&nbsp;{currentDatas[key].value}
                </li>
              )}
            </ul>
            : false
          }
          {compareDisplay && Object.keys( compareDatas ).length > 0 ?
            <ul>
              <li>{ formatTickValue( matchesDates[datum.date], togglers['radio-view-option'] ? 'month' : 'week' ) }</li>
              {Object.keys( compareDatas ).reverse().map( ( key, index ) =>               
                <li key={index}>   
                  <hr className='dash' style={{backgroundColor: compareDatas[key].color}} />
                  <span className='label'>{rangeLabels[key] ? rangeLabels[key] : key}</span>&nbsp;:&nbsp;{compareDatas[key].value}
                </li>
              )}
            </ul>
            : false
          }
          {compareDisplay && Object.keys( variationDatas ).length > 0 ?
            <ul>
              <li>VARIATION</li>
              {Object.keys( variationDatas ).reverse().map( ( key, index ) =>               
                <li key={index}>   
                  <span className='label'>{rangeLabels[key] ? rangeLabels[key] : key}</span>
                  &nbsp;:&nbsp;
                  <span className={variationDatas[key].value >= 0 ? "arrow up" : "arrow down" }>{variationDatas[key].value === 0 ? '' : String.fromCharCode( 0x21EA ) }</span>
                  {new Intl.NumberFormat("fr-FR", {  style: "decimal",  signDisplay: 'always' }).format( variationDatas[key].value )}
                </li>
              )}
            </ul>
            : false
          }
        </div>
      </div>
    </foreignObject>
  );
};

export default DataVizTooltipPerfPos;