/** Dependencies **/
import React from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import DefaultSortToolTip from './../SortToolTips/DefaultSortToolTip';
import RangeFilter from './Filters/RangeFilter';
import SelectFilter from './Filters/SelectFilter';
import AutoSuggestFilter from './Filters/AutoSuggestFilter';

/** Helpers */
import { 
  stringURLSnippetsCustomFilter
} from './../../../../helpers/barometer/customFilters';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

/**
 * Define a specific UI for Snippets filtering
 * @param {object} {column: { filterValue, preFilteredRows, setFilter }...}
 * @returns ReactDOM with input
 */
 function SnippetsFilterToolTip ( props )
{
  /** Get props **/
  const accessor = props.column.id;
  const preFilteredRows = props.column.preFilteredRows;

  /** Get state from redux store **/
  const snippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
    
  /** Define ranges for filter **/
  // % du taux d'occupation de la SERP
  let preselectFiltersTx = [
    {value: '10|100', label: '>= 10%' },
    {value: '25|100', label: '>= 25%' },
    {value: '50|100', label: '>= 50%' }    
  ];

  // Au dessus / Au dessous de la ligne de flottaison
  const preselectFilterAboveFold = [
    { value: 'Au dessus', label: 'Au dessus' },
    { value: 'Au dessous', label: 'Au dessous' }
  ];

  /** Get all snippets to build snippet select filter **/
  let currentSnippets = [];
  preFilteredRows.forEach( row => 
  {
    if( row?.values[accessor]?.currentTypesSnippets !== undefined )
      row.values[accessor].currentTypesSnippets.forEach( typeSnippet => 
      {
        // remove above fold from typeSnippet
        typeSnippet = typeSnippet.split( '-' )[1];

        if( !currentSnippets.some( snippet => snippet.label === typeSnippet ) )
          currentSnippets.push( { label: typeSnippet, value: typeSnippet } );
      });
  });

  return (
    <ReactTooltip 
      id={'filter-' + accessor}
      className="tooltip red"
      effect='solid'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="#e43e21"      
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        // set left value
        let left = currentTarget.offsetLeft - (width / 2);
        if( accessor === 'snippetsSEO' )
          left = currentTarget.offsetLeft - (width / 2) + 25;

        return {
          left: left,
          top: 17
        };
      }}
    >
      <div className="filter-container snippets">
        <DefaultSortToolTip column={props.column} id={accessor} />  
        <div className="filters">
          <div className="title"><h4>Filtrer</h4></div>
          <div className='filters-container'>
            <div className="filter range">            
              <RangeFilter 
                {...props} 
                title="taux d'occupation de la serp" 
                fieldName="taux-occupation"
                typeFilter="range"
                preselectFilters={preselectFiltersTx} 
                customFieldsDisp={false}
              />            
            </div>
            <div className='filter select'>
              <SelectFilter 
                {...props} 
                title="type de résultats" 
                fieldName="sous-snippets" 
                typeFilter="select-snippets"
                optionsValues={currentSnippets} 
                translateLabels={globalConfig.barometer.snippets}
              />
            </div>
            <div className="filter select">
              <SelectFilter 
                {...props} 
                title="Ligne de flottaison" 
                fieldName="above-fold" 
                typeFilter="select-above-fold"
                optionsValues={preselectFilterAboveFold}
              />
            </div>
          </div>
        </div>
        <div className="url">
          <div className="filter text"> 
            <div className="title"><h4>Chercher un domaine ou une URL</h4></div>
            <AutoSuggestFilter 
              {...props} 
              optionsSelectFilter={currentSnippets}
              filterFct={stringURLSnippetsCustomFilter}
              filterType='string-url-snippets'
              filterParams={{ snippetsDetails: snippetsDetails }}
            />
          </div>
        </div>
      </div>
    </ReactTooltip>
  )
}

export default SnippetsFilterToolTip;