/** Dependencies **/
import React from 'react';
import moment from 'moment';

function GSCIdentifierDate( props ) 
{
  // get value of identifier date
  let identifierDate = null
  if( props?.gscIdentifierDate?.value !== undefined )
    identifierDate = props.gscIdentifierDate.value;

  return (
    <div className="identifier-date-container">
      { identifierDate !== null ?
        <p className='value'>{ moment( identifierDate.toString(), 'YYYYMMDD' ).format( 'MMM - YY' ) }</p>
        : false
      } 
    </div>
  );
}

export default GSCIdentifierDate;