/** Dependencies **/
import React from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedDataVizTab } from "./../../../../reducers/selectedDataVizTab";

/** Helpers **/
import { getPicto } from './../../../../helpers/pictos';
import { getLastSnippetsUpdateDate } from './../../../../helpers/datas';

function PerfTab ( props )
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get current selected tab **/
  const selectedDataVizTab = useSelector( state => state.selectedDataVizTab.value );
  const datas = useSelector( state => state.datas.value );
  const barometerContext = useSelector( state => state.barometerContext.value );  
  
  /** Get current Perf Trend **/
  const currentPerfVisTrend = props.currentPerfVisTrend;
  const currentPerfAvgPosTrend = props.currentPerfAvgPosTrend;
  const currentPerfPosTrend = props.currentPerfPosTrend;

  /** Get last snippets update date from datas **/
  let lastCurrentMonth = getLastSnippetsUpdateDate( datas );

  // filter currentPerfAvgPosTrend to keep last month value
  let avgPosValue = null;
  if( currentPerfAvgPosTrend.me )
  {
    let lastMonthValue = currentPerfAvgPosTrend.me.filter( value => value.x === lastCurrentMonth );
    if( lastMonthValue.length > 0 )
      avgPosValue = lastMonthValue[0].y
  };

  // filter currentPerfVisTrend to keep last month value
  let visValue = null;
  if( 
    currentPerfVisTrend.me 
    && barometerContext === 'keywords'
  ){
    let lastMonthValue = currentPerfVisTrend.me.filter( value => value.x === lastCurrentMonth );
    if( lastMonthValue.length > 0 )
      visValue = Math.round( lastMonthValue[0].y )
  };

  // filter currentPerfPosTrend to keep last month value
  let kSumValue = null;
  if(
    currentPerfPosTrend.me.nb_ranked_keywords 
    && barometerContext === 'urls'
  ){
    let lastMonthValue = currentPerfPosTrend.me.nb_ranked_keywords.filter( value => value.x === lastCurrentMonth );
    if( lastMonthValue.length > 0 )
      kSumValue = lastMonthValue[0].y
  };  

  return (
    <div 
      className={ selectedDataVizTab === 'perf' ? 'dataviz-tab perf on' : 'dataviz-tab perf' } 
      onClick={ () => dispatch( updateSelectedDataVizTab( 'perf' ) ) }
    >
      <div className="title"><h4>ma visibilité seo + snippets</h4></div>
      { visValue || avgPosValue || kSumValue ?
        <div className="values-container">        
          { avgPosValue ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'SEO', {"size": "1.1rem"} )}<h5>pos. moy.</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( avgPosValue )}</div>
              </div>
            </div>
            : false
          }
          { visValue ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'SEO', {"size": "1.1rem"} )}<h5>tx occup.</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{visValue}<span className="percent">%</span></div>
              </div>
            </div>
            : false
          }
          { kSumValue ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'SEO', {"size": "1.1rem"} )}<h5>nb. mots-clés</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{kSumValue}</div>
              </div>
            </div>
            : false
          }
        </div>
        : false
      }
    </div>
  )
}

export default PerfTab;