/** Dependencies **/
import {useState, useRef, useEffect} from 'react';
import { 
  VictoryChart, 
  VictoryAxis, 
  VictoryBar,
  VictoryLabel,
  VictoryGroup
} from "victory";

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { 
  rewriteDetailsObjectData,
  getRoundValueLabel
} from './../../../../helpers/barometer/charts.js';

import { orderObjectKeysWithPattern } from './../../../../helpers/functions.js'

function DataVizTooltipPerfVis( props ) 
{
  /** Get props **/
  const { datum, x } = props;
  const date = datum.date;
  const grpDomainDisplay = props.grpDomainDisplay;
  const compareDisplay = props.compareDisplay;

  /** Get state from redux store **/
  const instanceConfig = useSelector( state => state.config.value );  

  /** Init state **/
  const [tooltipOffsetX, setTooltipOffsetX] = useState( 0 );

  /** Set ref **/
  const tooltipContainer = useRef( null );

  /** Get config values **/
  const grpDomains = instanceConfig.groups;

  /** Set order keys for sort datas **/
  const orderKeys = ['SEO', 'Google', 'Snippets', 'Total'];

  /** Rewrite datas for popin detail **/
  let currentDatas = [];
  let compareDatas = [];
  if( 
    props.currentDetails[date] 
    && Object.keys( props.currentDetails[date] ).length > 0
  ){
    
    // rewrite for current period for all groups
    Object.keys( props.currentDetails[date] ).forEach( grp => 
    {
      // clone currentDetails for add total value in datas
      let currentDatasClone = {...props.currentDetails[date][grp]};

      // get total value with sum of all current details
      const currentTotalValue = Object.values( props.currentDetails[date][grp] ).map( object => object.percent ).reduce( (a,b) => a + b );

      // add Total entry in clone datas object
      currentDatasClone.Total = {percent: currentTotalValue};

      // sort data order by label keys pattern
      currentDatasClone = orderObjectKeysWithPattern( currentDatasClone, orderKeys );

      currentDatas = [...currentDatas, {
        grp: grp, 
        datas: rewriteDetailsObjectData( currentDatasClone )
      }]

      return currentDatas;
    })

    // get compare date
    const currentIndexOf = Object.keys(props.currentDetails).findIndex( element => parseInt( element ) === date );
    const compareDate = parseInt( Object.keys(props.compareDetails)[currentIndexOf] );

    // rewrite for compare period for all groups
    if( 
      props.compareDetails[compareDate]
      && Object.keys( props.compareDetails[compareDate] ).length > 0
    ){
      Object.keys( props.compareDetails[compareDate] ).forEach( grp => 
      {
        // clone comapreDetails for add total value in datas
        let compareDatasClone = {...props.compareDetails[compareDate][grp]};

        // get total value with sum of all compare details
        const compareTotalValue = Object.values( props.compareDetails[compareDate][grp] ).map( object => object.percent ).reduce( (a,b) => a + b );

        // add Total entry in clone datas object
        compareDatasClone.Total = {percent: compareTotalValue};

        // sort data order by label keys pattern
        compareDatasClone = orderObjectKeysWithPattern( compareDatasClone, orderKeys );

        compareDatas = [...compareDatas, rewriteDetailsObjectData( compareDatasClone )]

        return compareDatas;
      })
    }      
  }

  /** Get if all displayed values = 0 to set default dependant axis values */
  // get current sum values
  let valuesSumDisplay = currentDatas.map( datas => 
    datas.datas.reduce( (a, b) => a + b.y, 0 ) 
  ).reduce( (a, b) => a + b, 0 ); 

  // get compare sum values
  valuesSumDisplay += compareDatas.map( datas => 
    datas.reduce( (a, b) => a + b.y, 0 ) 
  ).reduce( (a, b) => a + b, 0 );

  /** Define width of chart and bars **/
  const barWidth = 550 / currentDatas.length * 0.75 - ( ( 550 / currentDatas.length * 0.75 ) / 2 );

  /** Update x position for tooltip **/
  useEffect( () => 
  {
    if( tooltipContainer !== null )
    {
      const tooltipWidth = tooltipContainer.current.offsetWidth;
      if( x > 1350 / 2 )
        setTooltipOffsetX( - tooltipWidth - 1 );
      else
        setTooltipOffsetX( 1 );
    }
  }, []);

  return (
    <foreignObject x={x + tooltipOffsetX} y={-10} width="660" height="320">
      <div className="tooltip grey visibility" ref={tooltipContainer} style={tooltipContainer.current === null || valuesSumDisplay === 0 ? {visibility: 'hidden'} : {}}>
        <div className="tooltip-container">
          {currentDatas.length > 0 ?
            currentDatas.map( ( datas, index ) => 
              grpDomainDisplay[datas.grp] ?
                <VictoryChart
                  key={index}
                  width={650}
                  height={225 / Object.keys(grpDomainDisplay).filter( grp => grpDomainDisplay[grp] ).length }
                  padding={{ top: 25, bottom: 0, left: 35, right: 10 }}
                  domainPadding={{x: barWidth}}
                >
                  <VictoryAxis 
                    style={{
                      axis: {
                        stroke: "#D8D8D8"
                      },
                      tickLabels: {
                        fontSize: 10,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                        fill: "#707070"              
                      }
                    }}
                  />
                  <VictoryAxis dependentAxis 
                    offsetX={35}
                    tickFormat={ ( t ) => t + '%'}
                    style={{
                      axis: {
                        stroke: "#D8D8D8"
                      },
                      tickLabels: {
                        fontSize: 10,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                        fill: "#707070"
                      },
                      grid: {
                        stroke: '#D8D8D8', 
                        strokeWidth: 0.5
                      }
                    }}
                  />
                  <VictoryGroup
                    offset={barWidth / 2}
                  >
                    <VictoryBar
                      data={datas.datas}
                      sortKey='x'
                      labels={ ({ datum }) => getRoundValueLabel( datum.y ) }
                      labelComponent={
                        <VictoryLabel 
                          dy={ d => d.datum.y > Math.max( ...d.data.map( elem => elem.y ) ) * 0.9 ? 15 : 0 }
                          dx={ ({ datum }) => 
                            // set offset to label if match compared date value = 0
                            (
                              compareDisplay 
                              && compareDatas[index]
                              && compareDatas[index].filter( data => data.x === datum.x && data.y > 0 ).length > 0 
                            ) ? -15
                            : 0
                          }
                        />
                      }
                      cornerRadius={{ 
                        topLeft: 5,
                        topRight: 5 
                      }}
                      style={{ 
                        data: {
                          width: barWidth,
                          fill: grpDomains.length > 0 ? grpDomains.filter( grp => grp.value === datas.grp )[0].currentColor : null,
                          strokeWidth: 1
                        },
                        labels: {
                          fontFamily: "Roboto",
                          fontSize: 10,
                          fontWeight: "bold",                      
                          fill: "#707070"
                        }
                      }}
                    />

                    {compareDisplay && compareDatas.length > 0 ?
                      <VictoryBar
                        data={compareDatas[index]}
                        sortKey='x'
                        labels={ ({ datum }) => getRoundValueLabel( datum.y ) }
                        labelComponent={<VictoryLabel 
                          dy={ d => d.datum.y > Math.max( ...d.data.map( elem => elem.y ) ) * 0.9 ? 15 : 0 }
                          dx={15}
                        />}
                        cornerRadius={{ 
                          topLeft: 5,
                          topRight: 5 
                        }}
                        style={{ 
                          data: {
                            width: barWidth,
                            fill: 'transparent',
                            stroke: grpDomains.length > 0 ? grpDomains.filter( grp => grp.value === datas.grp )[0].compareColor : null, 
                            strokeWidth: 1, 
                            strokeDasharray:"2"
                          },
                          labels: {
                            fontFamily: "Roboto",
                            fontSize: 10,
                            fontWeight: "300",                      
                            fill: "#707070"
                          }
                        }}
                      />
                      : false
                    }
                  </VictoryGroup>
                </VictoryChart>
              : false
            )
            : false
          }
        </div>
      </div>
    </foreignObject>
  );
};

export default DataVizTooltipPerfVis;