/** Dependencies **/
import React, {useState, useRef, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import DataVizChartPerfsPositionsTrend from './DataVizCharts/PerfsPositionsTrend';
import Loader from './../../../Loader';

import { 
	getPicto
} from './../../../../helpers/pictos';

/** Helpers **/
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';
import { removeDomain } from './../../../../helpers/functions';
import { filterOnValueToContext } from './../../../../helpers/barometer/barometer';

function MyPerfDetailsUrls( props ) 
{
	// Get props
  const id = props.id;
  const snippetID = 'perfs-' + id;
  const accessor = props.accessor;
  const label = props.label;
  const keywords = props.keywords;
  const assignedKeywords = props.assignedKeywords;
    
  // Define ref
	const tooltipSnippet = useRef( null );

  /** Init state **/
  const [popinContent, setPopinContent] = useState( null );
  const [positionsTrends, setPositionsTrends] = useState( null );
  const [keywordsVolumes, setKeywordsVolumes] = useState( null );
  const [positionsTrendsOtherUrls, setPositionsTrendsOtherUrls] = useState( null );
  const [expectedUrlsWithKeywords, setExpectedUrlsWithKeywords] = useState( null );
  const [SERPUrlsWithKeywords, setSERPUrlsWithKeywords] = useState( null );
  const [currentAssignedKeywordsDatas, setCurrentAssignedKeywordsDatas] = useState( null );
  const [currentAssignedKeywordsDatasOtherUrls, setCurrentAssignedKeywordsDatasOtherUrls] = useState( null );
  const [currentNonAssignedKeywordsDatas, setCurrentNonAssignedKeywordsDatas] = useState( null );
  const [compareAssignedKeywordsDatas, setCompareAssignedKeywordsDatas] = useState( null );
  const [compareAssignedKeywordsDatasOtherUrl, setCompareAssignedKeywordsDatasOtherUrl] = useState( null );
  const [compareNonAssignedKeywordsDatas, setCompareNonAssignedKeywordsDatas] = useState( null );
  
  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const currentSnippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  const compareSnippetsDetails = useSelector( state => state.datas.value.compareSnippetsDetails );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /** Set mini url Trends period */
  const miniTrendStartDate = moment( selectedFilters.currentPeriod.enddate ).subtract( 5, 'month' ).startOf( 'month' );
  const miniTrendEndDate = moment( selectedFilters.currentPeriod.enddate ).endOf( 'month' );

  /** Set empty trend position array in period */
  const periodDuration = moment.duration( miniTrendEndDate.diff( miniTrendStartDate ) ).months();

  let emptyTrend = [];
  for( let i = 0 ; i <= periodDuration ; i++ )
  {
    emptyTrend = [...emptyTrend, {
      x: moment( miniTrendStartDate.clone().add( i, 'months' ) ).format('YYYYMM'),
      y: 101
    }];
  }

  /** Set non assigned keywords */
  const nonAssignedKeywords = keywords.filter( keyword => !assignedKeywords.includes( keyword ));

  /**
	 * Load content of popin :: Perf positions Trend || ranked urls
	 */
	const loadContent = () => 
	{
    /** Reset content */
    setPopinContent( null );

    /** Positions trend with url */
    callWebservice(
      snippetID,
      snippetID + '-positionsTrend',
      'perf-positions-trend',
      dispatch,
      selectedInstance,
      {
        period: {
          startdate: miniTrendStartDate.format( 'YYYYMMDD' ),
          enddate: miniTrendEndDate.format( 'YYYYMMDD' )
        },
        who: accessor,
        where: {
          urls: [label],
          locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
          devices: [selectedFilters.devLoc.deviceValue]
        },
        context: 'urls'
      },
      { function: 'setPositionsTrends' }
    );

    /** Positions trend with other urls */
    callWebservice(
      snippetID,
      snippetID + '-positionsTrend',
      'perf-positions-trend',
      dispatch,
      selectedInstance,
      {
        period: {
          startdate: miniTrendStartDate.format( 'YYYYMMDD' ),
          enddate: miniTrendEndDate.format( 'YYYYMMDD' )
        },
        who: accessor,
        where: {
          keywords: assignedKeywords,
          urls: currentAssignedKeywordsDatasOtherUrls.map( snippet => snippet.url ),
          locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
          devices: [selectedFilters.devLoc.deviceValue]
        },
        context: 'urls'
      },
      { function: 'setPositionsTrendsOtherUrls' }
    );

    /** Volumes with keywords */
    callWebservice(
      snippetID,
      snippetID + '-volumes',
      'volume',
      dispatch,
      selectedInstance,
      {
        period: {
          currentPeriod: {
            startdate: selectedFilters.currentPeriod.startdate.slice( 0, 6 ),
            enddate: selectedFilters.currentPeriod.enddate.slice( 0, 6 )
          }
        },
        where: {
          keywords: [...keywords, ...assignedKeywords],
          locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
          devices: [selectedFilters.devLoc.deviceValue]
        }
      },
      { function: 'setKeywordsVolumes' }
    );

    /** Expected urls with keywords */
    callWebservice(
			snippetID,
			snippetID + '-expectedUrlsWithKeywords',
			'get-expected-url',
			dispatch,
      selectedInstance,
			{
				keywords: [...keywords, ...assignedKeywords],
				where: {
          locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
          devices: [selectedFilters.devLoc.deviceValue]
        },
				context: 'keywords'
			},
			{ function: 'setExpectedUrlsWithKeywords' }
		);

    /** SERP url with keywords */
    callWebservice(
			snippetID,
			snippetID + '-SERPHTMLUrlsWithKeywords',
			'get-serp-html-url',
			dispatch,
      selectedInstance,
			{
				where: {
          keywords: [...keywords, ...assignedKeywords],
          locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
          devices: [selectedFilters.devLoc.deviceValue]
        }
			},
			{ function: 'setSERPUrlsWithKeywords' }
		);
	}

	/**
   * Build popin content with current and compare loaded datas of snippets details
   */
  const buildContent = () => 
  {
    let content = [];

    /** Add Assigned Keywords Content */
    let assignedBodyContent = [];    
    if( assignedKeywords.length > 0 )
    {
      // Body
      assignedKeywords.forEach( ( keyword, index ) => 
      {
        // get snippets details
        const snippets = currentAssignedKeywordsDatas.filter( datas => datas.keyword === keyword );

        // filter on snippets to get SEO AND Other
        const snippetsSEO = getSnippetsSEO( snippets );
        
        const snippetsOther = getSnippetsOther( snippets );

        // get snippet with other url details
        const snippetsWithOtherUrls = currentAssignedKeywordsDatasOtherUrls.filter( datas => datas.keyword === keyword );

        // filter on snippets with other url to get SEO AND Other
        const snippetsSEOWithOtherUrls = getSnippetsSEO( snippetsWithOtherUrls );
        
        assignedBodyContent = [...assignedBodyContent, 
          <tr key={'assigned-content-' + index}>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td className='datas'>

                      {/* Display keyword */}
                      { getHTMLKeyword( keyword ) }

                      {/* Display SEO */}
                      { snippetsSEO.length > 0 ?
                          getHTMLSnippetsSEO( snippetsSEO, compareAssignedKeywordsDatas )
                          : <ul><li>NP</li></ul>
                      }

                      {/* Display Other */}
                      { snippetsOther.length > 0 ?
                          getHTMLSnippetsOther( snippetsOther )
                          : false
                      }
                    </td>

                    {/* Display SERP external link */}
                    { SERPUrlsWithKeywords[keyword] ? 
                        getHTMLSERPUrlWithKeyword( keyword )
                        : false
                    }
                    
                    {/* Display positions trend */}
                    <td className='perf-positions-trend'>
                      <DataVizChartPerfsPositionsTrend 
                        datas={ positionsTrends[keyword] ? positionsTrends[keyword] : emptyTrend }
                      />
                    </td>
                  </tr>

                  {/* Display SEO with other positionned urls */}
                  { snippetsSEOWithOtherUrls.length > 0 ?
                    getHTMLSnippetsSEOWithOtherUrls( snippetsSEOWithOtherUrls, compareAssignedKeywordsDatasOtherUrl ) 
                    : false
                  }
                </tbody>
              </table>
            </td>            
          </tr>
        ]        
      });
    } else {

      assignedBodyContent = [...assignedBodyContent, 
        <tr key='assigned-nocontent'>
          <td>Aucun mot-clé assigné à cette url</td>
        </tr>
      ];
    }

    /** Add Non Assigned Keywords Content */
    let nonAssignedBodyContent = [];
    if( nonAssignedKeywords.length > 0 )
    {
      // Body
      nonAssignedKeywords.forEach( ( keyword, index ) => 
      {
        // get snippets details
        const snippets = currentNonAssignedKeywordsDatas.filter( datas => datas.keyword === keyword );

        // filter on snippets to get SEO AND Other
        const snippetsSEO = getSnippetsSEO( snippets );
        
        const snippetsOther = getSnippetsOther( snippets );

        nonAssignedBodyContent = [...nonAssignedBodyContent, 
          <tr key={'non-assigned-content-' + index}>
            <td className='datas'>
              <button className='internal' onClick={ () => filterOnValueToContext( keyword, 'label', 'keywords', navigate ) }>
                {/** Display keyword */}
                {keyword}

                {/** Display volume */}
                { keywordsVolumes[keyword]?.volume ? ' ( ' + new Intl.NumberFormat( "fr-FR" ).format( keywordsVolumes[keyword].volume ) + ' )' : false }
              </button>
              
              {/* Display SEO */}
              { snippetsSEO.length > 0 ?
                  getHTMLSnippetsSEO( snippetsSEO, compareNonAssignedKeywordsDatas )
                  : <ul><li>NP</li></ul>
              }

              {/* Display Other */}
              { snippetsOther.length > 0 ?
                  getHTMLSnippetsOther( snippetsOther )
                  : false
              }

              {/* Display expected url */}
              <div className='expected-url'>
                { getCurrentExpectedUrl( keyword ) !== false ? "Url attendue : " : "Pas d'URL assignés" }
                { getCurrentExpectedUrl( keyword ) !== false ?
                    <button 
                      onClick={ () => filterOnValueToContext( getCurrentExpectedUrl( keyword ), 'action', 'keywords', navigate ) } 
                      className='internal'
                    >{ getCurrentExpectedUrl( keyword ) }</button>
                    : false
                }
              </div>
            </td>
            
            {/* Display SERP external link */}
            { SERPUrlsWithKeywords[keyword] ? 
                getHTMLSERPUrlWithKeyword( keyword )
                : false
            }

            {/* Display positions trend */}
            <td className='perf-positions-trend'>
              <DataVizChartPerfsPositionsTrend 
                datas={ positionsTrends[keyword] ? positionsTrends[keyword] : emptyTrend }
              />
            </td>
          </tr>
        ]
      });
    } else {

      nonAssignedBodyContent = [...nonAssignedBodyContent, 
        <tr key='non-assigned-nocontent'>
          <td>Aucun mot-clé positionné sur cette période d'analyse</td>
        </tr>
      ];
    }

    /** Build content */
    content = [...content, 
      <React.Fragment key='content-popin'>
        <table>
          <thead className="header">
            <tr>
              <th>
                <h4>Mots-clés assignés à l'URL</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            { assignedBodyContent }
            <tr className='assigned-keywords-details'>
              <td>
                <button onClick={ () => filterOnValueToContext( label, 'action', 'keywords', navigate  ) }>Voir le détail des mots-clés assignés</button>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead className="header">
            <tr>
              <th colSpan="3">
                <h4>Mots-clés non assignés à l'URL</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            { nonAssignedBodyContent }
            <tr className='non-assigned-keywords-details'>
              <td colSpan="3">
                <button onClick={ () => filterOnValueToContext( label, 'perfme', 'keywords', navigate  ) }>Voir le détail des mots-clés positionnés</button>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    ];

    // Set popin content
    setPopinContent( content.length > 0 ? content : null );
  }

  /**
   * Return DOMElement for keyword
   * @param {String} keyword 
   * @returns DOMElement
   */
  const getHTMLKeyword = ( keyword ) =>
  {
    return <button className='internal' onClick={ () => filterOnValueToContext( keyword, 'label', 'keywords', navigate ) }>
      {/** Display keyword */}
      {keyword}

      {/** Display volume */}
      <span className='volume'>
        { keywordsVolumes[keyword]?.volume ? ' ( ' + new Intl.NumberFormat( "fr-FR" ).format( keywordsVolumes[keyword].volume ) + ' )' : false }
      </span>
    </button>;
  }

  /**
   * Return DOMElement for SEO snippets
   * @param {Array} snippetsSEO 
   * @param {Array} compareDatas 
   * @returns DOMElement
   */
  const getHTMLSnippetsSEO = ( snippetsSEO, compareDatas ) => 
  {
    return (
      <ul>
        {snippetsSEO.sort( ( a, b ) => a.position - b.position ).map( ( snippet, index ) => 
          <li key={ 'assigned-seo-' + index }>
            {/* Display position */}
            {snippet.position}

            {/* Display variation or E */}
            {
              compareDatas !== null ?
                getCompareDatas( snippet, compareDatas ).length === 0 ?
                  ' ( E )'
                : getCompareDatas( snippet, compareDatas )[0].position === snippet.position ?
                  ' ( = )'
                : getCompareDatas( snippet, compareDatas )[0].position !== snippet.position  ? 
                  ' ( '
                  +  
                    new Intl.NumberFormat( "fr-FR", { style: "decimal",  signDisplay: 'always' }).format( 
                      getCompareDatas( snippet, compareDatas )[0].position - snippet.position 
                    )                
                  + 
                  ' )'
                : false
              : false
            }

            {/* Display % (taux occupation) */}
            { parseInt( snippet.position ) < 11 ? 
                ' | ' + new Intl.NumberFormat( "fr-FR", { maximumFractionDigits: 0, style: "percent" }).format( 
                  getPercent( snippet )
                ) 
                : false 
            }
          </li> 
        )}
      </ul>
    );
  }

  /**
   * Return DOMElement for Other snippets
   * @param {Array} snippetsOther 
   * @returns DOMElement
   */
  const getHTMLSnippetsOther = ( snippetsOther ) => 
  {
    return (
      <ul>
        {snippetsOther.sort( ( a, b ) => a.position - b.position ).map( ( snippet, index ) => 
          <li key={ 'assigned-other-' + index }>
            {/* Display Type Snippet */}
            {snippet.typeSnippet}

            {/* Display % (taux occupation) */}
            { parseInt( snippet.position ) < 11 ? 
                ' | ' + new Intl.NumberFormat( "fr-FR", { maximumFractionDigits: 0, style: "percent" }).format( 
                  getPercent( snippet )
                ) 
                : false 
            }
          </li>
        )}
      </ul>
    );
  }

  /**
   * Return DOMElement for SEO snippets of other urls list
   * @param {Array} snippetsSEOWithOtherUrls 
   * @param {Array} compareDatas 
   * @returns DOMElement
   */
  const getHTMLSnippetsSEOWithOtherUrls = ( snippetsSEOWithOtherUrls, compareDatas ) => 
  {
    return (
      snippetsSEOWithOtherUrls.sort( ( a, b ) => a.position - b.position ).map( ( snippet, index ) => 
        <tr key={ 'assigned-seo-others-urls-' + index }>
          <td className='datas others-urls' colSpan="2">
            <span>Autre URL : </span>
            <button 
              title={snippet.url}
              onClick={ () => filterOnValueToContext( snippet.url, 'perfme', 'keywords', navigate ) }
              className="internal"
            >
              { removeDomain( snippet.url ) }
            </button>
            <div>
              {/* Display position */}
              {snippet.position}

              {/* Display variation or E */}
              {
                compareDatas !== null ?
                  getCompareDatas( snippet, compareDatas ).length === 0 ?
                    ' ( E )'
                  : getCompareDatas( snippet, compareDatas )[0].position === snippet.position ?
                    ' ( = )'
                  : getCompareDatas( snippet, compareDatas )[0].position !== snippet.position  ? 
                    ' ( '
                    +  
                      new Intl.NumberFormat( "fr-FR", { style: "decimal",  signDisplay: 'always' }).format( 
                        getCompareDatas( snippet, compareDatas )[0].position - snippet.position 
                      )                
                    + 
                    ' )'
                  : false
                : false
              }

              {/* Display % (taux occupation) */}
              { parseInt( snippet.position ) < 11 ? 
                  ' | ' + new Intl.NumberFormat( "fr-FR", { maximumFractionDigits: 0, style: "percent" }).format( 
                    getPercent( snippet )
                  ) 
                  : false 
              }
            </div>
          </td> 
          
          { positionsTrendsOtherUrls[snippet.url] ? 
              <td className='perf-positions-trend' >
                <DataVizChartPerfsPositionsTrend 
                  datas={ positionsTrendsOtherUrls[snippet.url] ? positionsTrendsOtherUrls[snippet.url] : emptyTrend }
                />
              </td>
            : false
          }
        </tr>        
      )
    );
  }

  /**
   * Return DomElement of SERP url for keyword
   * @param {string} keyword 
   * @returns DOMElement
   */
  const getHTMLSERPUrlWithKeyword = ( keyword ) => 
  {
    return(
      <td className='link-serp-viewer'>
        <a href={ SERPUrlsWithKeywords[keyword] } target="_blank" rel="noreferrer">{getPicto( 'Google Viewer', {width: "1.7rem", id: snippetID} )}</a>
      </td>
    );
  }

  /**
   * Filter snippets array for SEO groups snippets
   * @param {Array} snippets 
   * @returns Array
   */
  const getSnippetsSEO = ( snippets ) => 
  {
    return snippets.filter( snippet => 
      snippet.typeSnippet === 'SEO' 
      || snippet.typeSnippet === "Featured Snippet" 
    );
  }

  /**
   * Filter snippets array for Other groups snippets
   * @param {Array} snippets 
   * @returns Array
   */
  const getSnippetsOther = ( snippets ) => 
  {
    return snippets.filter( snippet => 
      snippet.typeSnippet !== 'SEO' 
      && snippet.typeSnippet !== 'Featured Snippet' 
      && snippet.typeSnippet !== 'Ads' 
      && snippet.typeSnippet !== 'Shopping'  
    );
  }
  
  /**
   * Return compareDatas filtered on current snippet url and type SEO or Featured
   * @param {Object} snippet 
   * @param {Array} compareDatas 
   * @returns Array
   */
  const getCompareDatas = ( snippet, compareDatas ) =>
  {
    return compareDatas.filter( data => 
      data.keyword === snippet.keyword
      && data.grpSnippet === snippet.grpSnippet
      && data.url === snippet.url
    );
  }

  /**
   * Return expected url from update or automatic field
   * @param {Object} snippet 
   * @returns string or false
   */
  const getCurrentExpectedUrl = ( keyword ) => 
  {
    return expectedUrlsWithKeywords[keyword] !== undefined && expectedUrlsWithKeywords[keyword].length > 0 ? 
      expectedUrlsWithKeywords[keyword][0]?.updated_expectedUrl ?
        removeDomain( expectedUrlsWithKeywords[keyword][0].updated_expectedUrl )
      : expectedUrlsWithKeywords[keyword][0]?.automatic_expectedUrl ?
        removeDomain( expectedUrlsWithKeywords[keyword][0].automatic_expectedUrl )
      : null
    : false;
  }

  /**
   * Return total area of current keywords
   * @param {Object} snippet 
   * @returns 
   */
  const getPercent = ( snippet ) => 
  {
    // get total area for current keyword
    const totalArea = currentSnippetsDetails.filter( detail => 
      detail.keyword === snippet.keyword
      && parseInt( detail.position ) < 11
      && detail.typeSnippet !== "Ads" 
      && detail.typeSnippet !== "Shopping"
    ).map( snippet => snippet.area ).reduce( ( a, b ) => a + b, 0 );

    // get percent
    const percent = snippet.area / totalArea;

    return percent;
  } 

  /**
   * Build content when datas are loaded
   */
  useEffect( () => 
  {
    if( 
      positionsTrends !== null 
      && positionsTrendsOtherUrls !== null 
      && keywordsVolumes !== null
      && SERPUrlsWithKeywords !== null
      && expectedUrlsWithKeywords !== null
      && currentAssignedKeywordsDatas !== null
      && currentAssignedKeywordsDatasOtherUrls !== null
      && currentNonAssignedKeywordsDatas !== null
    )
      buildContent();
  }, [ 
    positionsTrends, 
    positionsTrendsOtherUrls,
    keywordsVolumes,
    SERPUrlsWithKeywords,
    expectedUrlsWithKeywords,
    currentAssignedKeywordsDatas,
    currentAssignedKeywordsDatasOtherUrls,
    currentNonAssignedKeywordsDatas
  ]);

  /**
   * Set assigned keywords datas when snippet details loaded
   */
  useEffect( () =>
  {
    if(
      currentSnippetsDetails 
      && compareSnippetsDetails
    ){
      /** Get current assigned keywords datas */
      setCurrentAssignedKeywordsDatas( currentSnippetsDetails.filter( detail => 
          detail.url === label 
          && detail.who === accessor 
          && assignedKeywords.includes( detail.keyword )
        )
      );

      /** Get current assigned keywords datas with other url */
      setCurrentAssignedKeywordsDatasOtherUrls( currentSnippetsDetails.filter( detail => 
          detail.url !== label 
          && detail.who === accessor 
          && assignedKeywords.includes( detail.keyword )
        )
      );

      /** Get current non assigned keywords datas */
      setCurrentNonAssignedKeywordsDatas( currentSnippetsDetails.filter( detail => 
          detail.url === label 
          && detail.who === accessor 
          && nonAssignedKeywords.includes( detail.keyword )
        )
      );
      
      /** Get compare datas */
      if( compareSnippetsDetails.length > 0 )
      {
        setCompareAssignedKeywordsDatas( compareSnippetsDetails.filter( detail => 
            detail.url === label 
            && detail.who === accessor 
            && assignedKeywords.includes( detail.keyword )
          )
        );

        setCompareAssignedKeywordsDatasOtherUrl( compareSnippetsDetails.filter( detail => 
            detail.url !== label 
            && detail.who === accessor 
            && assignedKeywords.includes( detail.keyword )
          )
        );

        setCompareNonAssignedKeywordsDatas( compareSnippetsDetails.filter( detail => 
            detail.url === label 
            && detail.who === accessor 
            && nonAssignedKeywords.includes( detail.keyword )
          )
        );
      }
    }      
  }, [
    currentSnippetsDetails,
    compareSnippetsDetails
  ]);

	return (
		<ReactTooltip 
			id={snippetID}
			ref={tooltipSnippet}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterHide={ () => {
				tooltipSnippet.current.tooltipRef.style.left = null;
				tooltipSnippet.current.tooltipRef.style.top = null;
			}}
      afterShow={loadContent}
			globalEventOff={'click'}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 - 20,
          top: 50
        };
      }}
		>
      <React.Fragment>
        <Loader 
          loaderID={snippetID} 
          loaderStyle={{width:'15', stroke:'#e43e21'}} 
          callBackFcts={{
						setPositionsTrends: setPositionsTrends,
            setPositionsTrendsOtherUrls: setPositionsTrendsOtherUrls,
            setKeywordsVolumes: setKeywordsVolumes,
            setExpectedUrlsWithKeywords: setExpectedUrlsWithKeywords,
            setSERPUrlsWithKeywords: setSERPUrlsWithKeywords
					}}
          globalCallBack={undefined}
        />
        {popinContent !== null ?
          <div className='tooltip-container'>
            {popinContent}
          </div>
          : false
        }
      </React.Fragment>      
		</ReactTooltip>			
	);
}

export default MyPerfDetailsUrls;