/** Dependencies **/
import React, {useEffect, useState} from 'react';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateDatas } from './../../../reducers/datas';
import { updateSelectedDataVizTab } from './../../../reducers/selectedDataVizTab';
import { onToggler } from './../../../reducers/toggler';

/** Components **/
import Loader from './../../Loader';
import VolumeTab from './DataVizTabs/VolumeTab';
import SERPTab from './DataVizTabs/SERPTab';
import PerfTab from './DataVizTabs/PerfTab';
import GSCTab from './DataVizTabs/GSCTab';
import DataVizVolume from './DataVizCharts/DataVizVolume';
import DataVizSERP from './DataVizCharts/DataVizSERP';
import DataVizPerf from './DataVizCharts/DataVizPerf';
import DataVizGSC from './DataVizCharts/DataVizGSC';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';

/** Helpers **/
import { getIsIgnore } from './../../../helpers/datas';

/** SCSS **/
import './DataViz.scss';

function DataViz( props, ref ) 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const datas = useSelector( state => state.datas.value );
  const selectedDataVizTab = useSelector( state => state.selectedDataVizTab.value );
  const barometerContext = useSelector( state => state.barometerContext.value ); 
  const selectedInstance = useSelector( state => state.selectedInstance.value ); 
  const togglers = useSelector( state => state.togglers.value ); 
  
  /** Init state **/
  const [dataVizChart, setDataVizChart] = useState( null );  

  /** Define FullScreenHandle **/
  const chartFullScreenHandle = props.fullscreenhandle;

  /** set callback function to dispatch result of actions states request **/
  const callbackTrendLoader = ( result, id ) => { dispatch( updateDatas( {[id]:result} ) ); };  

  /** Display DataVizCharts when datas loaded **/
  useEffect(() =>
  {
    // set viz trend
    switch ( selectedDataVizTab ) {
      case 'serp':
        if( datas.currentSERPTrend || datas.compareSERPTrend )
          setDataVizChart( 
            <DataVizSERP 
              currentSERPTrend={datas.currentSERPTrend} 
              compareSERPTrend={datas.compareSERPTrend} 
              fullscreenhandle={chartFullScreenHandle} 
              ref={ref} 
            /> 
          );
        else
          dispatch( updateSelectedDataVizTab( 'volume' ) );

        break;
      case 'perf':
        if( datas.currentPerfTrend || datas.comparePerfTrend )
          setDataVizChart( 
            <DataVizPerf 
              currentPerfTrend={datas.currentPerfTrend} 
              comparePerfTrend={datas.comparePerfTrend} 
              fullscreenhandle={chartFullScreenHandle} 
              ref={ref} 
            /> 
          );
        else
          dispatch( updateSelectedDataVizTab( 'volume' ) );

        break;
      case 'gsc':
        if( datas.currentGSCTrend || datas.compareGSCTrend )
          setDataVizChart( 
            <DataVizGSC 
              currentGSCTrend={datas.currentGSCTrend} 
              compareGSCTrend={datas.compareGSCTrend} 
              fullscreenhandle={chartFullScreenHandle} 
              ref={ref} 
            /> 
          );
        else
          dispatch( updateSelectedDataVizTab( 'volume' ) );

        break;
      default:
        if( datas.currentVolumeTrend || datas.compareVolumeTrend )
          setDataVizChart( 
            <DataVizVolume 
              currentVolumeTrend={datas.currentVolumeTrend} 
              compareVolumeTrend={datas.compareVolumeTrend} 
              fullscreenhandle={chartFullScreenHandle} 
              ref={ref} 
            /> 
          );    
        break;
    }
  }, [
    selectedDataVizTab, 
    datas.currentSERPTrend,
    datas.compareSERPTrend,
    datas.currentPerfTrend,
    datas.comparePerfTrend,
    datas.currentGSCTrend,
    datas.compareGSCTrend,
    datas.currentVolumeTrend,
    datas.compareVolumeTrend
  ]);

  /** Load and update dataviz **/
  useEffect(() => 
  {
    if( 
      datas.filterResult 
      && datas.filterResult.length > 0 
      && selectedInstance
    ){
      // get current keywords
      const keywords = datas.filterResult.filter( 
        item => !getIsIgnore( item ) 
      ).map( 
        data => data.keywords 
      ).flat( 2 );

      // get current assigned and ignored keywords
      let assignedKeywords = [];
      let assignedURLKeywords = [];
      if( barometerContext === 'urls' )
      {
        // get current assigned keywords
        assignedURLKeywords = datas.filterResult.filter( 
          item => !getIsIgnore( item ) 
        ).map( 
          data => data['keywords-assigned'].map( keyword => data.label + '|' + keyword )
        ).flat( 2 );     
  
        // get current ignored keywords
        assignedKeywords = assignedURLKeywords.map( keyword => keyword.split( '|' )[1] );
      }

      // labels
      const labels = datas.filterResult.filter( item => !getIsIgnore( item ) ).map( item => item.label );

      // get current period
      const currentPeriod = selectedFilters.currentPeriod;

      // get compare period
      const comparePeriod = selectedFilters.comparePeriod;

      /** Current Trends **/
      if( currentPeriod.startdate !== undefined )
      {
        // Set filters
        const currentTrendFilters = {          
          period: { 
            startdate: currentPeriod.startdate, 
            enddate: currentPeriod.enddate 
          },
          where: {
            keywords: keywords,
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue]            ,
            devices: [selectedFilters.devLoc.deviceValue],
            dataVizViewType: togglers['radio-view-option'] ? 'month' : 'week'
          }
        }

        /** Current Volume Trend */
        let currentTrendFiltersVolume = { ...currentTrendFilters };
        
        // replace keywords with assigned keywords in url context
        if( barometerContext === 'urls')
          currentTrendFiltersVolume = { 
            ...currentTrendFilters, 
            where: { ...currentTrendFilters.where, keywords: assignedKeywords }
          };
  
        if( 
          barometerContext === 'keywords' 
          || ( barometerContext === 'urls' && assignedKeywords.length > 0 )
          || barometerContext === 'gsc-keywords'
        ){
          callWebservice(
            'dataviz',
            'currentVolumeTrend',
            'volume-trend',
            dispatch,
            selectedInstance,
            currentTrendFiltersVolume,
            { function: 'trendLoader', params: ['currentVolumeTrend'] },
            'Chargement des volumes de la période d\'analyse'
          );

        } else {
          callbackTrendLoader( null, 'currentVolumeTrend' );
        }
        
        /** Current GSC Trend */
        // add urls from label for gsc and remove keywords
        let currentTrendFiltersGSC = { ...currentTrendFilters };
        if( barometerContext === 'urls')
        {
          currentTrendFiltersGSC = { 
            ...currentTrendFilters, 
            where: { ...currentTrendFilters.where, urls: labels } 
          };
          delete currentTrendFiltersGSC.where.keywords;
        }

        callWebservice(
          'dataviz',
          'currentGSCTrend',
          'gsc-trend',
          dispatch,
          selectedInstance,
          currentTrendFiltersGSC,
          { function: 'trendLoader', params: ['currentGSCTrend'] },
          'Chargement des données GSC de la période d\'analyse'
        );

        /** Current SERP Trend */
        if( 
          barometerContext === 'keywords'
          || barometerContext === 'urls'
        ){
          callWebservice(
            'dataviz',
            'currentSERPTrend',
            'serp-trend',
            dispatch,
            selectedInstance,
            currentTrendFilters,
            { function: 'trendLoader', params: ['currentSERPTrend'] },
            'Chargement des données SERP de la période d\'analyse'
          );
        } else {
          callbackTrendLoader( null, 'currentSERPTrend' );
        }

        /** Current Performance Trend */
        if( 
          barometerContext === 'keywords'
          || barometerContext === 'urls'
        ){
          let currentTrendFiltersPerf = {
            ...currentTrendFilters, 
            where: {
              ...currentTrendFilters.where,
              typesSnippetsExcluded: ['Ads', 'Shopping']
            },
            context: barometerContext
          };

          if( barometerContext === 'urls' )
            currentTrendFiltersPerf = { 
              ...currentTrendFiltersPerf, 
              where: {
                ...currentTrendFiltersPerf.where,
                urls: labels,
                assignedKeywords: assignedURLKeywords
              }
            };

          callWebservice(
            'dataviz',
            'currentPerfTrend',
            'perf-trend',
            dispatch,
            selectedInstance,
            currentTrendFiltersPerf,
            { function: 'trendLoader', params: ['currentPerfTrend'] },
            'Chargement des données Performance de la période d\'analyse'
          );

        } else {
          callbackTrendLoader( null, 'currentPerfTrend' );
        }

      } else {
        dispatch( updateDatas({
          currentVolumeTrend: null,
          currentGSCTrend: null,
          currentSERPTrend: null,
          currentPerfTrend: null
        }));
      }

      /** Compare Trends **/
      if( comparePeriod.startdate !== undefined )
      {
        // set filters
        const compareTrendFilters = {
          period: { 
            startdate: comparePeriod.startdate, 
            enddate: comparePeriod.enddate 
          },
          where: {
            keywords: keywords,
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue],
            dataVizViewType: togglers['radio-view-option'] ? 'month' : 'week'
          }
        }

        /** Compare Volume Trend */
        let compareTrendFiltersVolume = {...compareTrendFilters};
        
        // replace keywords with assigned keywords in url context
        if( barometerContext === 'urls')
          compareTrendFiltersVolume = { 
            ...compareTrendFilters, 
            where: { ...compareTrendFilters.where, keywords: assignedKeywords }
          };
          

        if( 
          barometerContext === 'keywords' 
          || ( barometerContext === 'urls' && assignedKeywords.length > 0 )
          || barometerContext === 'gsc-keywords'
        ){
          callWebservice(
            'dataviz',
            'compareVolumeTrend',
            'volume-trend',
            dispatch,
            selectedInstance,
            compareTrendFiltersVolume,
            { function: 'trendLoader', params: ['compareVolumeTrend'] },
            'Chargement des volumes de la période de comparaison'
          );
        } else {
          callbackTrendLoader( null, 'compareVolumeTrend' );
        }

        /** Compare GSC Trend */
        // add urls from label for gsc and remove keywords
        let compareTrendFiltersGSC = {...compareTrendFilters};
        if( barometerContext === 'urls')
        {
          compareTrendFiltersGSC = { 
            ...compareTrendFilters, 
            where: { ...compareTrendFiltersGSC.where, urls: labels } 
          };
          delete compareTrendFiltersGSC.where.keywords;
        }

        // call webservice
        callWebservice(
          'dataviz',
          'compareGSCTrend',
          'gsc-trend',
          dispatch,
          selectedInstance,
          compareTrendFiltersGSC,
          { function: 'trendLoader', params: ['compareGSCTrend'] },
          'Chargement des données GSC de la période de comparaison'
        );

        /** Compare SERP Trend */
        if( 
          barometerContext === 'keywords'
          || barometerContext === 'urls'
        ){
          callWebservice(
            'dataviz',
            'compareSERPTrend',
            'serp-trend',
            dispatch,
            selectedInstance,
            compareTrendFilters,
            { function: 'trendLoader', params: ['compareSERPTrend'] },
            'Chargement des données SERP de la période de comparaison'
          );
        } else {
          callbackTrendLoader( null, 'compareSERPTrend' );
        }

        /** Compare Performance Trend */
        if( 
          barometerContext === 'keywords'
          || barometerContext === 'urls'
        ){
          let compareTrendFiltersPerf = {
            ...compareTrendFilters, 
            where: {
              ...compareTrendFilters.where,
              typesSnippetsExcluded: ['Ads', 'Shopping']
            },
            context: barometerContext
          };

          if( barometerContext === 'urls' )
            compareTrendFiltersPerf = {
              ...compareTrendFiltersPerf, 
              where: {
                ...compareTrendFiltersPerf.where,
                urls: labels,
                assignedKeywords: assignedURLKeywords                
              }
            };

          callWebservice(
            'dataviz',
            'comparePerfTrend',
            'perf-trend',
            dispatch,
            selectedInstance,
            compareTrendFiltersPerf,
            { function: 'trendLoader', params: ['comparePerfTrend'] },
            'Chargement des données Performance de la période de comparaison'
          );

        } else {
          callbackTrendLoader( null, 'comparePerfTrend' );
        }

      } else {
        dispatch( updateDatas({
          compareVolumeTrend: null,
          compareGSCTrend: null,
          compareSERPTrend: null,
          comparePerfTrend: null
        }) );
      }

    } else {
      dispatch( updateDatas({
        currentVolumeTrend: null,
        currentGSCTrend: null,
        currentSERPTrend: null,
        currentPerfTrend: null,
        compareVolumeTrend: null,
        compareGSCTrend: null,
        compareSERPTrend: null,
        comparePerfTrend: null
      }) );
    }
    
  }, [ 
    JSON.stringify( datas.filterResult.map( data => [
      data.volume, 
      data.snippetsSEO,
      data.snippetsGoogle,
      data.snippetsAds,
      data.snippetsSnippets,
      data.perfme,
      data.perfgp1,
      data.perfgp2,
      data.perfgp3,
      data.impressions,
      data.clicks,
      data.ctr,
      data.position,
      data.action,
      data['keywords-assigned']
    ] ) ),
    togglers['radio-view-option']
  ]);

  // init togglers for compare period and view type
  useEffect( () => 
  {
    dispatch( onToggler( 'radio-compare-option' ) );
    dispatch( onToggler( 'radio-view-option' ) );
  }, [])

  return (
    <React.Fragment>
      <Loader 
        loaderID="dataviz" 
        loaderStyle={{width:'66', stroke:'#e43e21', viewBox:'-2 -2 42 42'}} 
        callBackFcts={{
          trendLoader: callbackTrendLoader
        }}
        globalCallBack={undefined}
      />
      <div className='dataviz-tabs-container'>
        {( 
          datas.currentVolumeTrend 
          || datas.compareVolumeTrend 
        ) ?
          <VolumeTab 
            currentVolumeTrend={datas.currentVolumeTrend} 
            compareVolumeTrend={datas.compareVolumeTrend} 
          />
          : ''
        }
        {( datas?.currentSERPTrend?.grpSnippets !== undefined ) ?
          <SERPTab 
            currentSERPTrend={datas.currentSERPTrend.grpSnippets} 
          />
          : ''
        }
        {( 
          datas?.currentPerfTrend?.grpDomains !== undefined
          || datas?.currentPerfTrend?.avgPositionsGrpDomains !== undefined
          || datas?.currentPerfTrend?.positionsGrpDomains !== undefined
        ) ?
          <PerfTab 
            currentPerfVisTrend={datas.currentPerfTrend.grpDomains} 
            currentPerfAvgPosTrend={datas.currentPerfTrend.avgPositionsGrpDomains} 
            currentPerfPosTrend={datas.currentPerfTrend.positionsGrpDomains}             
          />
          : ''
        }
        {( datas.currentGSCTrend && Object.keys( datas.currentGSCTrend ).length > 0 ) ?
          <GSCTab 
            currentGSCTrend={datas.currentGSCTrend} 
          />
          : ''
        }
      </div>
      {dataVizChart}
    </React.Fragment>
  )
}

export default React.forwardRef( DataViz );