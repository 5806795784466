/** Components **/
import DefaultDateRangeFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/DefaultDateRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Dashboards/Barometer/TableHeaders/TitleWithPicto';
import TableCellGSCIdentifierDate from './../../../components/Dashboards/Barometer/TableCells/GSCIdentifierDate';

export const GscIdentifierDateColumn = ( widthColumns ) => 
{
  return {
    accessor: "gsc.identifierDate",
    width: widthColumns["gsc.identifierDate"],
    title: "Date d'ident.",
    className: "col-identifier-date col-center",
    Filter: DefaultDateRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'Date d\'identification',
    sortDescFirst: false,          
    sortType: 'identifierDateSort',
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Cell: props => 
      <TableCellGSCIdentifierDate 
        gscIdentifierDate={props.value}
      />
  }
}