/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import SnippetDetailsCategory from './../TableTooltips/SnippetDetailsCategory';

/** Helpers **/
import { 
	getPicto
} from './../../../../helpers/pictos';

function Snippet( props ) 
{
  /** Get state from redux store **/
  const barometerContext = useSelector( state => state.barometerContext.value );  

  // get props
  const id = props.id;
  const accessor = props.accessor;
  const label = props.label;
  const keywords = props.keywords;
  const category1 = props.category1;
  const category2 = props.category2;
  const category3 = props.category3;

  // get percent
  let percent = null;
  if( props.values && props.values.value )
    percent = props.values.value;

  // get variation
  let variation = null;
  if( props.values && props.values.variation )
    variation = props.values.variation;

  // get types snippets except SEO because exist everytime 
  let currentTypesSnippets = null;  
  if( 
    props?.values?.currentTypesSnippets !== undefined
    && props.values.currentTypesSnippets instanceof Array 
    && props.values.currentTypesSnippets.length > 0 
  )
    currentTypesSnippets = props.values.currentTypesSnippets.filter( typeSnippet => !typeSnippet.endsWith( 'SEO' ) );

  // set positiv and negativ width for hr design
  const positiveWidth = percent;
  const negativeWidth = 100 - percent;

  return (
    <React.Fragment>
      <div 
        className="snippets-container"
        data-tip={accessor + '-' + id} 
        data-for={accessor + '-' + id}
        data-arrow-color="#e43e21" 
        data-place="bottom"
      >
        { barometerContext === 'categories' ?
          <div className='hover-area'>&nbsp;</div>
          : false 
        }
        
        { percent !== null ?
          <div className="line-container">
            <hr className={(positiveWidth === 0 ? 'none' : '') + ' positive-line'} style={{width: positiveWidth + "%"}} />          
            <hr className={(negativeWidth === 0 ? 'none' : '') + ' negative-line'} style={{width: negativeWidth + "%"}} />
          </div>
          : false 
        }

        <div className='data-container'>
        
          { percent !== null ? 
            <p className='value'>{new Intl.NumberFormat( 'fr-FR' ).format( percent )}<span className='percent-symbol'>%</span></p>
            : false 
          }
          
          { variation !== null ?
            <p className='variation'>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, style: "decimal",  signDisplay: 'always' }).format( variation )}</p>
            : false 
          }

        </div>

        { currentTypesSnippets !== null && currentTypesSnippets.length > 0 ?
            <ul className='types-snippets'>
              { currentTypesSnippets.map( ( snippet, index ) => 
                  <li key={index} title={snippet.split( '-' )[1]}>
                    { getPicto( 
                        snippet.split( '-' )[1], 
                        { 
                          "size": "1rem", 
                          "fill": snippet.split( '-' )[0] === '1' ? '#71B8F2' : 'black' 
                        }
                      )
                    }
                  </li>
                )
              }
            </ul>
          : false 
        }

        { percent !== null && barometerContext === 'categories' ?
            <SnippetDetailsCategory
              accessor={accessor} 
              id={id} 
              label={label} 
              keywords={keywords} 
              category1={category1}
              category2={category2}
              category3={category3}
            />
          : false 
        }

      </div>
    </React.Fragment>
  );
}

export default Snippet;