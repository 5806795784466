/** Components **/
import DefaultStringFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/DefaultStringFilterToolTip';
import TableCellKeywords from './../../../components/Dashboards/Barometer/TableCells/Keywords';
import TableCellThematics from './../../../components/Dashboards/Barometer/TableCells/Thematics';
import TableCellUrls from './../../../components/Dashboards/Barometer/TableCells/Urls';

export const LabelColumn = ( barometerContext, widthColumns ) => 
{
  return {
    accessor: "label",
    width: widthColumns['label'],
    title: 
      barometerContext === 'categories' ? 
        "thématique"  
      : barometerContext === 'urls' ? 
        "url"  
      : "mot clé",
    className: "col-label",
    Filter: DefaultStringFilterToolTip,
    filter: "multiFieldsCustomFilter",
    filterTitle: 
      barometerContext === 'categories' ? 
        "Chercher une/des thématique(s)"
      : barometerContext === 'urls' ? 
        "Chercher une/des url(s)"
      : "Chercher un/des mot(s) clé(s)" ,
    disableSortBy: true,
    Header: props => props.column.title,
    Total: "TOTAL",
    Cell: props => 
      barometerContext === 'categories' ?
        <TableCellThematics 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          value={props.row.original.label}
          depth={props.row.depth}
          row={props.row}
        />
      : barometerContext === 'urls' ?
        <TableCellUrls 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          value={props.row.original.label} 
        />
      : <TableCellKeywords 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          value={props.row.original.label} 
        />
  }
}