/** Dependencies **/
import {useState, useRef, useEffect} from 'react';
import { 
  VictoryChart, 
  VictoryAxis, 
  VictoryBar,
  VictoryLabel,
  VictoryGroup
} from "victory";

/** Components **/
import CustomTicketLabelSnippet from './../DataVizCharts/CustomTicketLabelSnippet';

/** Helpers **/
import { 
  rewriteDetailsArrayData,
  getRoundValueLabel 
} from './../../../../helpers/barometer/charts.js';

import { getPicto } from './../../../../helpers/pictos';

function DataVizTooltipSERP( props ) 
{
  /** Get props **/
  const { datum, x, parentBarWidth } = props;
  const date = datum.date;
  const labelValue = datum.labelValue;
  const currentColor = props.currentColor;
  const compareColor = props.compareColor;
  const compareDisplay = props.compareDisplay;

  /** Init state **/
  const [tooltipOffsetX, setTooltipOffsetX] = useState( 0 );

  /** Set ref **/
  const tooltipContainer = useRef( null );

  /** Rewrite datas for popin detail **/
  let currentDatas = [];
  let compareDatas = [];
  let currentTotalValue = 0;
  if( 
    props.currentDetails[date]
    && props.currentDetails[date][labelValue]
  ){
    currentDatas = rewriteDetailsArrayData( props.currentDetails[date][labelValue].snippets );

    // get total value with sum of all current details
    currentTotalValue = Math.round( currentDatas.map( data => data.y ).reduce( ( a, b ) => a + b ) );

    // add Total entry in clone datas object
    currentDatas = [...currentDatas, {
      x: 'Total',
      y: currentTotalValue,
      tickValue: 'Total'
    }];

    // get compare date
    const currentIndexOf = Object.keys( props.currentDetails ).findIndex( element => parseInt( element ) === date );
    const compareDate = parseInt( Object.keys( props.compareDetails )[currentIndexOf] );
    
    if( 
      props.compareDetails[compareDate]
      && props.compareDetails[compareDate][labelValue]
    ){
      compareDatas = rewriteDetailsArrayData( props.compareDetails[compareDate][labelValue].snippets );

      // get total value with sum of all current details
      const compareTotalValue = Math.round( compareDatas.map( data => data.y ).reduce( ( a, b ) =>  a + b ) );

      // add Total entry in clone datas object
      compareDatas = [...compareDatas, {
        x: 'Total',
        y: compareTotalValue,
        tickValue: 'Total'
      }];

      /** Push values for type snippet wich exist in current but not in compare and reverse */ 
      // get type snippets
      const currentTicks = currentDatas.map( data => data.x );
      const compareTicks = compareDatas.map( data => data.x );

      // get difference between current and compare
      const difference = currentTicks.filter( value => 
        !compareTicks.includes( value ) 
      ).concat( compareTicks.filter( value => 
        !currentTicks.includes( value ) 
      ));

      // add values in current and compare data arrays
      difference.forEach( typeSnippet => 
      {
        if( currentDatas.filter( data => data.x === typeSnippet ).length === 0 ) 
          currentDatas = [{
            x: typeSnippet, 
            y: 0, 
            tickValue: typeSnippet
          }, ...currentDatas];

        if( compareDatas.filter( data => data.x === typeSnippet ).length === 0 ) 
          compareDatas = [{
            x: typeSnippet, 
            y: 0, 
            tickValue: typeSnippet
          }, ...compareDatas];
      });
    }
  }

  /** Define width of chart and bars | domain padding X **/
  let barWidth = 750 / currentDatas.length * 0.75;
  let domainPaddingX = barWidth / 2;
  if( compareDatas.filter( item => item.y > 0 ).length > 0 && compareDisplay === true ){
    barWidth = 750 / currentDatas.length * 0.75 - ((750 / currentDatas.length * 0.75) / 2);
    domainPaddingX = barWidth;
  }

  /** Update x position for tooltip **/
  useEffect( () => 
  {
    if( tooltipContainer !== null )
    {
      const tooltipWidth = tooltipContainer.current.offsetWidth;
      if( x > 1350 / 2 )
        setTooltipOffsetX( - tooltipWidth - ( parentBarWidth / 2 ) );
      else
        setTooltipOffsetX( 1 );
    }
  }, []);

  return (
    <foreignObject x={x + tooltipOffsetX} y={-10} width="660" height="320">
      <div className="tooltip grey" ref={tooltipContainer} style={tooltipContainer.current === null ? {visibility: 'hidden'} : {}}>
        <div className="tooltip-container">
          <h4>{getPicto(labelValue, {size:"1.2em"})}détails {labelValue} - {currentTotalValue}%</h4>
          {currentDatas.length > 0 ?
            <VictoryChart
              width={650}
              height={213}
              padding={{ top: 20, bottom: 15, left: 35, right: 10 }}
              domainPadding={{x: domainPaddingX}}
            >
              <VictoryAxis 
                style={{
                  axis: {
                    stroke: "#D8D8D8"
                  },
                  tickLabels: {
                    fontSize: 10,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                    fill: "#707070"              
                  }
                }}
                tickLabelComponent={<CustomTicketLabelSnippet />}
              />
              <VictoryAxis dependentAxis 
                offsetX={35}
                tickFormat={ ( t ) => t + '%'}
                style={{
                  axis: {
                    stroke: "#D8D8D8"
                  },
                  tickLabels: {
                    fontSize: 10,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                    fill: "#707070"
                  },
                  grid: {
                    stroke: '#D8D8D8', 
                    strokeWidth: 0.5
                  }
                }}
              />
              <VictoryGroup
                offset={compareDatas.filter( item => item.y > 0 ).length > 0 && compareDisplay === true ? barWidth / 2 : 0}
              >
                <VictoryBar
                  data={currentDatas}
                  labels={ ({ datum }) => getRoundValueLabel( datum.y ) }
                  labelComponent={
                    <VictoryLabel 
                      dx={ ({datum}) => 
                        // set offset to label if match compared date value = 0
                        (
                          compareDisplay
                          && compareDatas.length > 0 
                          && compareDatas.filter( item => 
                            item.x === datum.x 
                            && ( Math.round( ( item.y + Number.EPSILON ) * 10 ) / 10 ) > 0 
                          ).length > 0 
                        ) ? -15 
                        : 0
                      }
                    />
                  }
                  cornerRadius={{ 
                    topLeft: 5,
                    topRight: 5 
                  }}
                  style={{ 
                    data: {
                      width: barWidth,
                      fill: currentColor,
                      strokeWidth: 1, 
                    },
                    labels: {
                      fontFamily: "Roboto",
                      fontSize: 10,
                      fontWeight: "bold",                      
                      fill: "#707070"
                    }
                  }}
                  // animate={{
                  //   duration: 200
                  // }}
                />

                {compareDatas.length > 0 && compareDisplay === true ?
                  <VictoryBar
                    data={compareDatas}   
                    labels={ ({ datum }) => getRoundValueLabel( datum.y ) }
                    labelComponent={<VictoryLabel dx={10} />}
                    cornerRadius={{ 
                      topLeft: 5,
                      topRight: 5 
                    }}  
                    style={{ 
                      data: {
                        width: barWidth,
                        fill: 'transparent',
                        stroke: compareDatas.filter( item => item.y > 0 ).length > 0 && compareDisplay === true ? compareColor : 'transparent',
                        strokeWidth: 1, 
                        strokeDasharray:"2"
                      },
                      labels: {
                        fontFamily: "Roboto",
                        fontSize: 10,
                        fontWeight: "300",                      
                        fill: "#707070"
                      }
                    }}
                    // animate={{
                    //   duration: 200
                    // }}
                  />
                  :false
                }
              </VictoryGroup>
            </VictoryChart>
            :false
          }
        </div>
      </div>
    </foreignObject>
  );
};

export default DataVizTooltipSERP;