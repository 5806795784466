/** Dependencies **/
import React, {useState, useRef, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import Loader from './../../../Loader';

/** Helpers */
import {
  formatCompareValues
} from './../../../../helpers/functions';

import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';

import { 
	getPicto
} from './../../../../helpers/pictos';

import {
  sortColumn,
  getPictoSort
} from './../../../../helpers/barometer/table';

import { filterOnValueToContext } from './../../../../helpers/barometer/barometer';

function GSCDetails( props ) 
{
	// Get props
  const {
    id,
    accessor,
    label
  } = props;

  // Define ref
	const tooltipGSC = useRef( null );

  /** Init state **/
  const [popinContent, setPopinContent] = useState( null );
  const [currentGSCDetails, setCurrentGSCDetails] = useState( null );
  const [compareGSCDetails, setCompareGSCDetails] = useState( null );
  const [followedKeywords, setFollowedKeywords] = useState( null );
  const [sort, setSort] = useState( { value: 'clicks', order: 'desc' } );

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /**
	 * Load content of popin
	 */
	const loadContent = () => 
	{
		if( label.length > 0 )
		{
      // GSC
      if( 
        selectedFilters?.currentPeriod?.startdate !== undefined
        && selectedFilters?.currentPeriod?.enddate !== undefined
        && selectedFilters.devLoc !== undefined
      ){  
        // GSC current details
        callWebservice(
					id,
					id + '-currentGSCDetails',
					'gsc-details-by-urls',
					dispatch,
          selectedInstance,
					{
						cols: ['kwGSC.keyword', 'url', 'SUM(impressions) AS impressions', 'SUM(clicks) AS clicks', 'AVG(position) AS position', 'serpUrl'],
						period: {
              startdate: selectedFilters.currentPeriod.startdate,
              enddate: selectedFilters.currentPeriod.enddate
            },
						where: {
							url: label,
							locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
							devices: [selectedFilters.devLoc.deviceValue]
						},
						groupby: ['kwGSC.keyword']
					},
					{ function: 'setCurrentGSCDetails' }
				);

        // GSC compare details
        if( 
          selectedFilters?.comparePeriod?.startdate !== undefined
          && selectedFilters?.comparePeriod?.enddate !== undefined
          && selectedFilters.devLoc !== undefined
        ){ 
          callWebservice(
            id,
            id + '-compareGSCDetails',
            'gsc-details-by-urls',
            dispatch,
            selectedInstance,
            {
              cols: ['keyword', 'url', 'SUM(impressions) AS impressions', 'SUM(clicks) AS clicks', 'AVG(position) AS position'],
              period: {
                startdate: selectedFilters.comparePeriod.startdate,
                enddate: selectedFilters.comparePeriod.enddate
              },
              where: {
                url: label,
                locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
                devices: [selectedFilters.devLoc.deviceValue]
              },
              groupby: ['keyword']
            },
            { function: 'setCompareGSCDetails' }
          );
        }

        // followed keywords
        if( 
          selectedFilters?.comparePeriod?.startdate !== undefined
          && selectedFilters?.comparePeriod?.enddate !== undefined
          && selectedFilters.devLoc !== undefined
        ){ 
          callWebservice( 
            id,        
            id + '-followedKeywords',
            'get-keywords',
            dispatch,
            selectedInstance,
            {
              period: {
                startdate: selectedFilters.currentPeriod.startdate,
                enddate: selectedFilters.currentPeriod.enddate
              },
              where: {
                devices: [selectedFilters.devLoc.deviceValue],
                locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue]
              }
            },
            {
              function: 'setFollowedKeywords',
              params: []
            }
          );
        }

      } else {
        setCurrentGSCDetails( [] );
        setCompareGSCDetails( [] );
        setFollowedKeywords( [] );
      }
		}
	}

	/**
   * Build popin content with current and compare loaded datas of gsc details
   */
  const buildPopinContent = () => 
  {
    let content = [];

    if( 
      currentGSCDetails[label] instanceof Array 
      && currentGSCDetails[label].length > 0 
      && followedKeywords instanceof Array
      && followedKeywords.length > 0
    ){
      // get all expected urls
      const allKeywords = [...new Set( followedKeywords.map( result => result.keyword ).flat(1) )];

      // set header table
      const header = 
        <thead>
          <tr>
            <th className='url'>mot clé</th>
            <th onClick={ () => sortColumn( 'impressions', sort, setSort ) }><button>{getPictoSort( 'impressions', sort )}impressions</button></th>
            <th onClick={ () => sortColumn( 'clicks', sort, setSort ) }><button>{getPictoSort( 'clicks', sort )}clics</button></th>
            <th onClick={ () => sortColumn( 'ctr', sort, setSort ) }><button>{getPictoSort( 'ctr', sort )}ctr</button></th>
            <th onClick={ () => sortColumn( 'position', sort, setSort ) }><button>{getPictoSort( 'position', sort )}position</button></th>
          </tr>
        </thead>;
      
      // set body table
      const lines = currentGSCDetails[label]
        .filter( detail => parseInt( detail.impressions ) > 1 )
        .sort( ( a, b ) => {
          return sort.value === 'ctr' ? 
            sort.order === 'desc' ? 
              ( b.clicks / b.impressions ) - ( a.clicks / a.impressions ) 
              : ( a.clicks / a.impressions ) - ( b.clicks / b.impressions ) 
          : sort.order === 'desc' ? 
              b[sort.value] - a[sort.value] 
              : a[sort.value] - b[sort.value]
        })
        .map( ( currentDatas, index ) => 
        {
          // get compare values
          let compareImpressions = null;
          let compareClicks = null;
          let comparePosition = null;
          let compareCTR = null;

          if( compareGSCDetails !== null && compareGSCDetails[label] )
          {
            const compareValues = compareGSCDetails[label].filter( datas => datas.keyword === currentDatas.keyword ).length === 1 ? 
              compareGSCDetails[label].filter( datas => datas.keyword === currentDatas.keyword )[0]
              : null

            if( compareValues !== null )
            {
              compareImpressions = formatCompareValues( currentDatas.impressions, compareValues.impressions ? compareValues.impressions : 0 );

              compareClicks = formatCompareValues( currentDatas.clicks, compareValues.clicks ? compareValues.clicks : 0 );
    
              if( Math.abs( compareImpressions ) > 0 && Math.abs( currentDatas.impressions ) > 0 )
                compareCTR = formatCompareValues( ( currentDatas.clicks / currentDatas.impressions ), ( compareClicks / compareImpressions ) );
    
              comparePosition = formatCompareValues( compareValues.position ? compareValues.position : 101, currentDatas.position );
            }
          };
                  
          return <tr key={index}>
            <td className='url'>
              { allKeywords.includes( currentDatas.keyword ) ? 
                <button className='followed' onClick={ () => filterOnValueToContext( currentDatas.keyword, 'label', 'keywords', navigate ) } title={currentDatas.keyword}>{currentDatas.keyword}</button>
                : <button onClick={ () => filterOnValueToContext( currentDatas.keyword, 'label', 'gsc-keywords', navigate ) } title={currentDatas.keyword}>{currentDatas.keyword}</button>                  
              }              
              { currentDatas.serpUrl !== null ?
                <a href={ currentDatas.serpUrl } target="_blank" rel="noreferrer" title={currentDatas.serpUrl}>{getPicto( 'Google Viewer', { width: "1.5rem", id: id })}</a>
                : false
              }
            </td>
            <td>
              { new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentDatas.impressions ) }
              { compareImpressions !== null && Math.abs( compareImpressions ) !== 0 ? <span> ({compareImpressions})</span> : false }
            </td>
            <td>
              { new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentDatas.clicks ) }
              { compareClicks !== null && Math.abs( compareClicks ) !== 0 ? <span> ({compareClicks})</span> : false }
            </td>
            <td>
              {currentDatas.impressions > 0 ? 
                new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, style: 'percent' }).format( ( currentDatas.clicks / currentDatas.impressions ) )
                : false 
              }
              { compareCTR !== null && Math.abs( compareCTR ) !== 0 ? <span> ({compareCTR})</span> : false }
            </td>
            <td>
              { new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentDatas.position ) }
              { comparePosition !== null && Math.abs( comparePosition ) !== 0 ? <span> ({comparePosition})</span> : false }
            </td>
          </tr>
        }
      );

      content = [...content, 
        <table key="table-content">
          {header}
          <tbody>{lines}</tbody>
        </table>
      ];
    }

    // Set popin content
    setPopinContent( content.length > 0 ? content : null );
  }

  /**
   * Build content when datas are loaded
   */
  useEffect( () => 
  {
    if( 
      currentGSCDetails !== null 
      && compareGSCDetails !== null 
      && followedKeywords !== null 
    )
      buildPopinContent();
  }, [ 
    currentGSCDetails,
    compareGSCDetails,
    followedKeywords,
    sort
  ]);

	return (
		<ReactTooltip 
			id={id}
			ref={tooltipGSC}
			className="tooltip grey"
			effect='solid'
      offset={{left: 250}}
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterHide={ () => {
				tooltipGSC.current.tooltipRef.style.left = null;
				tooltipGSC.current.tooltipRef.style.top = null;
			}}
      afterShow={loadContent}
			globalEventOff={'click'}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset in terms of gsc data type
        let offsetX = 0;
        switch ( accessor ) {
          case 'gsc.impressions':
            offsetX = 20;
            break;
          case 'gsc.clicks':
            offsetX = -80;
            break;
          case 'gsc.ctr':
            offsetX = -164;
            break;        
          default:
            offsetX = -247;
            break;
        }
        
        return {
          left: - width / 2 + offsetX,
          top: 50
        };
      }}
		>
      <React.Fragment>
        <Loader 
          loaderID={id} 
          loaderStyle={{width:'15', stroke:'#e43e21'}} 
          callBackFcts={{
            setCurrentGSCDetails: setCurrentGSCDetails,
            setCompareGSCDetails: setCompareGSCDetails,
            setFollowedKeywords: setFollowedKeywords
          }}
          globalCallBack={undefined} 
        />
        {popinContent !== null ?
          <div className='tooltip-container'>
            {popinContent}
          </div>
          : false
        }
      </React.Fragment>      
		</ReactTooltip>			
	);
}

export default GSCDetails;