/** Dependencies **/
import React from "react";
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import PerfSortToolTip from './../SortToolTips/PerfSortToolTip';
import RangeFilter from './Filters/RangeFilter';
import SelectFilter from './Filters/SelectFilter';
import AutoSuggestFilter from './Filters/AutoSuggestFilter';
import TogglerFilter from './Filters/TogglerFilter';

/** Helpers */
import { 
  stringURLPerfsCustomFilter,
  stringKeywordPerfsCustomFilter
} from './../../../../helpers/barometer/customFilters';

/**
 * Define a specific UI for Performance filtering
 * @param {object} {column: { filterValue, preFilteredRows, setFilter }...}
 * @returns ReactDOM with input
 */
function PerfFilterToolTip ( props )
{
  /** Get props **/
  const id = props.column.id;

  /** Get state from redux store **/
  const snippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  const comparePeriod = useSelector( state => state.selectedFilters.value.comparePeriod );
  const barometerContext = useSelector( state => state.barometerContext.value );

  /** Set preselect filters  **/
  // Positions
  const preselectFiltersPos = [
    { value: '1|1', label: 'Position 1' },
    { value: '0|3', label: 'Top 3' },
    { value: '0|5', label: 'Top 5' },
    { value: '0|10', label: 'Top 10' },
    { value: '11|20', label: 'Page 2' },
    { value: '101|', label: 'Non classé' }
  ];

  // average positions
  const preselectFiltersAvgPos = [
    {value: '0|3', label: 'moins de 3'},
    {value: '4|6', label: '4 ▶︎ 6'},
    {value: '6|9', label: '6 ▶︎ 9'},
    {value: '10|20', label: '10 ▶︎ 20'},
    {value: '20|30', label: '20 ▶︎ 30'},
    {value: '30|101', label: 'plus de 30'}
  ];

  // Entrées / Sorties
  const preselectFiltersES = [
    { value: 'Entrées', label: 'Entrées' },
    { value: 'Sorties', label: 'Sorties' },
  ];

  // Au dessus / Au dessous de la ligne de flottaison
  const preselectFilterAboveFold = [
    { value: 'Au dessus', label: 'Au dessus' },
    { value: 'Au dessous', label: 'Au dessous' }
  ];

  /** Get all snippets to build snippet select filter **/
  const currentSnippets = [
    { label: 'SEO', value: 'SEO' },
    { label: 'PAA', value: 'PAA' },
    { label: 'Recipes', value: 'Recipes' },
    { label: 'Position 0', value: 'Featured Snippet' },
    { label: 'Images', value: 'Images' },
    { label: 'Find Results On', value: 'Find Results On' },    
    { label: 'Local Results', value: 'Local Results' },
  ];  

  return (
    <ReactTooltip 
      id={'filter-' + id}
      className="tooltip red"
      effect='solid'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="#e43e21"
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset
        let offsetX = 0;
        if( 
          barometerContext === 'categories' 
          && (
            id === 'perfgp1' || id === 'perfgp2' || id === 'perfgp3' 
          )
        )
          offsetX = -40;
        else if( 
          barometerContext === 'urls' 
          && id === 'perfme'
        )
          offsetX = -40;
        
        return {
          left: currentTarget.offsetLeft - (width / 2) + offsetX,
          top: 17
        };
      }}
    >
      <div className="filter-container perf">
        <PerfSortToolTip column={props.column} id={id} />  
        <div className="filters">
          <div className="title"><h4>Filtrer</h4></div>
          <div className="filters-container">

            <div className="filter select">            
              <SelectFilter 
                {...props} 
                title="type de résultats" 
                fieldName="sous-snippets"
                typeFilter="select-snippets"
                optionsValues={currentSnippets} 
              /> 
            </div>

            {
              barometerContext === 'keywords' || barometerContext === 'categories' ?
                <div className="filter range">
                  <RangeFilter 
                    {...props} 
                    title="présence seo" 
                    fieldName="presence-seo" 
                    typeFilter="range-presence-seo"
                    preselectFilters={preselectFiltersPos} 
                  /> 
                </div>  
              : false
            } 

            { barometerContext === 'urls' ?
                <div className="filter range">
                  <RangeFilter 
                    {...props} 
                    title="position moyenne" 
                    fieldName="avg-position" 
                    typeFilter="range-avg-position"
                    preselectFilters={preselectFiltersAvgPos} 
                  /> 
                </div> 
              : false
            }     

            {
              (
                comparePeriod.startdate 
                && comparePeriod.startdate !== undefined
                && comparePeriod.enddate 
                && comparePeriod.enddate !== undefined
              ) ?
                <div className="filter select">
                  <SelectFilter 
                    {...props} 
                    title="Entrées / Sorties" 
                    fieldName="in-out"
                    typeFilter="select-in-out"
                    optionsValues={preselectFiltersES} 
                  />    
                </div>
              : false
            }

            { barometerContext === 'urls' ?
                <div className="filter range">
                  <RangeFilter 
                    {...props} 
                    title="nb. mots clés" 
                    fieldName="nb-keywords" 
                    typeFilter="range-nb-keywords"
                    preselectFilters={[]} 
                  /> 
                </div> 
              : false
            } 

            <div className="filter range">
              <RangeFilter 
                {...props} 
                title="taux d'occupation de la serp" 
                fieldName="taux-occupation"
                typeFilter="range"
                preselectFilters={[]} 
                unit={'%'}
              />    
            </div>

            <div className="filter select">
              <SelectFilter 
                {...props} 
                title="Ligne de flottaison" 
                fieldName="above-fold" 
                typeFilter="select-above-fold"
                optionsValues={preselectFilterAboveFold}
              />
            </div>

            {
              (
                barometerContext !== 'urls'
                && comparePeriod.startdate 
                && comparePeriod.startdate !== undefined
                && comparePeriod.enddate 
                && comparePeriod.enddate !== undefined
              ) ?
                <div className="filter select">
                  <TogglerFilter 
                    {...props} 
                    title="Yoyo URLs" 
                    fieldName="yoyo-url"
                    typeFilter="select-yoyo-url"
                    label="Yoyo URLs" 
                  />    
                </div>
              : false
             }          

             {  barometerContext === 'keywords' ?
                <div className="filter select">
                  <TogglerFilter 
                    {...props} 
                    title="URL pos. ≠ URL. att." 
                    fieldName="urlpos-urlatt"
                    typeFilter="select-urlpos-urlatt"
                    label="URL pos. ≠ URL. att." 
                    filterParams={{ snippetsDetails: snippetsDetails }}
                  />    
                </div>   
                : false
              } 
          </div>          
        </div>
        <div className="url">
          <div className="title">            
              { barometerContext === 'keywords' ?
                <h4>Chercher un domaine ou une URL</h4>
                : barometerContext === 'urls' ? 
                <h4>Chercher un mot clé</h4>
                : false
              }
          </div>
          <div className="filter text">        
            <AutoSuggestFilter 
              {...props} 
              filterFct={ 
                barometerContext === 'keywords' ?
                stringURLPerfsCustomFilter
                : barometerContext === 'urls' ? 
                stringKeywordPerfsCustomFilter
                : false
              }
              filterType={ 
                barometerContext === 'keywords' ?
                'string-url-perfs'
                : barometerContext === 'urls' ? 
                'string-keyword-perfs'
                : false
              }
              filterParams={{ snippetsDetails: snippetsDetails }}
            />
          </div>
        </div>
      </div>
    </ReactTooltip>
  )
}

export default PerfFilterToolTip;