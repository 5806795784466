/** Dependencies **/
import React, {useState, useEffect} from 'react';
import { FullScreen } from "react-full-screen";
import { 
  VictoryChart, 
  VictoryAxis, 
  VictoryStack,
  VictoryBar,
  VictoryGroup,
  VictoryTooltip
} from 'victory';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { onToggler, offToggler } from './../../../../reducers/toggler';

/** Components **/
import TogglerRadioOption from './../DataVizOptions/TogglerRadioOption';
import TogglerCheckboxOption from './../DataVizOptions/TogglerCheckboxOption';
import DataVizTooltipSERP from './../DataVizTooltips/DataVizTooltipSERP';

/** Helpers **/
import { rewriteTrendObjectData } from './../../../../helpers/barometer/charts.js';
import { orderObjectKeysWithPattern } from './../../../../helpers/functions.js';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

function DataVizSERP( props, ref ) 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Define FullScreenHandle and globalTrend ref **/
  const chartFullScreenHandle = props.fullscreenhandle;

  /** Get state from redux store */
  const togglers = useSelector( state => state.togglers.value ); 

  /** Init state **/
  const [grpSnippetsDisplay, setGrpSnippetsDisplay] = useState( {} );

  /** Get config values **/
  const grpSnippets = globalConfig.barometer.snippet_grps;

  /** Init datas for chart **/
  let currentGrpSnippetsTrend = {};
  let compareGrpSnippetsTrend = {};

  /** Rewrite array datas **/
  if( 
    props?.currentSERPTrend?.grpSnippets !== undefined
    && Object.keys( props.currentSERPTrend.grpSnippets ).length > 0 
  ){
    // Rewrite Current SERP Trend datas
    currentGrpSnippetsTrend = rewriteTrendObjectData( 
      props.currentSERPTrend.grpSnippets,
      togglers['radio-view-option'] ? 'month' : 'week'
    );

    // Rewrite Compare SERP Trend datas
    if( 
      props?.compareSERPTrend?.grpSnippets !== undefined
      && Object.keys( props.compareSERPTrend.grpSnippets  ).length > 0
    )
      compareGrpSnippetsTrend = rewriteTrendObjectData( 
        props.compareSERPTrend.grpSnippets,
        togglers['radio-view-option'] ? 'month' : 'week'
      );    
  };

  /** Init datas for tooltip **/
  let currentDetailsSnippetsTrend = [];
  let compareDetailsSnippetsTrend = [];

  /** Rewrite array datas **/
  if( 
    props?.currentSERPTrend?.detailGrpSnippets !== undefined
    && Object.keys( props.currentSERPTrend.detailGrpSnippets ).length > 0 
  ){
    // Rewrite Current SERP Trend datas
    currentDetailsSnippetsTrend = props.currentSERPTrend.detailGrpSnippets;

    // Rewrite Compare SERP Trend datas
    if( 
      props?.compareSERPTrend?.detailGrpSnippets !== undefined
      && Object.keys( props.compareSERPTrend.detailGrpSnippets  ).length > 0
    )
      compareDetailsSnippetsTrend = props.compareSERPTrend.detailGrpSnippets;
  };

  /** Sort trand data order by array pattern **/
  const orderKeys = ['SEO', 'Google', 'Ads', 'Snippets'];
  currentGrpSnippetsTrend = orderObjectKeysWithPattern( currentGrpSnippetsTrend, orderKeys );
  compareGrpSnippetsTrend = orderObjectKeysWithPattern( compareGrpSnippetsTrend, orderKeys );

  /** Define width of chart and bars **/
  let barWidth = 0;
  if( Object.keys( currentGrpSnippetsTrend ).length > 0 )
  {
    const nbMonth = currentGrpSnippetsTrend[Object.keys( currentGrpSnippetsTrend )[0]].length;
    barWidth = 1300 / nbMonth * 0.5;
    if( togglers['radio-compare-option'] === true && Object.keys(compareGrpSnippetsTrend).length > 0 )
      barWidth = 1300 / nbMonth * 0.75 - ( (1300 / nbMonth * 0.75) / 2 );
  }

  /** Get if all displayed values = 0 to set default dependant axis values */
  // get current sum values
  let valuesSumDisplay = Object.keys( grpSnippetsDisplay ).filter( grp => grpSnippetsDisplay[grp] ).map( grp => 
    currentGrpSnippetsTrend[grp] ? currentGrpSnippetsTrend[grp].reduce( ( a, b ) => a + b.y, 0 ) : 0 
  ).reduce( (a, b) => a + b, 0 );

  // get compare sum values
  valuesSumDisplay += Object.keys( grpSnippetsDisplay ).filter( grp => grpSnippetsDisplay[grp] ).map( grp => 
    compareGrpSnippetsTrend[grp] ? compareGrpSnippetsTrend[grp].reduce( ( a, b ) => a + b.y, 0 ) : 0
  ).reduce( (a, b) => a + b, 0 );
  
  /** Init set display state for groups from config **/
  useEffect( () => 
  {    
    // init true value from config file
    let initGrpSnippetsDisplay = {};
    Object.keys(grpSnippets).forEach( grp => {
      initGrpSnippetsDisplay = {...initGrpSnippetsDisplay, [grp]: true };
    });

    // set value
    setGrpSnippetsDisplay( initGrpSnippetsDisplay );
  }, [] );

  /**
   * 
   * @param {String} value group name
   * @param {DOMELement} element checkbox DOMElement
   */
  const callBackCheckTogglerFn = ( value, element, only=false ) => 
  {
    // get id of element
    const elementID = element.id;

    // clone grpDomainDisplay
    const grpSnippetsDisplayClone = {...grpSnippetsDisplay};
    
    // if only selected => set all checkboxes to false
    if( only === true )      
      Object.keys( grpSnippetsDisplayClone ).map( label => grpSnippetsDisplayClone[label] = false )

    if( elementID )
    {
      // set new value
      grpSnippetsDisplayClone[elementID] = ( value === true && only === false ) ? false : true;

      // update grpDomainDisplay object
      setGrpSnippetsDisplay( grpSnippetsDisplayClone );
    }
  };

  return (
    <React.Fragment>
      <div className='dataviz-options-container'>
        {Object.keys( grpSnippets ).map( ( grp, index ) => 
          <TogglerCheckboxOption 
            key={index}
            id={grp}
            togglerState={grpSnippetsDisplay[grp]} 
            callBackTogglerFn={ callBackCheckTogglerFn } 
            label={grpSnippets[grp].label} 
            borderColor={grpSnippets[grp].currentColor}
            backgroundColor={grpSnippets[grp].compareColor}
          />
        )}
        <span className='separator'>&nbsp;</span>
        <TogglerRadioOption 
          id="radio-compare-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-compare-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          }
          label="afficher la période de comparaison"
        />
        <span className='separator'>&nbsp;</span>
        <TogglerRadioOption 
          id="radio-view-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-view-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          } 
          label="Sem. / Mois"
        />
      </div>
      <div className='dataviz-container'>        
        <div className='dataviz-chart' ref={ref}>
          <FullScreen handle={chartFullScreenHandle}>
            {Object.keys( currentGrpSnippetsTrend ).length > 0 ?
              <VictoryChart
                width={1300}
                height={250}
                padding={{ top: 10, bottom: 30, left: 50, right: 20 }}
                domainPadding={{x: barWidth / 2 + 50}}
              >
                <VictoryAxis 
                  tickValues={currentGrpSnippetsTrend[Object.keys( currentGrpSnippetsTrend )[0]].map( elem => elem.tickValue )}
                  style={{
                    tickLabels: {
                      fontSize: 6.5,
                      fontFamily: 'Roboto',
                      fontWeight: 'bold',
                      fill: '#626262',
                      angle: -45
                    }
                  }}
                />
                <VictoryAxis dependentAxis 
                  offsetX={50}
                  tickValues={valuesSumDisplay === 0 ? [0] : []}
                  tickFormat={ t => new Intl.NumberFormat( 'fr-FR' ).format( t ) }
                  style={{
                    tickLabels: {
                      fontSize: 16,
                      fontFamily: 'Roboto',
                      fontWeight: 300,
                      fill: '#707070'
                    },
                    grid: {
                      stroke: '#D8D8D8', 
                      strokeWidth: 0.5
                    }
                  }}
                />
                {Object.keys(grpSnippetsDisplay).filter( grp => grpSnippetsDisplay[grp] ).length > 0 ?
                  <VictoryGroup
                    offset={togglers['radio-compare-option'] === true && Object.keys(compareGrpSnippetsTrend).length > 0 ? barWidth / 2 : 0}
                  >
                    <VictoryStack>
                      {Object.keys(currentGrpSnippetsTrend).map( (grp, index) => 
                        grpSnippetsDisplay[grp] ?
                          <VictoryBar
                            key={index}
                            data={currentGrpSnippetsTrend[grp]}
                            labels={ () => ' ' } // space value needed to display label component
                            labelComponent={
                              <VictoryTooltip
                                {...props}
                                text={() => ''}
                                flyoutComponent={
                                  <DataVizTooltipSERP 
                                    {...props}                                     
                                    currentDetails={currentDetailsSnippetsTrend} 
                                    compareDetails={compareDetailsSnippetsTrend}
                                    parentBarWidth={barWidth}
                                    compareDisplay={togglers['radio-compare-option']}
                                    currentColor={grpSnippets[grp].currentColor}
                                    compareColor={grpSnippets[grp].compareColor}
                                  />
                                }
                              />
                            }
                            style={{ 
                              data: {
                                width: barWidth,
                                fill: grpSnippets[grp].currentColor,
                                strokeWidth: 1, 
                                cursor: 'pointer'
                              }
                            }}
                            cornerRadius={ ( {datum} ) => {
                              if( datum._stack === Object.keys( currentGrpSnippetsTrend ).length ){
                                if( datum.y > 5 )
                                  return barWidth < 70 ? barWidth * 0.1 : 10;
                                else 
                                  return 0;
                              } else
                                return 0;
                            }}
                          />       
                        :false
                      )}
                    </VictoryStack>

                    {(
                      togglers['radio-compare-option'] 
                      && Object.keys(compareGrpSnippetsTrend).length > 0 
                    )?
                      <VictoryStack>
                        {Object.keys(compareGrpSnippetsTrend).map( (grp, index) => 
                          grpSnippetsDisplay[grp] ?
                            <VictoryBar
                              key={index}
                              data={compareGrpSnippetsTrend[grp]}
                              style={{ 
                                data: {
                                  width: barWidth,
                                  fill: 'transparent',
                                  stroke: () => grpSnippets[grp].compareColor, 
                                  strokeWidth: 1, 
                                  strokeDasharray: 2
                                }
                              }}
                              // animate={{
                              //   duration: 200
                              // }}
                              cornerRadius={ ( {datum} ) => {
                                if( datum._stack === Object.keys( currentGrpSnippetsTrend ).length ){
                                  if( datum.y > 5 )
                                    return barWidth < 70 ? barWidth * 0.1 : 10;
                                  else 
                                    return 0;
                                } else
                                  return 0;
                              }}
                            />       
                          :false
                        )}
                      </VictoryStack>
                      :false
                    }
                  </VictoryGroup>
                  :false
                }
              </VictoryChart>
              :false
            }
          </FullScreen>
        </div>
      </div>
    </React.Fragment>
	);
}

export default React.forwardRef( DataVizSERP );