/** Dependencies **/
import React from 'react';
import { FullScreen } from "react-full-screen";
import { 
  VictoryChart, 
  VictoryLine, 
  VictoryAxis, 
  VictoryGroup, 
  VictoryScatter,
  createContainer
} from 'victory';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { onToggler, offToggler } from './../../../../reducers/toggler';

/** Components **/
import TogglerRadioOption from './../DataVizOptions/TogglerRadioOption';
import DataVizTooltipVolume from './../DataVizTooltips/DataVizTooltipVolume';

/** Helpers **/
import { rewriteTrendArrayData } from './../../../../helpers/barometer/charts.js'

function DataVizVolume( props, ref ) 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Create Custom Victory Container for cursor and voronoi **/
  const VictoryCursorVoronoiContainer = createContainer( "voronoi", "cursor" );

  /** Define FullScreenHandle and globalTrend ref **/
  const chartFullScreenHandle = props.fullscreenhandle;

  /** Get state from redux store */
  const togglers = useSelector( state => state.togglers.value ); 

  /** Init datas for chart **/
  let globalCurrentTrend = [];
  let globalCompareTrend = [];

  if( 
    props.currentVolumeTrend instanceof Array 
    && props.currentVolumeTrend.length > 0
  ){
    // Rewrite Current Volume Trend datas
    globalCurrentTrend = rewriteTrendArrayData( props.currentVolumeTrend, 'current' );

    // Rewrite compareVolumeTrend data
    if( 
      props.compareVolumeTrend instanceof Array 
      && props.compareVolumeTrend.length > 0
    )
      // Rewrite Compare Volume Trend datas
      globalCompareTrend = rewriteTrendArrayData( props.compareVolumeTrend, 'compare' );
  }

  /** Get domain max values **/
  let domain = {};
  if( globalCurrentTrend.length === 1 )
   if( globalCompareTrend.length > 0 )
    domain = {x:[0, 2], y:[0, Math.max(globalCurrentTrend[0].y, globalCompareTrend[0].y)] };
   else
    domain = {x:[0, 2], y:[0, globalCurrentTrend[0].y]};

  /** Get if all displayed values = 0 to set default dependant axis values */
  // get current sum values
  let valuesSumDisplay = globalCurrentTrend.reduce( ( a, b ) => a + b.y, 0 );

  // get compare sum values
  valuesSumDisplay += globalCompareTrend.reduce( ( a, b ) => a + b.y, 0 );

  return (
    <React.Fragment>
      <div className='dataviz-options-container'>
        <TogglerRadioOption 
          id="radio-compare-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-compare-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          }
          label="afficher la période de comparaison"
        />
      </div>
      <div className='dataviz-container'>
        <div className='dataviz-chart volume' ref={ref}>
          <FullScreen handle={chartFullScreenHandle}>
            <VictoryChart
              width={1300}
              height={250}
              domainPadding={{y: 40}}
              padding={{ top: 10, bottom: 30, left: 50, right: 20 }}
              domain={domain}
              minDomain={{y: 0}}
              containerComponent={
                <VictoryCursorVoronoiContainer 
                  voronoiBlacklist={["currentLine", "compareLine"]}
                  voronoiDimension="x"
                  cursorDimension="x"
                  labels={ () => ' ' } // space value needed to display label component
                  labelComponent={ <DataVizTooltipVolume /> }  
                />
              }
            >
              <VictoryAxis
                tickValues={ globalCurrentTrend.map( elem => elem.tickValue ) }
                style={{
                  tickLabels: {
                    fontSize: 6.5,
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fill: "#626262",
                    angle: -45
                  },
                  grid: {
                    stroke: '#f5f2f2', 
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryAxis dependentAxis
                tickValues={valuesSumDisplay === 0 ? [0] : []}
                tickFormat={( t ) => ( t >= 1000 ? new Intl.NumberFormat( 'fr-FR' ).format( t / 1000 ) + ' k' : new Intl.NumberFormat( 'fr-FR' ).format( t ) )}
                offsetX={50}
                style={{
                  tickLabels: {
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fontWeight: 300,
                    fill: '#626262'
                  },
                  grid: {
                    stroke: '#f5f2f2', 
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryGroup data={globalCurrentTrend}>
                <VictoryLine
                  name = "currentLine"
                  style={{
                    data: {
                      stroke: '#ff9d00',
                      strokeWidth: 2
                    }
                  }}
                />
                <VictoryScatter
                  name="currentScatter"
                  style={{
                    data: {fill: '#ff9d00'}
                  }}
                  size={({ active }) => active ? 5 : 3 } 
                />
              </VictoryGroup>
              {togglers['radio-compare-option'] === true && globalCompareTrend.length > 0 ?
                <VictoryGroup data={globalCompareTrend}>
                  <VictoryLine
                    name = "compareLine"
                    style={{ 
                      data: { 
                        stroke: '#ff9d00',
                        strokeDasharray: 3,
                        strokeWidth: 1
                      }
                    }}
                  />
                  <VictoryScatter 
                    name="compareScatter"
                    style={{
                      data: {fill: '#ff9d00'}
                    }}
                    size={({ active }) => active ? 5 : 3 } 
                  />
                </VictoryGroup>
              :false
              }
            </VictoryChart>
          </FullScreen>
        </div>
      </div>
    </React.Fragment>
	);
}

export default React.forwardRef( DataVizVolume );