/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsKeywords
{
  constructor(
    selectedFilters,
    loaderID,
    dispatch,
    selectedInstance
  ){
    this.device = selectedFilters.devLoc.deviceValue;
    this.location = selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue;
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  addKeywords = ( 
    keywords, 
    categories, 
    extractSources,
    updateTable = false
  ) => 
  {
    this.exec(
      keywords, 
      categories, 
      'add',
      extractSources,
      updateTable
    );
  }
  
  updateKeywords = (
    keywords, 
    categories, 
    extractSources,
    updateTable = false
  ) => 
  {
    this.exec(
      keywords, 
      categories, 
      'update',
      extractSources,
      updateTable
    );
  }
  
  delKeywords = (
    keywords,
    updateTable = false
  ) => 
  {
    this.exec(
      keywords, 
      null, 
      'remove',
      null,
      updateTable
    );
  }
  
  exec = (
    keywords, 
    categories, 
    actionType,
    extractSources,
    updateTable = false
  ) => 
  {
    callWebservice( 
      this.loaderID,
      actionType + '-keywords',
      actionType + '-keywords',
      this.dispatch,
      this.selectedInstance,
      {
        keywords: keywords, 
        categories: categories,
        device: actionType === 'update' ? [this.device] : this.device,
        location: actionType === 'update' ? [this.location] : this.location,
        extractSources: extractSources
      },
      updateTable === true ? {
        function: 'updateCategoriesState'
      } : null
    );
  }
}