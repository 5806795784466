/** Dependencies **/
import React from 'react';

/** Helpers **/
import { getPicto } from './../../../../helpers/pictos.js';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

function Snippets( props ) {

  /** Get props **/
  const type = props.type;
  const title = props.title;

  /** Get label of snippet **/
  const options = globalConfig.barometer.snippet_grps[type].header_picto_options;

  return (
    <React.Fragment>
      {getPicto( type, options )}<h4>{title}</h4>
    </React.Fragment>
  );
}

export default Snippets;