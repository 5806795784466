/** Dependencies **/
import moment from 'moment';

/** Helpers **/
import { cleanString } from './../functions';
import { getUpdateOrAutoValue } from './../datas';

/**
 * Define custom filters
 * @returns object conf for custom filters of table
 */
export const getCustomFilters = () => 
{
  return {
    multiFieldsCustomFilter: ( rows, id, filterValue ) => 
    {
      if( 
        filterValue instanceof Array 
        && filterValue.length > 0 
      ){
        // filter rows for each filter of column
        filterValue.forEach( filter => 
        {
          // get result
          const value = filter.value.result;

          // get field
          const type = filter.value.type;

          switch ( type ) {
            // range filter type
            case 'range':
              rows = rangeCustomFilter( rows, id, value );
              break;
            case 'range-presence-seo':
              rows = rangePresenceSEOCustomFilter( rows, id, value );
              break;
            case 'range-avg-position':
              rows = rangeAvgPositionCustomFilter( rows, id, value );
              break;
            case 'range-nb-keywords':
              rows = rangeNbKeywordsCustomFilter( rows, id, value );
              break;

            // date range filter type
            case 'range-date':
              rows = identifierGscDateRangeCustomFilter( rows, id, value );
              break;

            // select filter type
            case 'select-action':
              rows = selectActionCustomFilter( rows, id, value );
              break;
            case 'select-intention':
              rows = selectIntentionCustomFilter( rows, id, value );              
              break;
            case 'select-snippets':
              rows = selectSnippetCustomFilter( rows, id, value );
              break;
            case 'select-in-out':
              rows = selectInOutCustomFilter( rows, id, value );
              break;
            case 'select-yoyo-url':
              rows = selectYoyoURLCustomFilter( rows, id, value );
              break;
            case 'select-type-expected-url':
              rows = selectTypeExpectedURLCustomFilter( rows, id, value );
              break;
            case 'select-pageType':
              rows = selectPageTypeCustomFilter( rows, id, value );
              break;
            case 'select-above-fold':
              rows = selectAboveFoldCustomFilter( rows, id, value )
              break;
            case 'select-urlpos-urlatt':
              rows = selectURLPosURLAttCustomFilter( rows, id, value, filter.params )
              break;

            // string filter type
            case 'string-label':
              rows = stringLabelCustomFilter(
                rows,
                id,
                {
                  value: value,
                  option: filter.option
                }
              );
              break;
            case 'string-keyword-perfs':
              rows = stringKeywordPerfsCustomFilter(
                rows,
                id,
                {
                  value: value,
                  option: filter.option
                }
              );
              break;
            case 'string-url-snippets':
              rows = stringURLSnippetsCustomFilter(
                rows,
                id,
                {
                  value: value,
                  option: filter.option,
                  usefor: filter.usefor,
                  selectValues: filter.selectValues
                },
                filter.params
              );
              break;
            case 'string-url-perfs':
              rows = stringURLPerfsCustomFilter(
                rows,
                id,
                {
                  value: value,
                  option: filter.option,
                  usefor: filter.usefor,
                  selectValues: filter.selectValues
                },
                filter.params
              );
              break;
            case 'string-url-gsc':
              rows = stringURLGSCCustomFilter(
                rows,
                id,
                {
                  value: value,
                  option: filter.option,
                  usefor: filter.usefor,
                  selectValues: filter.selectValues
                },
                filter.params
              );
              break;
            case 'string-url-expected':
              rows = stringURLExpectedCustomFilter(
                rows,
                id,
                {
                  value: value, 
                  option: filter.option,
                  usefor: filter.usefor,
                  selectValues: filter.selectValues
                }
              );
              break;
            default:
              break;
          }
        });  
      }

      // rebuild subrows which are deleted by setAllFilter (bug component)
      rows = rows.map( item => 
      {
        const itemClone = {...item};
        if( itemClone.depth && itemClone.depth === 0 )
          itemClone.subRows = itemClone.originalSubRows;

        return itemClone;
      });

      return rows;
    }
  };
}

export const stringKeywordPerfsCustomFilter = ( rows, id, values ) => 
{
  const value = values.value;
  const option = values.option;
  const usefor = values.usefor;

  let keywords = [];
  switch ( option ) {
    case 'notcontains':
      rows = rows.filter( row => 
        row.original.keywords.filter( keyword => !cleanString( keyword ).includes( cleanString( value ) ) ).length > 0
      );
      keywords = rows.map( row => 
        row.original.keywords.filter( keyword => !cleanString( keyword ).includes( cleanString( value ) ) )
      );
      break;
    case 'exactly':
      rows = rows.filter( row => 
        row.original.keywords.filter( keyword => cleanString( keyword ) === cleanString( value ) ).length > 0
      );
      keywords = rows.map( row => 
        row.original.keywords.filter( keyword => cleanString( keyword ) === cleanString( value ) )
      );
      break;
    case 'regex':
      try {
        rows = rows.filter( row => 
          row.original.keywords.filter( keyword => cleanString( keyword ).match( new RegExp( cleanString( value ), 'gi' ) ) ).length > 0
        );
        keywords = rows.map( row => 
          row.original.keywords.filter( keyword => cleanString( keyword ).match( new RegExp( cleanString( value ), 'gi' ) ) )
        );
      } catch (error) {
        rows = [];
      }
      break;
    // contains
    default:
      rows = rows.filter( row => 
        row.original.keywords.filter( keyword => cleanString( keyword ).includes( cleanString( value ) ) ).length > 0
      );
      keywords = rows.map( row => 
        row.original.keywords.filter( keyword => cleanString( keyword ).includes( cleanString( value ) ) )
      );
      break;
  }

  let results = rows; 
  if( usefor === 'autosuggest' )
    results = [...new Set( keywords )].flat();
  
  return results;
};

export const stringURLGSCCustomFilter = ( rows, id, values, params ) => 
{
  const value = values.value;
  const option = values.option;
  const usefor = values.usefor;

  let urls = [];
  let GSCDetails = {}
  if( params.GSCDetails )
  {
    urls = Object.keys( params.GSCDetails );
    GSCDetails = params.GSCDetails;
  }    

  let keywords = [];
  switch ( option ) {
    case 'notcontains':
      urls = urls.filter( url => !url.includes( value ) );
      break;
    case 'exactly':
      urls = urls.filter( url => url === value );
      break;
    case 'regex':
      try {
        urls = urls.filter( url => url.match( new RegExp( value, 'gi' ) ) );
      } catch (error) {
        rows = [];
      }
      break;
    // contains
    default:
      urls = urls.filter( url => url.includes( value ) );
      break;
  }

  // get keywords with filter match urls
  keywords = [...new Set( urls.map( url => GSCDetails[url].map( detail => detail.keyword ) ).flat() )];

  // filter rows with keywords
  rows = rows.filter( row => 
    keywords.includes( row.original.label )
  );

  let results = rows; 
  if( usefor === 'autosuggest' )
    results = [...new Set( urls )].flat();
  
  return results;
};

export const stringURLPerfsCustomFilter = ( rows, id, values, params ) =>
{
  const value = values.value;
  const option = values.option;
  const currentID = id instanceof Array ? id[0] : id;
  const grp = currentID.replace( 'perf', '' );
  const usefor = values.usefor;
  const snippetsDetails = params.snippetsDetails;

  let matchedURLS = [];
  switch ( option ) {
    case 'notcontains':
      matchedURLS = matchedURLS.concat( 
        snippetsDetails.filter( detail => 
          detail.who === grp 
          && !detail.url.includes( value ) 
        ).map( detail => detail ) 
      );
      break;
    case 'exactly':
      matchedURLS = matchedURLS.concat( 
        snippetsDetails.filter( detail => 
          detail.who === grp 
          && detail.url === value 
        ).map( detail => detail ) 
      );
      break;
    case 'regex':
      try {
        matchedURLS = matchedURLS.concat( 
          snippetsDetails.filter( detail => 
            detail.who === grp 
            && detail.url.match( new RegExp( value, 'gi' ) ) 
          ).map( detail => detail ) 
        );
      } catch (error) {
        matchedURLS = [];
      }
      break;
    default:
      matchedURLS = matchedURLS.concat( 
        snippetsDetails.filter( detail => 
          detail.who === grp 
          && detail.url.includes( value ) 
        ).map( detail => detail ) 
      );
      break;
  }

  let result = []; 
  let keywords = [];      
  if( usefor === 'autosuggest' )
  {
    // get all keywords from all rows
    rows.forEach( row => keywords = [...keywords, ...row.original.keywords] );
    
    // distinct results      
    keywords = [...new Set( keywords )];

    // apply filter
    result = matchedURLS.filter( match => keywords.includes( match.keyword ) ).map( match => match.url );

  } else if( usefor === 'filter' ) {
    
    // get all keywords from all matched url
    matchedURLS.forEach( match => keywords = [...keywords, match.keyword] );
    
    // distinct results      
    keywords = [...new Set( keywords )];

    // apply filter
    result = rows.filter( row => 
      row.original.keywords.filter( keyword => 
        keywords.includes( keyword ) 
      ).length > 0 
    );
  }
  
  return result;
};

export const stringURLSnippetsCustomFilter = ( rows, id, values, params ) => 
{
  const value = values.value;
  const option = values.option;
  const usefor = values.usefor;
  const selectedSnippets = values.selectValues;
  const snippetsDetails = params.snippetsDetails;

  let matchedURLS = [];
  selectedSnippets.forEach( snippet => 
  {
    switch ( option ) {
      case 'notcontains':
        matchedURLS = matchedURLS.concat( 
          snippetsDetails.filter( detail => 
            detail.typeSnippet === snippet 
            && !detail.url.includes( value ) 
          ).map( detail => detail ) 
        );
        break;
      case 'exactly':
        matchedURLS = matchedURLS.concat( 
          snippetsDetails.filter( detail => 
            detail.typeSnippet === snippet 
            && detail.url === value 
          ).map( detail => detail ) 
        );
        break;
      case 'regex':
        try {
          matchedURLS = matchedURLS.concat( 
            snippetsDetails.filter( detail => 
              detail.typeSnippet === snippet 
              && detail.url.match( new RegExp( value, 'gi' ) ) 
            ).map( detail => detail ) 
          );
        } catch (error) {
          matchedURLS = [];
        }
        break;
      default:
        matchedURLS = matchedURLS.concat( 
          snippetsDetails.filter( detail => 
            detail.typeSnippet === snippet 
            && detail.url.includes( value ) 
          ).map( detail => detail ) 
        );
        break;
    }
  });

  let result = []; 
  let keywords = [];      
  if( usefor === 'autosuggest' )
  {
    // get all keywords from all rows
    rows.forEach( row => keywords = [...keywords, ...row.original.keywords] );
    
    // distinct results      
    keywords = [...new Set( keywords )];

    // apply filter
    result = matchedURLS.filter( match => 
      keywords.includes( match.keyword ) 
    ).map( match => match.url );

  } else if( usefor === 'filter' ) {
    
    // get all keywords from all matched url
    matchedURLS.forEach( match => keywords = [...keywords, match.keyword] );
    
    // distinct results      
    keywords = [...new Set( keywords )];

    // apply filter
    result = rows.filter( row => 
      row.original.keywords.filter( keyword => 
        keywords.includes( keyword ) 
      ).length > 0 
    );
  }

  return result;
};

export const stringURLExpectedCustomFilter = ( rows, id, values ) => 
{
  const value = values.value;
  const option = values.option;
  const usefor = values.usefor;

  switch ( option ) {
    case 'notcontains':
      rows = rows.filter( row => 
        (
          (
            row.original?.expectedUrl?.automaticExpectedUrl !== undefined
            && row.original.expectedUrl.automaticExpectedUrl !== null
            && !cleanString( row.original.expectedUrl.automaticExpectedUrl ).includes( cleanString( value ) )
          ) || (
            row.original?.expectedUrl?.updatedExpectedUrl !== undefined
            && row.original.expectedUrl.updatedExpectedUrl !== null
            && !cleanString( row.original.expectedUrl.updatedExpectedUrl ).includes( cleanString( value ) )
          )
        )
        && row.original?.expectedUrl?.type !== undefined
      );
      break;
    case 'exactly':
      rows = rows.filter( row => 
        (
          (
            row.original?.expectedUrl?.automaticExpectedUrl !== undefined
            && row.original.expectedUrl.automaticExpectedUrl !== null
            && cleanString( row.original.expectedUrl.automaticExpectedUrl ) === cleanString( value )
          ) || (
            row.original?.expectedUrl?.updatedExpectedUrl !== undefined
            && row.original.expectedUrl.updatedExpectedUrl !== null
            && cleanString( row.original.expectedUrl.updatedExpectedUrl ) === cleanString( value )
          )
        )
        && row.original?.expectedUrl?.type !== undefined
      );
      break;
    case 'regex':
      try {
        rows = rows.filter( row => 
          (
            (
              row.original?.expectedUrl?.automaticExpectedUrl !== undefined
              && row.original.expectedUrl.automaticExpectedUrl !== null
              && cleanString( row.original.expectedUrl.automaticExpectedUrl ).match( new RegExp( cleanString( value ), 'gi' ) )
            ) || (
              row.original?.expectedUrl?.updatedExpectedUrl !== undefined
              && row.original.expectedUrl.updatedExpectedUrl !== null
              && cleanString( row.original.expectedUrl.updatedExpectedUrl ).match( new RegExp( cleanString( value ), 'gi' ) )
            )
          )
          && row.original?.expectedUrl?.type !== undefined
        );
      } catch (error) {
        rows = [];
      }
      break;
    // contains
    default:
      rows = rows.filter( row => 
        (
          (
            row.original?.expectedUrl?.automaticExpectedUrl !== undefined
            && row.original.expectedUrl.automaticExpectedUrl !== null
            && cleanString( row.original.expectedUrl.automaticExpectedUrl ).includes( cleanString( value ) )
          ) || (
            row.original?.expectedUrl?.updatedExpectedUrl !== undefined
            && row.original.expectedUrl.updatedExpectedUrl !== null
            && cleanString( row.original.expectedUrl.updatedExpectedUrl ).includes( cleanString( value ) )
          )
        )
        && row.original?.expectedUrl?.type !== undefined
      );
      break;
  }

  let results = rows; 
  if( usefor === 'autosuggest' )
    results = rows.map( row => getUpdateOrAutoValue( row?.original?.expectedUrl, 'expectedUrl' ) );

  return results;
};

export const stringLabelCustomFilter = ( rows, id, values ) => 
{
  const value = values.value;
  const option = values.option;
  const usefor = values.usefor;
  
  switch ( option ) {
    case 'notcontains':
      rows = rows.filter( row => 
        row.values[id]
        && !cleanString( row.values[id] ).includes( cleanString( value ) ));
      break;
    case 'exactly':
      rows = rows.filter( row => 
        row.values[id]
        && cleanString( row.values[id] ) === cleanString( value ) 
      );
      break;
    case 'regex':
      try {
        rows = rows.filter( row => 
          row.values[id]
          && cleanString( row.values[id] ).match( new RegExp( cleanString( value ), 'gi' ) ) 
        );
      } catch (error) {
        rows = [];
      }
      break;
    // contains
    default:
      rows = rows.filter( row => 
        row.values[id]
        && cleanString( row.values[id] ).includes( cleanString( value ) )
      );
      break;
  }

  let results = rows; 
  if( usefor === 'autosuggest' )
  {
    results = rows.map( row => 
      row.values[id]
    );
  }
  
  return results;
};

export const getFilterTypeWithFilterField = ( filterField ) => 
{
  let result = null;

  switch ( filterField ) {
    case 'action-text':
      result = 'string-url-expected';
      break;  
    case 'perfme-text':
      result = 'string-url-perfs';
      break;  
    default:
      result = 'string-label';
      break;
  }

  return result;
}

const selectActionCustomFilter = ( rows, id, values ) => 
{
  return rows.filter( row => 
    (
      row.values.action === undefined
      && values.includes( '' )
    )
    || 
    (
      row.values.action !== undefined
      &&
      (
        // case updated action
        (
          row.values.action.updatedActionID !== null
          && values.includes( row.values.action.updatedActionID.toString() )
        )
        || 
        // case not updated action
        (
          row.values.action.updatedActionID === null
          && values.includes( row.values.action.automaticActionID.toString() ) 
        )
      )
    )
  );
};

const selectIntentionCustomFilter = ( rows, id, values ) => 
{
  return rows.filter( row => 
    row.values.intention !== undefined
    && 
    (
      // case updated intention
      (
        row.values.intention.updatedIntention !== null
        && values.includes( row.values.intention.updatedIntention )
      )
      || 
      // case not updated intention
      (
        row.values.intention.updatedIntention === null
        && values.includes( row.values.intention.automaticIntention ) 
      )
    )
  );
};

const selectPageTypeCustomFilter = ( rows, id, values ) => 
{
  return rows.filter( row => 
    row.values.pageType !== undefined
    && 
    (
      // case updated page type
      (
        row.values.pageType.updatedPageTypeID !== null
        && values.includes( row.values.pageType.updatedPageTypeID.toString() )
      )
      || 
      // case not updated page type
      (
        row.values.pageType.updatedPageTypeID === null
        && values.includes( row.values.pageType.automaticPageTypeID.toString() ) 
      )
    )
  );
};

const selectSnippetCustomFilter = ( rows, id, values ) => 
{
  return rows.filter( row => 
  {
    const typesSnippets = row?.values[id]?.currentTypesSnippets !== undefined ?
      row.values[id].currentTypesSnippets !== null
      && row.values[id].currentTypesSnippets.map( typeSnippet => typeSnippet.split( '-' )[1] ) 
      : false;

    if( values && typesSnippets )
    {
      // check if types snippets of row is include in types snippets of filter                  
      let validSnippets = typesSnippets.filter( typeSnippet => values.includes( typeSnippet ) );
      
      return validSnippets.length > 0;                  
    }
  });
}

const selectAboveFoldCustomFilter = ( rows, id, values ) => 
{
  // replace value with boolean    
  values = values.map( value => value === 'Au dessus' ? 1 : 0 );

  return rows.filter( row => 
  {
    // get above fold snippets
    const aboveFoldSnippets = row?.values[id]?.currentTypesSnippets !== undefined ?
      row.values[id].currentTypesSnippets !== null
      && row.values[id].currentTypesSnippets.map( typeSnippet => typeSnippet.split( '-' )[0] ) 
      : false;

    if( values && aboveFoldSnippets )
    {
      // check if above fold snippets of row is include in values           
      let validSnippets = aboveFoldSnippets.filter( aboveFold => values.includes( parseInt( aboveFold ) ) );
      
      return validSnippets.length > 0;                  
    }
  });
}

const selectInOutCustomFilter = ( rows, id, values ) => 
{
  return rows.filter( row => 
  {
    if( row?.values[id]?.positions )
    {
      const ESRows = row.values[id].positions.filter( position => 
      {
        const positions = position.split( '|' );
        if( 
          values.includes( 'Entrées' )
          && positions.length === 2 
          && parseInt( positions[0] ) === 101 
          && parseInt( positions[1] ) !== 101 
        )
          return true;
        else if( 
          values.includes( 'Sorties' )
          && positions.length === 2 
          && parseInt( positions[0] ) !== 101 
          && parseInt( positions[1] ) === 101 
        )
          return true;
      })

      if( ESRows.length > 0 )
        return true;
      else
        return false;

    } else
      return false;
  })
}

const selectYoyoURLCustomFilter = ( rows, id, values ) => 
{
  return rows.filter( row => 
  {
    if( 
      values === true
      && row?.values[id]?.positions !== undefined
      && row?.values[id]?.positions instanceof Array 
      && row?.values[id]?.positions.length > 0
    ){
      // get current positions
      const positions = [...row.values[id].positions];

      // sort positions with current value
      const currentPositions = positions.map( positions => (
        {
          current: parseInt( positions.split( '|' )[0] ),
          compare: parseInt(  positions.split( '|' )[1] )
        }
      )).sort((a, b) => a.current - b.current);

      // sort positions with compare value
      const comparePositions = positions.map( positions => (
        {
          current: parseInt( positions.split( '|' )[0] ),
          compare: parseInt(  positions.split( '|' )[1] )
        }
      )).sort((a, b) => a.compare - b.compare);

      // get best current position after order by current position
      const bestPosSortCurrent = currentPositions.shift().current;

      // get best current position after order by compare position
      const bestPosSortCompare = comparePositions.shift().current;

      if( bestPosSortCurrent !== bestPosSortCompare )
        return true;
      else
        return false;

    } else
      return false;
  })
}

const selectURLPosURLAttCustomFilter = ( rows, id, values, params ) => 
{
  if( 
    values === true 
    && id instanceof Array
    && id.length === 1
  ){
    // get who
    const who = id[0].replace( 'perf', '' );

    return rows.filter( row => 
    {
      if( 
        row?.original?.expectedUrl !== undefined
      ){
        // get snippets for current accessor
        const snippets = params.snippetsDetails.filter( snippet => 
          snippet.keyword === row.values.label 
          && ( snippet.typeSnippet === 'SEO' || snippet.typeSnippet === 'Featured Snippet' ) 
          && snippet.who === who 
        );

        // sort snippets and get url
        const currentUrl = snippets.sort( ( a, b ) => a.position - b.position ).map( snippet => snippet.url ).shift();

        // get current expected url
        const currentExpectedUrl = row?.original?.expectedUrl?.updatedExpectedUrl !== undefined ?
          row.original.expectedUrl.updatedExpectedUrl
        : row?.original?.expectedUrl?.automaticExpectedUrl !== undefined ?
          row.original.expectedUrl.automaticExpectedUrl
        : null;
        
        // return true if current url is not expected url
        if( currentUrl !== currentExpectedUrl )
          return true;
        else
          return false;
  
      } else
        return false;
    });

  } else
    return rows;
}

const selectTypeExpectedURLCustomFilter =  ( rows, id, values ) => 
{
  return rows.filter( row => 
  {
    /** new url case */
    if( 
      values.includes( 'newurl' ) 
      && row?.original?.expectedUrl?.type !== undefined
      && row.original.expectedUrl.type === 'newurl'
    )
      return true;

    /** automatic assigned url case */
    if( 
      values.includes( 'automaticAssigned' ) 
      && row?.original?.expectedUrl?.automaticExpectedUrl !== undefined
      && row.original.expectedUrl.automaticExpectedUrl !== null
    )
      return true;

    /** non automatic assigned url case */
    if( 
      values.includes( 'nonAutomaticAssigned' ) 
      && 
      (
        row?.original?.expectedUrl?.automaticExpectedUrl === undefined
        || (
          row?.original?.expectedUrl?.automaticExpectedUrl !== undefined
          && row.original.expectedUrl.automaticExpectedUrl === null
        )
      )
    )
      return true;

    /** updated assigned url case */
    if( 
      values.includes( 'updatedAssigned' ) 
      && row?.original?.expectedUrl?.updatedExpectedUrl !== undefined
      && row.original.expectedUrl.updatedExpectedUrl !== null
    )
      return true;

    /** non assigned url case */
    if( 
      values.includes( 'nonAssigned' ) 
      && 
      (
        // non automatic 
        (
          row?.original?.expectedUrl?.automaticExpectedUrl === undefined
          || (
            row?.original?.expectedUrl?.automaticExpectedUrl !== undefined
            && row.original.expectedUrl.automaticExpectedUrl === null
          )
        )
        // non updated
        && 
        (
          row?.original?.expectedUrl?.updatedExpectedUrl === undefined
          || (
            row?.original?.expectedUrl?.updatedExpectedUrl !== undefined
            && row.original.expectedUrl.updatedExpectedUrl === null
          )
        )
      )
    )
      return true;

    

    // if no match before
    return false;
  });
}

const rangePresenceSEOCustomFilter = ( rows, id , values ) => 
{
  return rows.filter( row => 
  {
    // case :: non rank
    if( values[0] === 101 )
    {
      return row.values[id] === undefined || row.values[id].positions === null;
    
    // case :: rank
    } else {

      if( row.values[id] && row.values[id].positions )
      {
        // get second value after | of best position
        const position = parseInt( row.values[id].positions[0].split( '|' )[1] );

        // check if position is include in range values   
        return values[0] <= position && position <= values[1];
      }
    }                
  });
};

const rangeAvgPositionCustomFilter = ( rows, id , values ) => 
{
  return rows.filter( row => 
  {
    // get average position value
    const avgPosition = row?.values[id]?.avgPosition ?
      row.values[id].avgPosition 
      : null;

    // split avgPosition value to get current avgPosition value
    let currentAvgPosition = null;
    if( avgPosition !== null && typeof avgPosition === 'string' )
      currentAvgPosition = avgPosition.split( '|' )[1];
    
    // return true or false if include in range values
    if( 
      currentAvgPosition !== null
      && values[0] <= currentAvgPosition
      && currentAvgPosition <= values[1]
    )
      return true;
    else
      return false;                
  });
};

const rangeNbKeywordsCustomFilter = ( rows, id, values ) => 
{
  // apply filter
  return rows.filter( row => 
  {
    // get keywords
    let keywords = [];
    if( row?.original?.keywords !== undefined )
      keywords = row.original.keywords;

      // apply filter
    if(
      values[0] <= keywords.length
      && keywords.length <= values[1]
    )
      return true;
    else
      return false;
  });
}

const rangeCustomFilter = ( rows, id, values ) => 
{
  // apply filter
  return rows.filter( row => 
    row.values[id] 
    && values[0] <= row.values[id].value 
    && row.values[id].value <= values[1]
  )
}

const identifierGscDateRangeCustomFilter = ( rows, id, customValues ) => 
{
  // apply filter
  return rows.filter( row => 
  {
    // get current identifier date
    let identifierDate = null;
    if( row?.values[id]?.value !== undefined )
      identifierDate = row.values[id].value;

    // get start date
    let startDate = null;
    if( customValues[0] )
      startDate = parseInt( customValues[0] );

    // get end date
    let endDate = null;
    if( customValues[1] )
      endDate = parseInt( customValues[1] );

    if(
      (
        identifierDate !== null
        && startDate !== null
        && endDate !== null
        && identifierDate >= startDate
        && identifierDate <= endDate
      ) || (
        identifierDate !== null
        && startDate === null
        && endDate !== null
        && identifierDate <= endDate
      ) || (
        identifierDate !== null
        && startDate !== null
        && endDate === null
        && identifierDate >= startDate
      )
    )
      return true;
    else
      return false;
  });
}