/** Components **/
import DefaultRangeFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/DefaultRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Dashboards/Barometer/TableHeaders/TitleWithPicto';
import GSCCTRTotalCell from './../../../components/Dashboards/Barometer/TableTotalCells/GSCCTRTotalCell';
import TableCellGSC from './../../../components/Dashboards/Barometer/TableCells/GSC';

export const GscCtrColumn = ( widthColumns ) => 
{
  return {
    accessor: "gsc.ctr",
    width: widthColumns["ctr"],
    title: "ctr",
    className: "col-gsc",
    Filter: DefaultRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'CTR',
    sortDescFirst: true,
    sortType: 'valueSort',
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Total: props => 
      <GSCCTRTotalCell id={props.column.id} />,
    Cell: props => 
      <TableCellGSC 
        id={props.cell.row.id}
        values={props.value} 
        accessor={props.column.id} 
        label={props.cell.row.values.label}
        keywords={props.cell.row.original.keywords}
      />
  }
}