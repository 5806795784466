/** Dependencies **/
import React from 'react';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Helpers **/
import { getPicto } from './../../../../helpers/pictos';
import { 
  goTo,
  getCategories 
} from './../../../../helpers/barometer/barometer';

function Thematics( props ) 
{
  /** Get props **/
  let thematic = props.value;
  let category1 = props.category1;
  let category2 = props.category2;
  let depth = props.depth;
  let row = props.row;
  
  /** Get state from redux store **/
  const filtersDatas = useSelector( state => state.filtersDatas.value );
  const levelCategory = useSelector( state => state.levelCategory.value );
  
  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /** Set previous label **/
  let previousLabel = null;
  if( levelCategory === 2 && depth === 0 )
    previousLabel = props['category' + ( levelCategory - 1 )];

  /** Set previous links **/
  let previousLinks = [];
  if( 
    levelCategory === 2 
    && depth === 0 
  ){
    if( filtersDatas.categories[category1] )
      previousLinks = getCategories( filtersDatas.categories, category1 );
  }

  /** Set next links **/
  let currentLinks = [];
  if( 
    levelCategory === 1 
    && depth === 0 
  ){
    if( filtersDatas.categories[category1] )
      currentLinks = getCategories( filtersDatas.categories, category1 );

  } else if (
    ( levelCategory === 1 && depth === 1 )
    || 
    ( levelCategory === 2 && depth === 0 )
  ){
    if( filtersDatas.categories[category1] && filtersDatas.categories[category1].cat2[category2] )
      currentLinks = getCategories( filtersDatas.categories, category1, category2 );

  } else if (
    levelCategory === 2 && depth === 1
  )
    currentLinks = getCategories( filtersDatas.categories, category1, category2, thematic );
  
  return (
    <div className="thematic-container">
      { previousLabel !== null ?
        <button 
          className='previous-link' 
          title={previousLabel}
          onClick={ () => goTo( 
            previousLinks, 
            levelCategory - 1, 
            filtersDatas,
            null, 
            dispatch, 
            navigate 
          ) }
        >
          {getPicto( 'CornerLeftUp', {size: '1rem'} )}
          {previousLabel}
        </button>
      : false }
      <button 
        className={( levelCategory === 1 && depth === 1 ) || row.subRows.length > 0 ? 'thematic clickable' : 'thematic'} 
        title={thematic} 
        onClick={ () => 
          levelCategory === 1 && depth === 1 ? 
            goTo( 
              currentLinks, 
              levelCategory + 1, 
              filtersDatas,
              null, 
              dispatch, 
              navigate 
            ) 
          : row.subRows.length > 0 ? 
            row.toggleRowExpanded() : false 
        }
      >
        { levelCategory === 1 && depth === 1 ? getPicto( 'CornerLeftUp', {size: '1rem', transform: 'scale(-1,1) rotate(-90)'} ) : false}
        {thematic}
      </button>
      <button 
        className='keywords-link'
        onClick={ () => goTo( 
          currentLinks, 
          levelCategory, 
          filtersDatas,
          process.env.PUBLIC_URL + '/keywords', 
          dispatch, 
          navigate 
        ) }
      >
        {getPicto( 'List', {size: '1rem'} )}
        voir les mots clés
      </button>
    </div>
  );
}

export default Thematics;