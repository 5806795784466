/** Dependencies **/
import React from 'react';

/** Redux **/
import { updateDatas } from './../../reducers/datas';
import { updateLoadersTriggers } from './../../reducers/loadersTriggers';

/** Helpers **/
import { 
  orderObjectKeysWithPattern,
  exportXLSX,
  togglerClassName
} from './../../helpers/functions';

import { getPicto } from './../../helpers/pictos';

/** JSON */
import globalConfig from './../../assets/json/config.json';

/** Components **/
import StringFilterRemove from './../../components/Dashboards/Barometer/FiltersRemove/StringFilterRemove';
import SortFilterRemove from './../../components/Dashboards/Barometer/FiltersRemove/SortFilterRemove';
import PictoFilterRemove from './../../components/Dashboards/Barometer/FiltersRemove/PictoFilterRemove';
import FiltersRemoveTooltip from './../../components/Dashboards/Barometer/FiltersRemove/FiltersRemoveTooltip';

/**
 * dispatch updated actions states for keyword
 * @param {Array} result [
  {
    "keyword": "station de ski morvan",
    "action_id": 0
  }
]
 * @param {*} originalData [
  {
    "keyword": "course d orientation beaujolais",
    "volume": {
        "value": 80,
        "percent": 8
    },
    "category1": "Activités terrestres",
    "category2": "Course d'orientation",
    "category3": "-",
    "action_id": null,
    "variation": {
        "value": -40,
        "percent": -33
    },...
  ...
]
 */
export const updateActionState = ( result, originalData ) => 
{
  // deep copy of originalData
  let datasUpdated = JSON.parse( JSON.stringify( originalData ) );

  if(
    result.entries
    && result.entries instanceof Array 
    && result.entries.length > 0 
    && ( typeof result.action_id === 'number' || result.action_id === null )
  )
    result.entries.forEach( entry => {
      let index = datasUpdated.findIndex( result => result.label === entry );
      if( index !== -1 )
      {
        if( !datasUpdated[index].action )
          datasUpdated[index].action = {
            automaticActionID: null,
            updatedActionID: result.action_id,
            lastUpdate: result.lastUpdate,
            usersIDs: [result.userID]
          }
        else {  
          datasUpdated[index].action.updatedActionID = result.action_id;
          datasUpdated[index].action.lastUpdate = result.lastUpdate;

          if( !datasUpdated[index].action.usersIDs.includes( result.userID ) )
            datasUpdated[index].action.usersIDs.push( result.userID );
        }
      }
    });

  return datasUpdated;
}

/**
 * dispatch updated intention states for keyword
 * @param {Array} result [
  {
    "keyword": "station de ski morvan",
    "intention_id": 0
  }
]
 * @param {*} originalData [
  {
    "keyword": "course d orientation beaujolais",
    "volume": {
        "value": 80,
        "percent": 8
    },
    "category1": "Activités terrestres",
    "category2": "Course d'orientation",
    "category3": "-",
    "action_id": null,
    "variation": {
        "value": -40,
        "percent": -33
    },
    "intention": {
      
    }
  ...
]
 */
export const updateIntentionState = ( result, originalData ) => 
{
  // deep copy of originalData
  let datasUpdated = JSON.parse( JSON.stringify( originalData ) );
  
  if(
    result.keywords
    && result.keywords instanceof Array 
    && result.keywords.length > 0 
    && (
      typeof result.intention === 'string' 
      || result.intention === null
    )
  )
    result.keywords.forEach( keyword => 
    {
      let index = datasUpdated.findIndex( result => result.keywords.includes( keyword ) );
      if( index !== -1 )
      {
        if( !datasUpdated[index].intention )
          datasUpdated[index].intention = {
            automaticIntention: null,
            updatedIntention: result.intention,
            lastUpdate: result.lastUpdate,
            usersIDs: [result.userID]
          }
        else {
          datasUpdated[index].intention.updatedIntention = result.intention;          
          datasUpdated[index].intention.lastUpdate = result.lastUpdate;

          if( !datasUpdated[index].intention.usersIDs.includes( result.userID ) )
            datasUpdated[index].intention.usersIDs.push( result.userID );
        }
      }
    });

  return datasUpdated;
}

/**
 * dispatch updated page type states for url
 * @param {Array} result [
  {
    "url": "station de ski morvan",
    "pagetype_id": 0
  }
]
 * @param {*} originalData [
  {
    "keyword": "course d orientation beaujolais",
    "volume": {
        "value": 80,
        "percent": 8
    },
    "category1": "Activités terrestres",
    "category2": "Course d'orientation",
    "category3": "-",
    "action_id": null,
    "variation": {
        "value": -40,
        "percent": -33
    },
    "pagetype": {
      
    }
  ...
]
 */
export const updatePageTypeState = ( result, originalData ) => 
{
  // deep copy of originalData
  let datasUpdated = JSON.parse( JSON.stringify( originalData ) );
 
  if(
    result.urls
    && result.urls instanceof Array 
    && result.urls.length > 0 
    && ( typeof result.pagetype_id === 'number' || result.pagetype_id === null )
  )
    result.urls.forEach( url => 
    {
      let index = datasUpdated.findIndex( result => result.label === url );
      if( index !== -1 )
      {
        if( !datasUpdated[index].pageType )
          datasUpdated[index].pageType = {
            automaticPageTypeID: null,
            updatedPageTypeID: result.pagetype_id,
            lastUpdate: result.lastUpdate,
            usersIDs: [result.userID]
          }
        else {
          datasUpdated[index].pageType.updatedPageTypeID = result.pagetype_id;          
          datasUpdated[index].pageType.lastUpdate = result.lastUpdate;

          if( !datasUpdated[index].pageType.usersIDs.includes( result.userID ) )
            datasUpdated[index].pageType.usersIDs.push( result.userID );
        }
      }
    });

  return datasUpdated;
}

export const updateCommentState = ( result, originalData ) => 
{
  // deep copy of originalData
  let datasUpdated = JSON.parse( JSON.stringify( originalData ) );
  
  if( 
    result.entries
    && result.entries instanceof Array
    && result.entries.length > 0
  )
    result.entries.forEach( label => {
      let index = datasUpdated.findIndex( result => result.label === label );
      if( index !== -1 )
      {
        if( !result.nbComments )
          datasUpdated[index].comments = true;
        else if ( result.nbComments && result.nbComments.length === 1 )
          datasUpdated[index].comments = false;
      }        
    });
  
  return datasUpdated;
}

export const updateExpectedURLState = ( result, originalData ) => 
{
  // deep copy of originalData
  let datasUpdated = JSON.parse( JSON.stringify( originalData ) );

  if( 
    result.keywords
    && result.keywords instanceof Array
    && result.keywords.length > 0
    && result.expectedUrl
    && result.expectedUrl.trim() !== '' 
  )
    result.keywords.forEach( keyword => 
    {
      let index = datasUpdated.findIndex( result => result.keywords.includes( keyword ) );
      if( index !== -1 )
      {
        if( !datasUpdated[index].expectedUrl )
          datasUpdated[index].expectedUrl = {
            automaticExpectedUrl: null,
            updatedExpectedUrl: result.expectedUrl,
            type: result.typeExpectedUrl
          }
        else {
          datasUpdated[index].expectedUrl.updatedExpectedUrl = result.expectedUrl;   
          datasUpdated[index].expectedUrl.type = result.typeExpectedUrl;   
        }
      }
    });
  
  return datasUpdated;
}

export const updateCategoriesState = ( result, originalData ) => 
{
  // deep copy of originalData
  let datasUpdated = JSON.parse( JSON.stringify( originalData ) );

  if( 
    result.keywords
    && result.keywords instanceof Array
    && result.keywords.length > 0    
  )
    result.keywords.forEach( keyword => 
    {
      let index = datasUpdated.findIndex( result => result.keywords.includes( keyword ) );
      if( index !== -1 )
      {
        if(
          result.categories
          && result.categories instanceof Array
          && result.categories.length > 0
        ){
          datasUpdated[index].category1 = result.categories[0];
          datasUpdated[index].category2 = result.categories[1];
          datasUpdated[index].category3 = result.categories[2];
        } else {
          delete datasUpdated[index].category1;
          delete datasUpdated[index].category2;
          delete datasUpdated[index].category3;
        }
      }
    });
    
    // update global result
    return datasUpdated;
}

/**
 * Export Table datas to XLSX File
 * @param {String} fileName 
 * @param {Array} tableDatas 
 * @param {Array} snippetsDetailsDatas 
 */
export const exportDataToXLSX = ( fileName, tableDatas, snippetsDetailsDatas, context ) => 
{
  // display global loader
  togglerClassName( document.getElementById( 'loader-global' ), 'hide' );

  // get datas transformed after timeout to let time display loader
  var timeout = setTimeout( 
    () => 
    {
      // transform datas
      var datas = transformDatasToExport( tableDatas, snippetsDetailsDatas, context );

      // export XLSX
      exportXLSX( fileName, datas );

      // hide global loader
      togglerClassName( document.getElementById( 'loader-global' ), 'hide' );

      // clear timeout
      clearTimeout( timeout );
    }, 
    100
  );
}

/**
 * return an array with datas formated for export table to CSV
 * @param {array} datas from redux datas.globalResult of datas.filterResult
 * @returns 
 */
 export const transformDatasToExport = ( datas, snippetsDetails, context ) => 
 {
  let results = [];

  const snippetsAccessor = globalConfig.barometer.snippet_grps;
  const grpDomain = globalConfig.barometer.domains_grps; /** TODO => grpDomain must be getting with config from DB */
  const actions = globalConfig.barometer.action[context];

  // set pattern for values to get
  const rowPattern = {
    "Label": 'label',
    "Catégorie 1": 'category1',
    "Catégorie 2": 'category2',
    "Catégorie 3": 'category3',
    "Intention (machine)": 'intention.automaticIntention',
    "Intention (humaine)": 'intention.updatedIntention',
    "Volume": 'volume.value',
    "Volume (Rapport au max.)": 'volume.percent',
    "Volume (Variation)": 'volume.variation',
    "Snippets (Types)": 'typesSnippets',
    "Snippets (Aire occupée)": 'value',
    "Snippets (Variation)": 'variation',
    "Performance (Aire occupée)": 'value',
    "Performance (Variation)": 'variation',
    "Performance (Position)": 'position',
    "GSC - Impressions": 'impressions.value',
    "GSC - Impressions (Rapport au max.)": 'impressions.percent',
    "GSC - Impressions (Variation)": 'impressions.variation',
    "GSC - Clicks": 'clicks.value',
    "GSC - Clicks (Rapport au max.)": 'clicks.percent',
    "GSC - Clicks (Variation)": 'clicks.variation',
    "GSC - CTR": 'ctr.value',
    "GSC - CTR (Rapport au max.)": 'ctr.percent',
    "GSC - CTR (Variation)": 'ctr.variation',
    "GSC - Position": 'position.value',
    "GSC - Position (Variation)": 'position.variation',
    "K. Difficulty": 'kdifficulty.value',
    "Actions": 'action.actionID'
  }

  if( datas.length > 0 )
  {
    results = datas.forEach( elem => 
    {
      let row = {};

      if( elem.label )
      {
        // get data for each key of pattern
        Object.keys( rowPattern ).forEach( colName => 
        {
          const key = rowPattern[colName];
          
          // Snippets case
          if( colName.startsWith( 'Snippets' ) )
          { 
            Object.keys( snippetsAccessor ).forEach( accessor => 
            {
              const rowIndex = accessor + '-' + colName;

              if( 
                elem[snippetsAccessor[accessor].accessor] 
                && elem[snippetsAccessor[accessor].accessor][key]
              )
                if( elem[snippetsAccessor[accessor].accessor][key] instanceof Array )
                  row[rowIndex] = elem[snippetsAccessor[accessor].accessor][key].join( ',' );
                else
                  row[rowIndex] = elem[snippetsAccessor[accessor].accessor][key] + ( key === 'value' ? '%' : '' );
              else
                row[rowIndex] = '-';
            });

          // Performance case
          } else if ( colName.startsWith( 'Performance' ) ) {

            grpDomain.forEach( grp => 
            {
              const rowIndex = grp.value + '-' + colName;
              const index = 'perf' + grp.value;

              if( key === 'position' && grp.value === 'me' )
              {
                var positions = snippetsDetails.filter( detail => 
                  detail.who === grp.value
                  && detail.label === elem.label
                ).map( elem => elem.position + ' - ' + elem.url 
                ).join( '\n' );

                if( positions.length > 0 )
                  row[rowIndex] = positions;
                else
                  row[rowIndex] = '-';

              } else if( key !== 'position' ) {

                if( 
                  elem[index] 
                  && elem[index][key]
                )
                  if( elem[index][key] instanceof Array )
                    row[rowIndex] = elem[index][key].join( ',' );
                  else
                    row[rowIndex] = elem[index][key] + ( key === 'value' ? '%' : '' );
                else
                  row[rowIndex] = '-';
              }
            });

          // Generic case
          } else {

            const splitKey = key.split( '.' );

            // case single value
            if( 
              splitKey.length === 1 
              && elem[splitKey[0]] !== undefined
            )
              row[colName] = elem[splitKey[0]];

            // case object value
            else if( 
              splitKey.length === 2 
              && elem[splitKey[0]] 
              && elem[splitKey[0]][splitKey[1]] !== null
            ){
              if( splitKey[0] === 'action' )
                row[colName] = actions.filter( action => parseInt( action.value ) === parseInt( elem[splitKey[0]][splitKey[1]] ) )[0].label;
              else
                row[colName] = elem[splitKey[0]][splitKey[1]] + ( 
                  splitKey[1] === 'percent' 
                  || ( 
                    splitKey[0] === 'ctr' 
                    && splitKey[1] === 'value' 
                  ) ? '%' : '' 
                );

            // case empty
            } else
              row[colName] = '-';
          }
        });
      };

      return row;
    });
  }
  
  // sort with pattern
  results = results.map( result => 
    orderObjectKeysWithPattern( result, [
      "Label",
      "Catégorie 1",
      "Catégorie 2",
      "Catégorie 3",
      "Intention (machine)",
      "Intention (humaine)",
      "Volume",
      "Volume (Rapport au max.)",
      "Volume (Variation)",
      "SEO-Snippets (Types)",
      "SEO-Snippets (Aire occupée)",
      "SEO-Snippets (Variation)",
      "Google-Snippets (Types)",
      "Google-Snippets (Aire occupée)",
      "Google-Snippets (Variation)",
      "Ads-Snippets (Types)",
      "Ads-Snippets (Aire occupée)",
      "Ads-Snippets (Variation)",
      "Snippets-Snippets (Types)",
      "Snippets-Snippets (Aire occupée)",
      "Snippets-Snippets (Variation)",
      "me-Performance (Aire occupée)",
      "me-Performance (Variation)",
      "me-Performance (Position)",
      "gp1-Performance (Aire occupée)",
      "gp1-Performance (Variation)",
      "gp2-Performance (Aire occupée)",
      "gp2-Performance (Variation)",
      "gp3-Performance (Aire occupée)",
      "gp3-Performance (Variation)",
      "GSC - Impressions",
      "GSC - Impressions (Rapport au max.)",
      "GSC - Impressions (Variation)",
      "GSC - Clicks",
      "GSC - Clicks (Rapport au max.)",
      "GSC - Clicks (Variation)",
      "GSC - CTR",
      "GSC - CTR (Rapport au max.)",
      "GSC - CTR (Variation)",
      "GSC - Position",
      "GSC - Position (Variation)",
      "K. Difficulty",
      "Actions"
    ])  
  );

  return results;
}

/**
* 
* @param {Array of object column} columns from data-table
* @param {Object} selectedHeaderFilters {
    "volume": {
        "value": [
            0,
            9
        ],
        "label": "moins de 10",
        "type": "text"
    }
}
* @returns Array of DOMElement for filter reminder and more
*/
export const buildRemindersFilter = ( columns, selectedHeaderFilters ) => 
{
  // Define result value
  let result = [];

  /** Filter on column which are filtered or sorted **/
  const columnsFiltered = columns.filter( header => header.filterValue || header.isSorted );

  columnsFiltered.forEach( (column, index) => 
  {
    // Add filters
    if( selectedHeaderFilters[column.id] && column.filterValue !== undefined )
    {
      // Add selected header filter values in array to manage multiple filter in column 
      let columnFilterValues = selectedHeaderFilters[column.id];
      if( !(selectedHeaderFilters[column.id] instanceof Array) )
        columnFilterValues = [selectedHeaderFilters[column.id]];

      columnFilterValues.forEach( (filterValue, index) => 
      {
        if( filterValue.type === 'text' )
          result = [...result, <StringFilterRemove 
            id={column.id} 
            key={'filter-' + column.id + '-' + index}
            label={filterValue.label} 
            value={filterValue.value} 
            option={filterValue.option}
            filterType={column.filter}
            title={column.title}
          />]
        else 
          result = [...result, <PictoFilterRemove 
            id={column.id} 
            key={'filter-' + column.id + '-' + index}
            label={filterValue.label} 
            value={filterValue.value} 
            option={filterValue.option} 
            filterType={column.filter}
            title={column.title}
          />]
      })
    }

    // Add sort
    if ( column.isSorted === true )
      result = [...result, <SortFilterRemove 
        id={column.id} 
        key={'sort-' + index}
        label={column.title}
        isSortedDesc={column.isSortedDesc}
        clearSortBy={column.clearSortBy}
      />]
  })

  // Add More if result > 3
  if( result.length > 3 )
    result = [...result.slice( 0, 3 ), <FiltersRemoveTooltip filters={result} />]

  return result;
}

/**
 * Fire click on sort filter toggler when click on header
 * @param {Event} e Click event on header
 */
export const fireSortClickOnHeaderTitle = ( e ) => 
{
  // get current element
  const currentElement = e.currentTarget;

  // get first button element which is sort and filter toggler popin
  const sortFilterToggler = currentElement.querySelector( 'button' );

  if( sortFilterToggler )
    sortFilterToggler.click();
}

/**
 * Return all expected urls which are recorded
 * @param {Array} datas 
 * @returns {Array}
 */
export const getAllExpectedURLS = ( datas ) => 
{
  let expectedURLS = [];

  datas.forEach( data => 
    data?.expectedUrl !== undefined ?
      data?.expectedUrl?.updatedExpectedUrl !== null ?
        expectedURLS = [...expectedURLS, data.expectedUrl.updatedExpectedUrl ]
        : data?.expectedUrl?.automaticExpectedUrl !== null ?
        expectedURLS = [...expectedURLS, data.expectedUrl.automaticExpectedUrl ]
      : false
    : false
  );

  return expectedURLS;
}

/**
   * Set sort value and order
   * @param {String} value 
   */
export const sortColumn = ( value, currentSort, setSortCallBack ) => 
{
  let order = 'desc';
  if( currentSort.order === 'desc' && currentSort.value === value )
    order = 'asc';

  setSortCallBack( { value: value, order: order } );
}

/**
 * Return picto sort if value is current sort value
 * @param {String} value 
 * @returns 
 */
export const getPictoSort = ( value, currentSort ) => 
{
  let picto = '';

  let options = { size: '1.1rem' };
  if( currentSort.order === 'asc' )
    options.transform = 'rotate(180)';
  
  if ( value === currentSort.value )
    picto = getPicto( 'Sort', options );

  return picto;
}