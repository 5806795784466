/** Components **/
import TableHeaderKeywords from './../../components/Dashboards/Barometer/TableHeaders/Keywords';
import TableHeaderMyPerfSelect from './../../components/Dashboards/Barometer/TableHeaders/MyPerfSelect';
import TableHeaderSERP from './../../components/Dashboards/Barometer/TableHeaders/SERP';

/** JSON */
import globalConfig from './../../assets/json/config.json';

/** Customs columns */
import { LabelColumn } from './customColumns/labelColumn'
import { IntentionColumn } from './customColumns/intentionColumn';
import { PageTypeColumn } from './customColumns/pageTypeColumn';
import { VolumeColumn } from './customColumns/volumeColumn';
import { SerpColumn } from './customColumns/serpColumn';
import { SerpViewerColumn } from './customColumns/serpViewerColumn';
import { PerfsColumn } from './customColumns/perfsColumn';
import { GscIdentifierDateColumn } from './customColumns/gscIdentifierDateColumn';
import { GscImpressionsColumn } from './customColumns/gscImpressionsColumn';
import { GscClicksColumn } from './customColumns/gscClickColumn';
import { GscCtrColumn } from './customColumns/gscCtrColumn';
import { GscPositionColumn } from './customColumns/gscPositionColumn';
import { KDifficultyColumn } from './customColumns/kDifficultyColumn';
import { DecisionsColumn } from './customColumns/decisionsColumn';
import { CategoriesEditorColumn } from './customColumns/categoriesEditorColumn';

/**
 * 
 * @returns array with configuration of header of table in barometer
 */
 export const getCustomColumns = ( barometerContext, instanceConfig ) => 
 {
  // get groups for header
  const enabledColumns = globalConfig.barometer.enabled_columns;
  const widthColumns = globalConfig.barometer.width_columns[barometerContext];

  // init object with custom Label Component
  const customColumnComponents = {
    LabelColumn: LabelColumn( barometerContext, widthColumns ),
    IntentionColumn: IntentionColumn( widthColumns ),
    PageTypeColumn: PageTypeColumn( widthColumns ),
    VolumeColumn: VolumeColumn( barometerContext, widthColumns ),
    SerpColumn: SerpColumn( widthColumns ),
    SerpViewerColumn: SerpViewerColumn( widthColumns ),
    PerfsColumn: PerfsColumn( barometerContext, widthColumns, instanceConfig ),
    GscIdentifierDateColumn: GscIdentifierDateColumn( widthColumns ),
    GscImpressionsColumn: GscImpressionsColumn( barometerContext, widthColumns ),
    GscClickColumn: GscClicksColumn( widthColumns ),
    GscCtrColumn: GscCtrColumn( widthColumns ),
    GscPositionColumn: GscPositionColumn( widthColumns ),
    KDifficultyColumn: KDifficultyColumn( widthColumns ),
    DecisionsColumn: DecisionsColumn( widthColumns ),
    CategoriesEditorColumn: CategoriesEditorColumn( widthColumns )
  }
  
  // init result array
  let result = [];

  /**************************/
  /** Add Le marché column **/
  /**************************/
  if( 
    enabledColumns[barometerContext]?.market !== undefined 
    && enabledColumns[barometerContext].market.length > 0
  ){
    result.push(
      {
        id: 1,
        disableSortBy: true,
        Header: props => (
          barometerContext !== 'urls' ?
            <TableHeaderKeywords 
              {...props.getToggleAllRowsSelectedProps()} 
              id={'massaction'} 
              title={'mot clé'} 
            />
            : null
        ),
        columns: enabledColumns[barometerContext].market.map( column => customColumnComponents[column] )
      }
    );  
  }  

  /*********************/
  /** Add SERP column **/
  /*********************/
  if( 
    enabledColumns[barometerContext]?.serp !== undefined 
    && enabledColumns[barometerContext].serp.length > 0
  ){
    result.push(
      {
        id: 2,
        disableSortBy: true,
        Header: <TableHeaderSERP />,
        columns: enabledColumns[barometerContext].serp.map( column => customColumnComponents[column] ).flat()
      }
    );  
  }

  /******************************************/
  /********  Add Performance column *********/
  /******************************************/
  if( 
    enabledColumns[barometerContext]?.perfs !== undefined 
    && enabledColumns[barometerContext].perfs.length > 0
  ){
    result.push(
      {
        id: 3,
        className: 'column-header-myperf',
        disableSortBy: true,
        Header: <TableHeaderMyPerfSelect />,
        Total: '',
        columns: enabledColumns[barometerContext].perfs.map( column => customColumnComponents[column] ).flat()
      }
    );  
  }  

  /**********************************/
  /********  Add GSC column *********/
  /**********************************/
  if( 
    enabledColumns[barometerContext]?.gsc !== undefined 
    && enabledColumns[barometerContext].gsc.length > 0
  ){
    result.push(
      {
        id: 4,
        disableSortBy: true,
        Header: <h4>ma google search console</h4>,
        columns: enabledColumns[barometerContext].gsc.map( column => customColumnComponents[column] )
      }
    );  
  }

  /***********************************************/
  /************* Add DECISION column *************/
  /***********************************************/
  if( 
    enabledColumns[barometerContext]?.decisions !== undefined 
    && enabledColumns[barometerContext].decisions.length > 0
  ){
    result.push(
      {
        id: 5,
        disableSortBy: true,
        width: widthColumns[4],
        Header: <h4>état</h4>,
        columns: enabledColumns[barometerContext].decisions.map( column => customColumnComponents[column] )
      }
    );  
  }

  /************************************************/
  /********  Add Categories Editor column *********/
  /************************************************/
  if( 
    enabledColumns[barometerContext]?.categories !== undefined 
    && enabledColumns[barometerContext].categories.length > 0
  ){
    result.push(
      {
        id: 6,
        disableSortBy: true,
        Header: <h4>catégories</h4>,
        columns: enabledColumns[barometerContext].categories.map( column => customColumnComponents[column] )
      }
    );  
  }

  return result;
 }