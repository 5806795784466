/** Dependencies **/
import React, { useState } from "react";
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';

/** Components **/
import DefaultSortToolTip from './../SortToolTips/DefaultSortToolTip';
import RangeFilter from './Filters/RangeFilter';
import AutoSuggestFilter from './Filters/AutoSuggestFilter';
import Loader from './../../../Loader';

/** Helpers */
import { stringURLGSCCustomFilter } from './../../../../helpers/barometer/customFilters';
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';

function GSCFilterToolTip ( props )
{
  /** Get props **/
  const id = props.column.id;
  const title = props.column.filterTitle;

  /** Init state **/
  const [GSCDetails, setGSCDetails] = useState( [] );

  /** Get state from redux store **/
  const barometerContext = useSelector( state => state.barometerContext.value );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const filterResult = useSelector( state => state.datas.value.filterResult );
  const selectedInstance = useSelector( state => state.selectedInstance.value ); 

  /** Instance dispatch object **/
  const dispatch = useDispatch();
  
  /** Set preselect filters and key to access to data **/
  let preselectFilters = [];

  switch ( id ) {
    case 'impressions':    
      preselectFilters = [
        {value: '0|100', label: '0 ▶︎ 100'},
        {value: '101|1000', label: '100 ▶︎ 1k'},
        {value: '1001|10000', label: '1k ▶︎ 10k'},
        {value: '10001', label: 'supérieur à +10k'}
      ];
      break;
    case 'clicks':    
      preselectFilters = [
        {value: '0|10', label: '0 ▶︎ 10'},
        {value: '11|50', label: '10 ▶︎ 50'},
        {value: '51|100', label: '50 ▶︎ 100'},
        {value: '101|500', label: '100 ▶︎ 500'},
        {value: '501|1000', label: '500 ▶︎ 1000'},
        {value: '1001', label: 'supérieur à +1000'}
      ];
      break;
    case 'ctr':
      preselectFilters = [
        {value: '1', label: '>= 1%' },
        {value: '5', label: '>= 5%' },
        {value: '10', label: '>= 10%' },
        {value: '25', label: '>= 25%' },
        {value: '33', label: '>= 33%' },
        {value: '50', label: '>= 50%' }  
      ];
      break;
    case 'position':
      preselectFilters = [
        {value: '1|3', label: '<= 3' },
        {value: '1|5', label: '<= 5' },
        {value: '1|10', label: '<= 10' },
        {value: '1|20', label: '<= 20' },
        {value: '1|30', label: '<= 30' }
      ];
      break;
    default:
      break;
  }

  /**
	 * Load content of popin
	 */
	const loadContent = () => 
	{
    // GSC current details
    callWebservice( 
      id,        
      id + '-currentGSCDetails',
      'gsc-max-clicks-by-urls',
      dispatch,
      selectedInstance,
      {
        period: {
          startdate: selectedFilters.currentPeriod.startdate,
          enddate: selectedFilters.currentPeriod.enddate
        },
        where: {
          keywords: filterResult.map( row => row.label ),
          devices: [selectedFilters.devLoc.deviceValue],
          locations: [selectedFilters.devLoc.countryValue]
        }
      },
      {
        function: 'setGSCDetails',
        params: []
      }
    );
	}

  return (
    <ReactTooltip 
      id={'filter-' + id}
      className="tooltip red"
      effect='solid'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      afterShow={loadContent}
      globalEventOff='click'
      arrowColor="#e43e21"
      offset={id === 'position' || id === 'ctr' ? {left: 300} : {}}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset
        let offsetX = 0;
        if( 
          barometerContext === 'keywords' 
          && id === 'position' 
        )
          offsetX = -77;        
        
        return {
          left: currentTarget.offsetLeft - (width / 2) + offsetX,
          top: 17
        };
      }}
    >
      <div className="filter-container">
        <DefaultSortToolTip column={props.column} id={id} />  
        <div className="filters">
          <div className="title"><h4>Filtrer</h4></div>
          <div className="filter range">              
            <RangeFilter 
              {...props} 
              title={title}
              fieldName={ 'range-' + id } 
              typeFilter={ 'range' }
              preselectFilters={preselectFilters} 
              unit={ id === 'ctr' ? '%' : '' }
            /> 
          </div>     
        </div>    
        <div className="url">
          <div className="title">
            <h4>Chercher un domaine ou une URL</h4>
          </div>
          <div className="filter text">        
            <Loader 
              loaderID={id} 
              loaderStyle={{width:'15', stroke:'#e43e21'}} 
              callBackFcts={{
                'setGSCDetails': setGSCDetails
              }}
              globalCallBack={undefined} 
            />
            <AutoSuggestFilter 
              {...props} 
              filterFct={stringURLGSCCustomFilter}
              filterType='string-url-gsc'
              filterParams={{ GSCDetails: GSCDetails }}
            />
          </div> 
        </div>    
      </div>
    </ReactTooltip>
  )
}

export default GSCFilterToolTip;