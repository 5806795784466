/** Dependencies **/
import React, {useState, useRef, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import Loader from './../../../Loader';

/** Helpers */
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';
import {
  removeDomain,
  formatCompareValues
} from './../../../../helpers/functions';

import { 
	getPicto
} from './../../../../helpers/pictos';

import {
  getAllExpectedURLS,
  sortColumn,
  getPictoSort
} from './../../../../helpers/barometer/table';

import { filterOnValueToContext } from './../../../../helpers/barometer/barometer';

function GSCDetails( props ) 
{
	// Get props
  const {
    id,
    accessor,
    label
  } = props;

  // Define ref
	const tooltipGSC = useRef( null );

  /** Init state **/
  const [popinContent, setPopinContent] = useState( null );
  const [currentGSCDetails, setCurrentGSCDetails] = useState( null );
  const [compareGSCDetails, setCompareGSCDetails] = useState( null );
  const [sort, setSort] = useState( { value: 'clicks', order: 'desc' } );

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const globalResult = useSelector( state => state.datas.value.globalResult );
  const barometerContext = useSelector( state => state.barometerContext.value );  
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /**
	 * Load content of popin
	 */
	const loadContent = () => 
	{
		if( label !== '' )
		{
      // GSC
      if( 
        selectedFilters?.currentPeriod?.startdate !== undefined
        && selectedFilters?.currentPeriod?.enddate !== undefined
        && selectedFilters.devLoc !== undefined
      ){  
        // GSC current details
        callWebservice(
					id,
					id + '-currentGSCDetails',
					'gsc-details-by-keywords',
					dispatch,
          selectedInstance,
					{
						cols: ["keyword", "url", "SUM(impressions) AS impressions", "SUM(clicks) AS clicks", "AVG(position) AS position"],
						period: {
              startdate: selectedFilters.currentPeriod.startdate,
              enddate: selectedFilters.currentPeriod.enddate
            },
						where: {
							keywords: [label],
							locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
							devices: [selectedFilters.devLoc.deviceValue]
						},
						groupby: ['url']
					},
					{ function: 'setCurrentGSCDetails' }
				);

        // GSC compare details
        if( 
          selectedFilters?.comparePeriod?.startdate !== undefined
          && selectedFilters?.comparePeriod?.enddate !== undefined
          && selectedFilters.devLoc !== undefined
        ){ 
          callWebservice(
            id,
            id + '-compareGSCDetails',
            'gsc-details-by-keywords',
            dispatch,
            selectedInstance,
            {
              cols: ["keyword", "url", "SUM(impressions) AS impressions", "SUM(clicks) AS clicks", "AVG(position) AS position"],
              period: {
                startdate: selectedFilters.comparePeriod.startdate,
                enddate: selectedFilters.comparePeriod.enddate
              },
              where: {
                keywords: [label],
                locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
                devices: [selectedFilters.devLoc.deviceValue]
              },
              groupby: ['url']
            },
            { function: 'setCompareGSCDetails' }
          );
        }

      } else {
        setCurrentGSCDetails( [] );
        setCompareGSCDetails( [] );
      }
		}
	}

	/**
   * Build popin content with current and compare loaded datas of gsc details
   */
  const buildPopinContent = () => 
  {
    let content = [];

    if( currentGSCDetails[label] )
    {
      // get all expected urls
      const allExpectedURLS = getAllExpectedURLS( globalResult );

      // set header table
      const header = 
        <thead>
          <tr>
            <th className='url'>url</th>
            <th onClick={ () => sortColumn( 'impressions', sort, setSort ) }><button>{getPictoSort( 'impressions', sort )}impressions</button></th>
            <th onClick={ () => sortColumn( 'clicks', sort, setSort ) }><button>{getPictoSort( 'clicks', sort )}clics</button></th>
            <th onClick={ () => sortColumn( 'ctr', sort, setSort ) }><button>{getPictoSort( 'ctr', sort )}ctr</button></th>
            <th onClick={ () => sortColumn( 'position', sort, setSort ) }><button>{getPictoSort( 'position', sort )}position</button></th>
          </tr>
        </thead>;
      
      // set body table
      const lines = currentGSCDetails[label]
        .filter( detail => parseInt( detail.impressions ) > 1 )
        .sort( ( a, b ) => {
          return sort.value === 'ctr' ? 
            sort.order === 'desc' ? 
              ( b.clicks / b.impressions ) - ( a.clicks / a.impressions ) 
              : ( a.clicks / a.impressions ) - ( b.clicks / b.impressions ) 
          : sort.order === 'desc' ? 
              b[sort.value] - a[sort.value] 
              : a[sort.value] - b[sort.value]
        })
        .map( ( currentDatas, index ) => 
        {
          // get compare values
          let compareImpressions = null;
          let compareClicks = null;
          let comparePosition = null;
          let compareCTR = null;

          if( compareGSCDetails !== null && compareGSCDetails[label] )
          {
            const compareValues = compareGSCDetails[label].filter( compareDatas => compareDatas.url === currentDatas.url );          
            if( compareValues.length === 1 || compareValues.length === 0 )
            {
              compareImpressions = formatCompareValues( currentDatas.impressions, compareValues[0] ? compareValues[0]['impressions'] : 0 );

              compareClicks = formatCompareValues( currentDatas.clicks, compareValues[0] ? compareValues[0]['clicks'] : 0 );

              if( Math.abs( compareImpressions ) > 0 && Math.abs( currentDatas.impressions ) > 0 )
                compareCTR = formatCompareValues( ( currentDatas.clicks / currentDatas.impressions ), ( compareClicks / compareImpressions ) );

              comparePosition = formatCompareValues( compareValues[0] ? compareValues[0]['position'] : 101, currentDatas.position );
            }
          };
                
          return <tr key={index}>
            <td className='url'>
              { allExpectedURLS.includes( currentDatas.url ) ? 
                <button className='followed' onClick={ () => filterOnValueToContext( currentDatas.url, 'label', 'urls', navigate ) } title={currentDatas.url}>{removeDomain( currentDatas.url )}</button>
                : <p>{removeDomain( currentDatas.url )}</p>
              }
              <a href={currentDatas.url} target="_blank" rel="noreferrer" title={currentDatas.url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
            </td>
            <td>
              { new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentDatas.impressions ) }
              { compareImpressions !== null && Math.abs( compareImpressions ) !== 0 ? <span> ({compareImpressions})</span> : false }
            </td>
            <td>
              { new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentDatas.clicks ) }
              { compareClicks !== null && Math.abs( compareClicks ) !== 0 ? <span> ({compareClicks})</span> : false }
            </td>
            <td>
              {currentDatas.impressions > 0 ? 
                new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, style: 'percent' }).format( ( currentDatas.clicks / currentDatas.impressions ) )
                : false 
              }
              { compareCTR !== null && Math.abs( compareCTR ) !== 0 ? <span> ({compareCTR})</span> : false }
            </td>
            <td>
              { new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentDatas.position ) }
              { comparePosition !== null && Math.abs( comparePosition ) !== 0 ? <span> ({comparePosition})</span> : false }
            </td>
          </tr>
        }
      );

      content = [...content, 
        <table key="table-content">
          {header}
          <tbody>{
            lines.length > 0 ?
              lines
              : <tr><td className='url'><p>Aucune donnée valable sur cette période d'analyse</p></td></tr>
          }</tbody>
        </table>
      ];
    }

    // Set popin content
    setPopinContent( content.length > 0 ? content : null );
  }

  /**
   * Build content when datas are loaded
   */
  useEffect( () => 
  {
    if( currentGSCDetails !== null )
      buildPopinContent();
  }, [ 
    currentGSCDetails,
    compareGSCDetails,
    sort
  ]);

	return (
		<ReactTooltip 
			id={id}
			ref={tooltipGSC}
			className="tooltip grey"
			effect='solid'
      offset={{left: barometerContext === 'gsc-keywords' ? 100 : 200 }}
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterHide={ () => {
				tooltipGSC.current.tooltipRef.style.left = null;
				tooltipGSC.current.tooltipRef.style.top = null;
			}}
      afterShow={loadContent}
			globalEventOff={'click'}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset in terms of gsc data type
        let offsetX = 0;
        switch ( accessor ) {
          case 'gsc.impressions':
            offsetX = barometerContext === 'gsc-keywords' ? 20 : 20;
            break;
          case 'gsc.clicks':
            offsetX = barometerContext === 'gsc-keywords' ? -114 : -80;
            break;
          case 'gsc.ctr':
            offsetX = barometerContext === 'gsc-keywords' ? -247 : -180;
            break;        
          default:
            offsetX = barometerContext === 'gsc-keywords' ? -381 : -280;
            break;
        }
        
        return {
          left: - width / 2 + offsetX,
          top: 50
        };
      }}
		>
      <React.Fragment>
        <Loader 
          loaderID={id} 
          loaderStyle={{width:'15', stroke:'#e43e21'}} 
          callBackFcts={{
            setCurrentGSCDetails: setCurrentGSCDetails,
            setCompareGSCDetails: setCompareGSCDetails
          }}
          globalCallBack={undefined} 
        />
        {popinContent !== null ?
          <div className='tooltip-container'>
            {popinContent}
          </div>
          : false
        }
      </React.Fragment>      
		</ReactTooltip>			
	);
}

export default GSCDetails;