/** Helpers **/
import { getPicto } from "./../../../../helpers/pictos";

function SimpleSortToolTip ( props )
{
  /** Get props */
  const isSorted = props.column.isSorted;
  const isSortedDesc = props.column.isSortedDesc;
  const id = props.id;

  /** Define label of sort buttons **/
  const label1 = 'Valeurs T de réf.';
  
  let label2 = 'Du plus petit au plus grand';
  let label3 = 'Du plus grand au plus petit';
  if( id === 'gsc.identifierDate' )
  {
    label2 = 'Du plus ancien au plus récent';
    label3 = 'Du plus récent au plus ancien';
  }

  return (
    <div className="sort">
      <div className="title"><h4>Trier</h4></div>
      <ul className="preselect sort-type">
        <li>
          <button 
            className={ isSorted === true ? 'on' : '' } 
            onClick={ () => props.column.toggleSortBy( true ) }
          >{label1}</button>
        </li>
      </ul>
      <ul className="preselect">
        <li>
          <button 
            className={ isSortedDesc === false && isSorted === true ? 'on reverse' : 'reverse' } 
            onClick={ () => props.column.toggleSortBy( false ) }
          >{getPicto( 'Sort', { size:"1.3rem", transform:"rotate(180)" } )}{label2}</button>
        </li>
        <li>
          <button 
            className={ isSortedDesc === true && isSorted === true ? 'on reverse' : 'reverse' } 
            onClick={ () => props.column.toggleSortBy( true ) }
          >{getPicto( 'Sort', { size:"1.3rem" } )}{label3}</button>
        </li>
      </ul>
    </div>
  )
}

export default SimpleSortToolTip;