/** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { 
  orderObjectKeysWithPattern
} from './../../../../helpers/functions';

function MyPerfDetailsCategory( props ) 
{
	// Get props
  const id = props.id;
  const snippetID = 'perfs-' + id;
  const accessor = props.accessor;
  const keywords = props.keywords;
  const labelCategory = [
    props.category1, 
    props.category2, 
    props.category3
  ].filter( cat => cat !== null ).join( '|' );

  // Define ref
	const tooltipSnippet = useRef( null );

  /** Init state **/
  const [popinContent, setPopinContent] = useState( null );

  /** Get state from redux store **/
  const currentSnippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  const compareSnippetsDetails = useSelector( state => state.datas.value.compareSnippetsDetails );

	// Set popin content
  const buildPopinContent = () => 
  {
    let content = null;

    // get current datas
    const currentDatas = currentSnippetsDetails.filter( detail => 
      detail.label.startsWith( labelCategory ) 
      && detail.who === accessor 
      && (
        detail.typeSnippet === 'SEO'
        || detail.typeSnippet === 'Featured Snippet'
      )
    );

    // get compare datas
    const compareDatas = compareSnippetsDetails.filter( detail => 
      detail.label.startsWith( labelCategory ) 
      && detail.who === accessor 
      && (
        detail.typeSnippet === 'SEO'
        || detail.typeSnippet === 'Featured Snippet'
      )
    );

    // agregate data with domain for current datas
    let currentDataWithDomain = {};
    currentDatas.forEach( data => 
    {
      // get domain url
      const regexp = data.url.match( /https?\:\/\/(?:www\.)?([^\/]*)(?:\/(?:.*))?$/i );

      let domain = null;
      if( regexp[1] )
        domain = regexp[1];  

      // add position with domain
      if( domain !== null )
      {
        if( currentDataWithDomain[domain] && currentDataWithDomain[domain].position )
          currentDataWithDomain[domain].position = [...currentDataWithDomain[domain].position, data.position];
        else
          currentDataWithDomain[domain] = {position: [data.position]};
      }
    });

    // agregate data with domain for compare datas
    let compareDataWithDomain = {};
    compareDatas.forEach( data => 
    {
      // get domain url
      const regexp = data.url.match( /https?\:\/\/(?:www\.)?([^\/]*)(?:\/(?:.*))?$/i );

      let domain = null;
      if( regexp[1] )
        domain = regexp[1];  

      // add position with domain
      if( domain !== null )
      {
        if( compareDataWithDomain[domain] && compareDataWithDomain[domain].position )
          compareDataWithDomain[domain].position = [...compareDataWithDomain[domain].position, data.position];
        else
          compareDataWithDomain[domain] = {position: [data.position]};
      }
    });

    // define object datas with domain
    let datasWithDomains = {};

    // set average of position with domain for current datas
    Object.keys( currentDataWithDomain ).forEach( domain => 
    {
      // fill array with current position and add values until 100 entries
      if( keywords.length - currentDataWithDomain[domain].position.length > 0 )
        currentDataWithDomain[domain].position = [...currentDataWithDomain[domain].position, ...Array( keywords.length - currentDataWithDomain[domain].position.length ).fill( 100 )];

      datasWithDomains[domain] = {
        currentPosition: currentDataWithDomain[domain].position.reduce( ( a, b ) => 
          parseFloat( a ) + parseFloat( b )
          , 0
        ) / currentDataWithDomain[domain].position.length
      };
    });

    // set average of position with domain for compare datas
    Object.keys( compareDataWithDomain ).forEach( domain => 
    {
      // fill array with current position and add values until 100 entries
      if( keywords.length - compareDataWithDomain[domain].position.length > 0 )
        compareDataWithDomain[domain].position = [...compareDataWithDomain[domain].position, ...Array( keywords.length - compareDataWithDomain[domain].position.length ).fill( 100 )];

      datasWithDomains[domain] = {...datasWithDomains[domain],
        comparePosition: compareDataWithDomain[domain].position.reduce( ( a, b ) => 
          parseFloat( a ) + parseFloat( b )
          , 0
        ) / compareDataWithDomain[domain].position.length
      }
    });

    // sort datas with current positions
    const pattern = Object.keys( datasWithDomains ).sort( ( keyA, keyB ) => 
      datasWithDomains[keyA].currentPosition - datasWithDomains[keyB].currentPosition
    );
    datasWithDomains = orderObjectKeysWithPattern( datasWithDomains, pattern )


    // define content except Ads and Shopping
    content = Object.keys( datasWithDomains ).map( ( domain, index ) => 
      datasWithDomains[domain].currentPosition !== undefined ?
        <tr key={index}>
          <td className="type-snippet">
            {( index + 1 ) + '. '}{domain}&nbsp;:&nbsp;
            {new Intl.NumberFormat( 'fr-FR', { maximumFractionDigits: 1, style: 'decimal' }).format( datasWithDomains[domain].currentPosition )}
            {
              datasWithDomains[domain].comparePosition !== undefined
              && parseFloat( datasWithDomains[domain].comparePosition ) - parseFloat( datasWithDomains[domain].currentPosition ) !== 0 ?
                <span className='variation'>&nbsp;(
                  { 
                    new Intl.NumberFormat( 'fr-FR', { maximumFractionDigits: 1, style: 'decimal', signDisplay: 'always' }).format(
                      parseFloat( datasWithDomains[domain].comparePosition ) - parseFloat( datasWithDomains[domain].currentPosition )
                    )                    
                  }
                )</span>
              : false
            }
          </td>
        </tr>
      : false
    );
    
    // Set popin content
    setPopinContent( content );
  }

	return (
		<ReactTooltip 
			id={snippetID}
			ref={tooltipSnippet}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterHide={ () => {
				tooltipSnippet.current.tooltipRef.style.left = null;
				tooltipSnippet.current.tooltipRef.style.top = null;
			}}
      afterShow={buildPopinContent}
			globalEventOff={'click'}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 - 20,
          top: 40
        };
      }}
		>
      <div className='tooltip-container'>
        <table>
          <tbody>
            {popinContent}
          </tbody>
        </table>
      </div>
		</ReactTooltip>			
	);
}

export default MyPerfDetailsCategory;