/** Class object */
import wsKeywords from './wsKeywords.class.js';

/** Helpers **/
import { getCurrentDate } from './../functions';
import { callWebservice } from './webserviceCaller';
import { getItem } from './../../services/LocaleStorage';

export default class wsActions
{
  constructor(
    selectedFilters,
    loaderID,
    globalResult,
    dispatch,
    selectedInstance
  ){
    this.loaderID = loaderID;
    this.device = selectedFilters.devLoc.deviceValue;
    this.location = selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue;
    this.globalResult = globalResult;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;

    // instance wbKeyword object
    this.wsKeywords = new wsKeywords(
      selectedFilters,
      loaderID,
      dispatch,
      selectedInstance
    )
  }

  actionsForNewStatus = ( 
    actionID, 
    label, 
    categories = null, 
    actionSelectedLastUpdate = null, 
    actionSelectedUsersIDs = null 
  ) => 
  {
    // add or update entries = 
    const entries = [{
      label: label,
      actionSelectedLastUpdate: actionSelectedLastUpdate,
      actionSelectedUsersIDs: actionSelectedUsersIDs
    }];

    switch ( parseInt( actionID ) ) 
    {
      // ADD status => A optimiser (prio 1) || A surveiller || A valider || Optimisé (prio 1) || A optimiser (prio 2) || Optimisé (prio 2)
      case 0:
      case 1:
      case 2:
      case 4:
      case 5:
      case 6:
  
        // update keyword with new extract source
        this.wsKeywords.updateKeywords( [label], categories, 'volume|serp|gsc' );
  
        // add monitor status for keyword
        this.addOrUpdateActions( entries, 7, 'gsc-keywords' );
        break;
  
      // ADD status => A suivre que GSC
      case 3:
  
        // update keyword with new extract source
        this.wsKeywords.updateKeywords( [label], categories, 'volume|gsc' );
  
        // add monitor status for keyword
        this.addOrUpdateActions( entries, 8, 'gsc-keywords' );
        break;
  
      // ADD status => Suivi prio + GSC
      case 7:
  
        // add keyword with categories and extract volume/serp/gsc
        if( this.globalResult.filter( result => result.label === label && ![ 9, null ].includes( result?.action?.automaticActionID ) ) )
          this.wsKeywords.addKeywords( [label], categories, 'volume|serp|gsc' );
        else
          this.wsKeywords.updateKeywords( [label], categories, 'volume|serp|gsc' );
  
        // add monitor status for keyword
        this.addActions( [label], 2, 'keywords' );
        break;
  
      // ADD status => Suivi GSC
      case 8:

        // add keyword with categories and extract volume/gsc if no exist (automatic status for GSC case)
        if( this.globalResult.filter( result => result.label === label && ![ 9, null ].includes( result?.action?.automaticActionID ) ) )
          this.wsKeywords.addKeywords( [label], categories, 'volume|gsc' );
        else
          this.wsKeywords.updateKeywords( [label], categories, 'volume|gsc' );
  
        // add ignore status in keyword context
        this.addActions( [label], 3, 'keywords' );
        break;

      // ADD status => Pas de suivi 
      case 9:
  
        // remove keyword and state from keyword monitoring
        this.wsKeywords.delKeywords( [label] );
  
        // remove status for keyword monitoring
        this.delActions( [label], actionID, 'keywords' );
        break;
        
      default:
        break;
    }
  }

  actionsForUpdateStatus = ( 
    actionID, 
    updatedActionID,
    label,
    categories = null,
    actionSelectedLastUpdate = null, 
    actionSelectedUsersIDs = null 
  ) => 
  {
    // add or update entries = 
    const entries = [{
      label: label,
      actionSelectedLastUpdate: actionSelectedLastUpdate,
      actionSelectedUsersIDs: actionSelectedUsersIDs
    }];

    switch ( parseInt( actionID ) )
    {
      // UPDATE status => A optimiser (prio 1) || A surveiller || A valider || Optimisé (prio 1) || A optimiser (prio 2) || Optimisé (prio 2)
      case 0:
      case 1:
      case 2:
      case 4:
      case 5:
      case 6:
  
        // update keyword with new extract source
        this.wsKeywords.updateKeywords( [label], categories, 'volume|serp|gsc' );
  
        // add monitor status for keyword
        this.addOrUpdateActions( entries, 7, 'gsc-keywords' );
        break;
  
      // UPDATE status =>suivre que GSC
      case 3:
  
        // update keyword with new extract source
        this.wsKeywords.updateKeywords( [label], categories, 'volume|gsc' );
        
        // add monitor status for gsc-keyword
        this.addOrUpdateActions( entries, 8, 'gsc-keywords' );
        break;
  
      // UPDATE status => Suivi prio + GSC
      case 7:
  
        switch ( updatedActionID ) 
        {
          // FROM => Suivi GSC
          case 8:
  
            // update keyword with new extract source
            this.wsKeywords.updateKeywords( [label], categories, 'volume|serp|gsc' );
  
            // add monitor status for keyword
            this.addOrUpdateActions( entries, 2, 'keywords' );
            break;
  
          // FROM => Pas de suivi
          case 9:

            // add keyword with N.C. categories
            this.wsKeywords.addKeywords( [label], [ 'N.C.', 'N.C.', 'N.C.' ], 'volume|serp|gsc' );
            
            // add monitor status for keyword
            this.addActions( [label], 2, 'keywords' );
            break;
          default:
            break;
        }
  
        break;
  
      // UPDATE status => Suivi GSC
      case 8:
  
        switch ( updatedActionID ) 
        {
          // FROM => Suivi prio + GSC
          case 7:

            // update keyword with new extract source
            this.wsKeywords.updateKeywords( [label], categories, 'volume|gsc', );
  
            // add monitor status for keyword
            this.addOrUpdateActions( entries, 3, 'keywords' );
            break;
  
          // FROM => Pas de suivi 
          case 9:

            // add keyword with N.C. categories
            this.wsKeywords.addKeywords( [label], [ 'N.C.', 'N.C.', 'N.C.' ], 'volume|gsc' );
            
            // add monitor status for keyword
            this.addActions( [label], 3, 'keywords' );
            break;
          default:
            break;
        }
  
        break;
  
      // UPDATE status => Pas de suivi 
      case 9:
  
        // remove keyword and state from keyword monitoring
        this.wsKeywords.delKeywords( [label] );
  
        // remove status for keyword monitoring
        this.delActions( [label], actionID, 'keywords' );
        break;
        
      default:
        break;
    }
  }

  addOrUpdateActions = (
    entries,
    actionID, 
    context,
    updateTable = false
  ) => 
  {
    const currentDate = getCurrentDate( "YYYYMMDDHHmmss" );

    // get add or update action to do
    let addActionsEntries = [];
    let updateActionsEntries = [];
    entries.forEach( entry =>
    {
      if( 
        entry.actionSelectedLastUpdate !== null
        && entry.actionSelectedLastUpdate.toString().substring( 0, 8 ) === currentDate.toString().substring( 0, 8 )
        && entry.actionSelectedUsersIDs !== null
        && entry.actionSelectedUsersIDs.includes( parseInt( getItem( 'userID' ) ) )
      )
        updateActionsEntries = [...updateActionsEntries, entry.label ];
      else
        addActionsEntries = [...addActionsEntries, entry.label ];  
    });

    // exec add actions
    if( addActionsEntries.length > 0 )
      this.addActions(
        addActionsEntries, 
        actionID,
        context,
        updateTable
      );
  
    // exec update actions
    if( updateActionsEntries.length > 0 )
      this.updateActions(
        updateActionsEntries, 
        actionID,
        context,
        updateTable
      );
  }

  addActions = (
    entries, 
    actionID,
    context,
    updateTable = false
  ) => 
  {
    this.exec(
      entries, 
      actionID,
      'add',
      context,
      updateTable
    );
  }
  
  updateActions = (
    entries, 
    actionID,
    context,
    updateTable = false
  ) => 
  {
    this.exec(
      entries, 
      actionID,
      'update',
      context,
      updateTable
    );
  }
  
  delActions = (
    entries,
    actionID,
    context
  ) => 
  {
    this.exec(
      entries,
      actionID,
      'remove',
      context      
    );
  }

  exec = (
    entries, 
    actionID,
    actionType,
    context,
    updateTable = false
  ) => 
  {
    const currentDate = getCurrentDate( "YYYYMMDDHHmmss" );

    callWebservice( 
      this.loaderID,        
      actionType + '-' + actionID + '-actions',
      actionType + '-actions',
      this.dispatch,
      this.selectedInstance,
      {
        entries: entries, 
        action_id: actionID,
        user_id: parseInt( getItem( 'userID' ) ),
        currentDate: parseInt( currentDate ),
        device: actionType === 'update' ? [this.device] : this.device,
        location: actionType === 'update' ? [this.location] : this.location,
        context: context
      },
      updateTable === true ? {
        function: 'updateActionState'
      } : null
    );
  }
}