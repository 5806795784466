/** Components **/
import TableCellSERPLink from './../../../components/Dashboards/Barometer/TableCells/SERPLink';

export const SerpViewerColumn = ( widthColumns ) => 
{
  return {
    accessor: "serpviewer",
    width: widthColumns["serpviewer"],
    className: "col-snippets serp-viewer",
    disableFilters: true,
    disableSortBy: true,
    Header: '',
    Total: '',
    Cell: (props) => 
      <TableCellSERPLink
        serpHtmlLinkUrl={props.row.original.serpHtmlLinkUrl} 
      />
  }
}