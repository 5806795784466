/** Dependencies **/
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

/** Components */
import AutoSuggestFilter from './Filters/AutoSuggestFilter';

/** Helpers */
import { 
  stringLabelCustomFilter
} from './../../../../helpers/barometer/customFilters';

/**
 * Define a default UI for filtering
 * @param {object} {column: { preFilteredRows, id, filterTitle }...}
 * @returns ReactDOM with input
 */
function DefaultStringFilterToolTip ({
  column: { preFilteredRows, id, filterTitle },
})
{
  // define status for custom values
  const [toolTipShown, setToolTipShown] = useState( false );
  
  return (
    <ReactTooltip 
      id={'filter-'+id}
      className="tooltip red"
      effect='solid'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      isCapture={true}
      arrowColor="#e43e21"
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set left value
        let left = currentTarget.offsetLeft - (width / 2);
        if( id === 'label' )
          left = -41;

        return {
          left: left,
          top: 17
        };
      }}
      afterShow={() => setToolTipShown( true ) }
      afterHide={() => setToolTipShown( false ) }
    >
      <div className="filter text">
        <div className="title">{ filterTitle !== '' ? <h4>{ filterTitle }</h4> : '' }</div>
        <AutoSuggestFilter 
          column={{ id: id, preFilteredRows: preFilteredRows }}
          filterFct={stringLabelCustomFilter}
          filterType='string-label'
          toolTipShown={toolTipShown}
        />
      </div>
    </ReactTooltip>
  )
}

export default DefaultStringFilterToolTip;