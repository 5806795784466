/** Components **/
import ActionsFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/ActionsFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Dashboards/Barometer/TableHeaders/TitleWithPicto';
import TableCellActions from './../../../components/Dashboards/Barometer/TableCells/Actions';

export const DecisionsColumn = ( widthColumns ) => 
{
  return {
    accessor: "action",
    width: widthColumns["action"],
    title: "actions",
    className: "col-decisions col-center",
    Filter: ActionsFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: "Filtrer",
    sortDescFirst: true,
    sortType: 'actionSort',
    Header: props => 
      <TableHeaderTitleWithPicto 
        id={props.column.id} 
        title={props.column.title} 
      />,
    Total: '',
    Cell: props => 
      <TableCellActions 
        {...props.row.getToggleRowSelectedProps()} 
        id={props.row.id} 
        label={props.row.original.label}
        keywords={props.row.original.keywords} 
        categories={[
          props.row.original.category1, 
          props.row.original.category2, 
          props.row.original.category3
        ]} 
        lastUpdate={
          props?.value?.lastUpdate !== undefined ? 
            props.value.lastUpdate 
            : null
        } 
        usersIDs={
          props?.value?.usersIDs !== undefined ? 
            props.value.usersIDs 
            : null
        }
        automaticActionID={
          props?.value?.automaticActionID !== undefined 
          && props.value.automaticActionID !== null ? 
            props.value.automaticActionID 
            : null
        } 
        updatedActionID={
          props?.value?.updatedActionID !== undefined 
          && props.value.updatedActionID !== null ? 
            props.value.updatedActionID 
            : null
        }
        comments={
          props?.row?.original?.comments !== undefined ? 
            props.row.original.comments 
            : false
        }
        automaticExpectedUrl={
          props?.row?.original?.expectedUrl?.automaticExpectedUrl !== undefined 
          && props.row.original.expectedUrl.automaticExpectedUrl !== null ? 
            props.row.original.expectedUrl.automaticExpectedUrl 
            : null
        } 
        updatedExpectedUrl={
          props?.row?.original?.expectedUrl?.updatedExpectedUrl !== undefined 
          && props.row.original.expectedUrl.updatedExpectedUrl !== null ? 
            props.row.original.expectedUrl.updatedExpectedUrl 
            : null
        }
        typeExpectedUrl={
          props?.row?.original?.expectedUrl?.type !== undefined 
          && props.row.original.expectedUrl.type !== null ? 
            props.row.original.expectedUrl.type 
            : null
        }
      />
  }
}