/** Components **/
import SnippetsFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/SnippetsFilterToolTip';
import TableHeaderSnippets from './../../../components/Dashboards/Barometer/TableHeaders/Snippets';
import AreaTotalCell from './../../../components/Dashboards/Barometer/TableTotalCells/AreaTotalCell';
import TableCellSnippet from './../../../components/Dashboards/Barometer/TableCells/Snippet';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

export const SerpColumn = ( widthColumns ) => 
{
  const snippetsAccessor = globalConfig.barometer.snippet_grps;

  let serpColumns = [];
  Object.keys( snippetsAccessor ).forEach( accessor => {
    serpColumns.push({
      accessor: snippetsAccessor[accessor].accessor,
      width: widthColumns[snippetsAccessor[accessor].accessor],
      title: snippetsAccessor[accessor].label,
      className: "col-snippets",
      Filter: SnippetsFilterToolTip,
      filter: 'multiFieldsCustomFilter',
      sortDescFirst: true,
      sortType: 'valueSort',
      Header: props => 
        <TableHeaderSnippets type={accessor} title={props.column.title} />,
      Total: props => 
        <AreaTotalCell id={props.column.id} />,
      Cell: props => 
        <TableCellSnippet 
          id={props.row.id} 
          accessor={snippetsAccessor[accessor].accessor} 
          label={props.row.values.label} 
          keywords={props.row.original.keywords} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          category3={props.row.original.category3} 
          values={props.value} 
        />
    });
  });

  return serpColumns;
}