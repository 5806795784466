/** Components **/
import TableHeaderTitleWithPicto from './../../../components/Dashboards/Barometer/TableHeaders/TitleWithPicto';
import TableCellKDifficulty from './../../../components/Dashboards/Barometer/TableCells/KDifficulty';
import DefaultRangeFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/DefaultRangeFilterToolTip';

export const KDifficultyColumn = ( widthColumns ) => 
{
  return {
    accessor: "kdifficulty",
    width: widthColumns["kdifficulty"],
    title: "k. diffic.",
    className: "col-decisions",
    Filter: DefaultRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: "Filtrer par actions",
    sortDescFirst: true,
    sortType: 'valueSort',
    Header: props => 
      <TableHeaderTitleWithPicto 
        id={props.column.id} 
        title={props.column.title} 
      />,
    Total: '',
    Cell: props => 
      <TableCellKDifficulty values={props.cell.row.values.kdifficulty} />
  }
}