/**
 * 
 * @returns object conf for custom sort of table
 */
 export const getCustomSorts = () => {
  
  return {
    valueSort: (rowA, rowB, columnID) => 
    {
      let rowAValue = columnID === 'gsc.position' ? 101 : 0;
      if( rowA.values[columnID] && rowA.values[columnID].value )
        rowAValue = rowA.values[columnID].value;

      let rowBValue = columnID === 'gsc.position' ? 101 : 0;
      if( rowB.values[columnID] && rowB.values[columnID].value )
        rowBValue = rowB.values[columnID].value;

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    },
    nbRankedKeywordsSort: (rowA, rowB, columnID) => 
    {
      let rowAValue = 0;
      if( 
        rowA?.values[columnID]?.positions !== undefined
        && rowA.values[columnID].positions !== null
        && rowA.values[columnID].positions instanceof Array
      )
        rowAValue = rowA.values[columnID].positions.filter( position => position.split( '|' )[1] !== 101 ).length;

      let rowBValue = 0;
      if( 
        rowB?.values[columnID]?.positions !== undefined
        && rowB.values[columnID].positions !== null
        && rowB.values[columnID].positions instanceof Array
      )
        rowBValue = rowB.values[columnID].positions.filter( position => position.split( '|' )[1] !== 101 ).length;

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    },
    avgPositionSort: (rowA, rowB, columnID) => 
    {
      let rowAValue = 101;
      if( 
        rowA.values[columnID]?.avgPosition 
        && typeof rowA.values[columnID]?.avgPosition === 'string'
        && rowA.values[columnID].avgPosition.split( '|' )[1] !== 'null'
      )
        rowAValue = parseFloat( rowA.values[columnID].avgPosition.split( '|' )[1] );

      let rowBValue = 101;
      if( 
        rowB.values[columnID]?.avgPosition 
        && typeof rowB.values[columnID]?.avgPosition === 'string'
        && rowB.values[columnID].avgPosition.split( '|' )[1] !== 'null'
      )
        rowBValue = parseFloat( rowB.values[columnID].avgPosition.split( '|' )[1] );

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    },
    bestPositionSort: (rowA, rowB, columnID) => 
    {
      let rowAValue = 101;
      if( 
        rowA.values[columnID]?.positions 
        && rowA.values[columnID]?.positions instanceof Array
      )
        rowAValue = Math.min( ...rowA.values[columnID].positions.map( value => 
          parseInt( value.split( '|' )[1] ) 
        ));

      let rowBValue = 101;
      if( 
        rowB.values[columnID]?.positions 
        && rowB.values[columnID]?.positions instanceof Array
      )
        rowBValue = Math.min( ...rowB.values[columnID].positions.map( value => 
          parseInt( value.split( '|' )[1] ) 
        ));

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    },
    variationSort: (rowA, rowB, columnID) => 
    {
      let rowAValue = 0;
      if( rowA.values[columnID] && rowA.values[columnID].variation )
        rowAValue = rowA.values[columnID].variation;

      let rowBValue = 0;
      if( rowB.values[columnID] && rowB.values[columnID].variation )
        rowBValue = rowB.values[columnID].variation;

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    },
    variationPosSort: (rowA, rowB, columnID) => 
    {
      let rowAValue = 0;
      if( rowA?.values[columnID]?.positions )
      {
        // get best position
        let rowAPositions = rowA?.values[columnID]?.positions[0].split( '|' );

        // get compare value
        let rowAComparePos = null;
        let rowACurrentPos = null;
        if( rowAPositions.length === 2 )
        {
          rowAComparePos = parseInt( rowAPositions[0] );
          rowACurrentPos = parseInt( rowAPositions[1] );
        }
        
        // set variation
        if( rowACurrentPos !== null && rowAComparePos !== null )
          rowAValue = rowAComparePos - rowACurrentPos;
      }

      let rowBValue = 0;
      if( rowB?.values[columnID]?.positions )
      {
        // get best position
        let rowBPositions = rowB?.values[columnID]?.positions[0].split( '|' );

        // get compare value
        let rowBComparePos = null;
        let rowBCurrentPos = null;
        if( rowBPositions.length === 2 )
        {
          rowBComparePos = parseInt( rowBPositions[0] );
          rowBCurrentPos = parseInt( rowBPositions[1] );
        }
        
        // set variation
        if( rowBCurrentPos !== null && rowBComparePos !== null )
          rowBValue = rowBComparePos - rowBCurrentPos;
      }

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    },
    actionSort: (rowA, rowB) => 
    {
      let rowAState = -1;
      if( rowA.original.action.actionID !== null )
        rowAState =  rowA.original.action.actionID;

      let rowBState = -1;
      if( rowB.original.action.actionID !== null )
        rowBState =  rowB.original.action.actionID;

      if (rowAState < rowBState) return -1; 
      if (rowBState < rowAState) return 1;
    },
    identifierDateSort: (rowA, rowB, columnID) =>
    {
      let rowAValue = 0;
      if( rowA?.values[columnID]?.value !== undefined )
        rowAValue = rowA.values[columnID].value;

      let rowBValue = 0;
      if( rowB?.values[columnID]?.value )
        rowBValue = rowB.values[columnID].value;

      if ( rowAValue < rowBValue ) return -1; 
      if ( rowBValue < rowAValue ) return 1;
    }
  };
}