/** Dependencies **/
import React from 'react';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from '../../../../reducers/selectedHeaderFilters';

/** Helpers **/
import { getPicto } from './../../../../helpers/pictos';
import { removeDomain } from './../../../../helpers/functions';
import { filterOnValueToContext } from '../../../../helpers/barometer/barometer';

function Urls( props ) 
{
  /** Get props **/
  let url = props.value;

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** instance navigate object */
  const navigate = useNavigate();

  /**
   * Dispatch filter on expected url with current expected url
   */
  const filterOnUrl = () => 
  {
    dispatch( 
      updateSelectedHeaderFilters( { 
        filterName: 'label', 
        value: {
          type: 'text',
          label: url,
          option: 'exactly',
          usefor: 'filter',
          value: {
            field: 'label-text',
            type: 'string-label',
            result: url
          } 
        }
      }) 
    ); 
  }    

  return (
    <div className="url-container">
      <div className='url'>
        <div className='label' title={url} onClick={filterOnUrl}>{ removeDomain( url ) }</div>
        <a href={url} target="_blank" rel="noreferrer" title={url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
      </div>
      <div className='keywords-link-container'>
        <button 
          className='keywords-link'
          onClick={ () => filterOnValueToContext( url, 'perfme', 'keywords', navigate  ) }
        >
          {getPicto( 'List', {size: '1rem'} )}
          voir les mots clés positionnés 
        </button>
        &nbsp;/&nbsp;
        <button 
          className='keywords-link'
          onClick={ () => filterOnValueToContext( url, 'action', 'keywords', navigate ) }
        >
          {getPicto( 'List', {size: '1rem'} )}
          voir les mots clés assignés
        </button>
      </div>
    </div>
  );
}

export default Urls;