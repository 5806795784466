/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getIsIgnore } from './../../../../helpers/datas';

function PerfsTotalCell( props ) 
{
  /** Get props **/
  const id = props.id;

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const currentSnippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  const compareSnippetsDetails = useSelector( state => state.datas.value.compareSnippetsDetails );

  // filter datas with non ignore value
  let nonIgnoreDatas = null;
  if( datas.filterResult )
    nonIgnoreDatas = datas.filterResult.filter( item => !getIsIgnore( item ) );

  /** Get current and compare average position **/
  let currentAvgPosition = null;
  let compareAvgPosition = null;
  let variationAvgPosition = null;
  if( nonIgnoreDatas )
  {
    // get current position elements  nonIgnoreDatas.map( row => 
    let currentPositionElements = getPositions( nonIgnoreDatas, 1 );

    // get current sum positions
    const currentSumPos = currentPositionElements.reduce( ( a, b ) => b !== null ? a + parseFloat( b ) : a, 0 );

    if( !isNaN( currentSumPos ) )
      currentAvgPosition = currentSumPos / currentPositionElements.filter( element => element !== 'null' ).length;

    // get compare average position
    let comparePositionElements = getPositions( nonIgnoreDatas, 0 );

    // get compare sum positions
    let compareSumPos = comparePositionElements.filter( element => element !== 'null' ).reduce( ( a, b ) => b !== null ? a + parseFloat( b ) : a, 0 );

    if( !isNaN( compareSumPos ) )
      compareAvgPosition = compareSumPos / comparePositionElements.filter( element => element !== 'null' ).length;
    
    // get variation of avergae position
    if( !isNaN( currentAvgPosition ) && compareAvgPosition !== null )
      variationAvgPosition = compareAvgPosition - currentAvgPosition;
  }

  /** Get current and compare total keywords **/
  let currentTotalKeywords = null;
  let compareTotalKeywords = null;
  let variationTotalKeywords = null;
  if( 
    nonIgnoreDatas 
    && currentSnippetsDetails !== undefined 
    && compareSnippetsDetails !== undefined
  ){
    // get non ignore urls
    const nonIgnoreUrls = nonIgnoreDatas.map( data => data.label );

    // get current total keywords    
    currentTotalKeywords = [...new Set( currentSnippetsDetails.filter( snippet => 
      snippet?.position !== undefined
      && snippet.position !== null 
      && snippet.who === 'me' 
      && ( snippet.typeSnippet === 'SEO' || snippet.typeSnippet === 'Featured Snippet' )
      && nonIgnoreUrls.includes( snippet.url ) 
    ).map( snippet => snippet.keyword ))].length;

    // get compare total keywords
    compareTotalKeywords = [...new Set( compareSnippetsDetails.filter( snippet => 
      snippet?.position !== undefined
      && snippet.position !== null 
      && snippet.who === 'me' 
      && ( snippet.typeSnippet === 'SEO' || snippet.typeSnippet === 'Featured Snippet' )
      && nonIgnoreUrls.includes( snippet.url ) 
    ).map( snippet => snippet.keyword ))].length;
    
    // get variation of total keywords
    if( !isNaN( currentTotalKeywords ) && compareTotalKeywords > 0 )
      variationTotalKeywords = currentTotalKeywords - compareTotalKeywords;
  }

  /**
   * Return all positions for current url
   * @param {Array} datas 
   * @param {Bool} periodType Use 0 for compare periode or 1 for current periode
   * @returns Array
   */
  function getPositions( datas, periodType )  
  {
    return datas.map( row => 
    {
      // get sum of non ranked url on current keyword but rank on another one
      const sumRankOtherKeyword = [...new Set( 
        currentSnippetsDetails.filter( snippet => 
          snippet.who === "me"
          && snippet.url !== row.url
          && row['keywords-assigned'].filter( keyword => !row.keywords.includes( keyword ) ).includes( snippet.keyword )
          && ( snippet.typeSnippet === 'SEO' || snippet.typeSnippet === 'Featured Snippet' )
        ).map( row => row.keyword )
      )].length;

      if( 
        row[id]?.positions !== undefined 
        && row[id].positions instanceof Array
      )
        return [
          ...row[id].positions.map( position => parseInt( position.split( '|' )[periodType] ) !== 101 ? position.split( '|' )[periodType] : null ).filter( position => position !== null ), 
          ...new Array( 
            ( row.keywords.length + row['keywords-assigned'].filter( keyword => !row.keywords.includes( keyword ) ).length - sumRankOtherKeyword ) > row[id].positions.length ? 
              row.keywords.length + row['keywords-assigned'].filter( keyword => !row.keywords.includes( keyword ) ).length - row[id].positions.length - sumRankOtherKeyword
              : 0
          ).fill( 101 )
        ]
      else
        return new Array( ( row.keywords.length + row['keywords-assigned'].filter( keyword => !row.keywords.includes( keyword ) ).length ) ).fill( 101 )

    }).flat();
  }
  
  return (
    <div className='data-container'>
      <div className='left-container'>
        {(
          currentAvgPosition !== null 
          && !isNaN( currentAvgPosition )
          && currentAvgPosition > 0
        ) ? 
          <div>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentAvgPosition )}</div>
          : false
        }
        {(
          variationAvgPosition !== null 
          && !isNaN( variationAvgPosition )
          && variationAvgPosition !== 0
        ) ? 
          <div className='variation'>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, signDisplay: 'always' }).format( variationAvgPosition )}</div>
          : false
        }
      </div>
      <div className='right-container'>
        {(
          currentTotalKeywords !== null 
          && !isNaN( currentTotalKeywords )
          && currentTotalKeywords > 0
        ) ? 
          <div>{new Intl.NumberFormat('fr-FR').format( currentTotalKeywords )}</div>
          : false
        }
        {(
          variationTotalKeywords !== null 
          && !isNaN( variationTotalKeywords )
          && variationTotalKeywords !== 0
        ) ? 
          <div className='variation'>{new Intl.NumberFormat('fr-FR', { signDisplay: 'always' }).format( variationTotalKeywords )}</div>
          : false
        }
      </div>      
    </div>
  );
}

export default PerfsTotalCell;