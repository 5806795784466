/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getIsIgnore } from './../../../../helpers/datas';

function PerfsTotalCell( props ) 
{
  /** Get props **/
  const id = props.id;

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );

  // filter datas with non ignore value
  let nonIgnoreDatas = null;
  if( datas.filterResult )
    nonIgnoreDatas = datas.filterResult.filter( item => !getIsIgnore( item ) );

  /** Get current and compare average position **/
  let currentAvgPosition = null;
  let compareAvgPosition = null;
  let variationAvgPosition = null;
  if( nonIgnoreDatas )
  {
    // get current position elements
    let currentPositionElements = nonIgnoreDatas.map( row => 
      row[id]?.positions !== undefined && row[id].positions instanceof Array ? 
        Math.min( ...row[id].positions.map( position => parseInt( position.split( '|' )[1] ) ) )
        : 101
    ).flat();

    let currentSumPos = currentPositionElements.reduce( ( a, b ) => b !== null ? a + parseFloat( b ) : a, 0 );

    if( !isNaN( currentSumPos ) )
      currentAvgPosition = currentSumPos / currentPositionElements.length;

    // get compare average position
    let comparePositionElements = nonIgnoreDatas.map( row => 
      row[id]?.positions !== undefined && row[id].positions instanceof Array ? 
        Math.min( ...row[id].positions.map( position => parseInt( position.split( '|' )[0] ) ) )
        : 101
    ).flat();
    
    let compareSumPos = comparePositionElements.reduce( ( a, b ) => b !== null ? a + parseFloat( b ) : a, 0 );

    if( !isNaN( compareSumPos ) )
      compareAvgPosition = compareSumPos / comparePositionElements.length;
    
    // get variation of avergae position
    if( !isNaN( currentAvgPosition ) && compareAvgPosition !== null )
      variationAvgPosition = currentAvgPosition - compareAvgPosition;
  }

  /** Set starts key of reference value to get total sum **/
  let startKeyRefValue = 'snippets';
  
  let currentPercentValue = null;
  let comparePercentValue = null
  let variationPercent = null;
  if( nonIgnoreDatas )
  {
    /** Get percent for current period **/
    // get total summary value for current periode
    let currentTotalSumValue = nonIgnoreDatas.map( item => 
      Object.keys( item ).filter( key => 
        key.startsWith( startKeyRefValue ) 
        && key !== 'snippetsAds'
        && !getIsIgnore( item )
      ).map( key => 
        item[key].currentArea
      ).reduce( (a, b) => b ? a + parseInt( b ) : a, 0 )
    ).reduce( (a, b) => a + parseInt( b ), 0 );

    // // get columns snippets summary value
    let currentGrpSumValue = nonIgnoreDatas.filter( item => 
      item[id] 
    ).map( item => 
      item[id].currentArea 
    ).reduce( (a, b) => b ? a + parseInt( b ) : a, 0 );
      
    // get current percent value for current period
    if( currentTotalSumValue !== null && currentGrpSumValue !== null )
      currentPercentValue =  currentGrpSumValue / currentTotalSumValue;

    /** Get percent for compare period **/
    // get total summary value for compare periode
    let compareTotalSumValue = nonIgnoreDatas.map( item => 
      Object.keys( item ).filter( key => 
        key.startsWith( startKeyRefValue ) 
        && key !== 'snippetsAds'
        && !getIsIgnore( item )
      ).map( key => 
        item[key].compareArea
      ).reduce( (a, b) => b ? a + parseInt( b ) : a, 0 )
    ).reduce( (a, b) => a + parseInt( b ), 0 );

    // get columns snippets summary value for compare period
    let compareGrpSumValue = nonIgnoreDatas.filter( item => 
      item[id] 
    ).map( item => 
      item[id].compareArea 
    ).reduce( (a, b) => b ? a + parseInt( b ) : a, 0 );
    
    // get compare percent value    
    if( compareTotalSumValue !== null && compareGrpSumValue !== null )
      comparePercentValue =  compareGrpSumValue / compareTotalSumValue;

    // /** Get variation of percent for 2 periods **/ 
    if( !isNaN( currentPercentValue ) && !isNaN( comparePercentValue ) )
      variationPercent = ( currentPercentValue * 100 ) - ( comparePercentValue * 100 );
  }
  
  return (
    <div className='data-container'>
      <div className='left-container'>
        {(
          currentAvgPosition !== null 
          && !isNaN( currentAvgPosition )
          && currentAvgPosition > 0
        ) ? 
          <div>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentAvgPosition )}</div>
          : false
        }
        {(
          variationAvgPosition !== null 
          && !isNaN( variationAvgPosition )
          && variationAvgPosition !== 0
        ) ? 
          <div className='variation'>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, signDisplay: 'always' }).format( variationAvgPosition )}</div>
          : false
        }
      </div>
      <div className='right-container'>
        {(
          currentPercentValue !== null
          && !isNaN( currentPercentValue )
          && currentPercentValue > 0
        ) ? 
          <div>{new Intl.NumberFormat("fr-FR", { maximumFractionDigits: 0, style: "percent" }).format( currentPercentValue )}</div>
          :
          false
        }
        {(
          variationPercent !== null
          && !isNaN( variationPercent )
          && variationPercent !== 0
        ) ? 
          <div className='variation'>{new Intl.NumberFormat("fr-FR", { maximumFractionDigits: 1, style: "decimal", signDisplay: 'always' }).format( variationPercent )}</div>
          : false
        }
      </div>      
    </div>
  );
}

export default PerfsTotalCell;