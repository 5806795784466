/** Dependencies */
import React, {useState, useEffect} from 'react';
import Select, {components} from 'react-select';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { updateDatas } from './../../../../reducers/datas'

/** Helpers */
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';
import { getCurrentDate } from './../../../../helpers/functions';
import { getStylesSelectMenu } from './../../../../helpers/ui';
import { getPicto } from './../../../../helpers/pictos';
import { updateIntentionState } from '../../../../helpers/barometer/table';

/** Services */
import { getItem } from '../../../../services/LocaleStorage';

/** Components */
import IntentionsHistory from './../TableTooltips/IntentionsHistory';
import Loader from '../../../Loader';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

function Intention( props ) 
{
  /** Get props */
  const {
    id,
    keywords,
    automaticIntention,
    updatedIntention,
    lastUpdate,
    usersIDs
  } = props;
   
  /** Define intention ID */
  const intentionLoaderID = "intention" + id;

  /** Init state */
  const [updatedIntSelected, setUpdatedIntSelected] = useState( updatedIntention );
  const [intSelectedLastUpdate, setIntSelectedLastUpdate] = useState( null );
  const [intSelectedUsersIDs, setIntSelectedUsersIDs] = useState( null );

  /** Instance dispatch object */
	const dispatch = useDispatch();

  /** Get state from redux store */
  const datas = useSelector( state => state.datas.value );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value ); 

  /** Get all intentions values / labels */
  const optionsTableIntentions = globalConfig.barometer.intention;

  /** Get current date */
  const currentDate = getCurrentDate( "YYYYMMDDHHmmss" );

  /** Call function to add/remove/update value for intention for a keyword */
  const handleChange = ( values ) => 
  {
    if( updatedIntention !== values.value )
    {
      let actionType = null;

      // update case
      if( 
        intSelectedLastUpdate !== null
        && intSelectedLastUpdate.toString().substring( 0, 8 ) === currentDate.toString().substring( 0, 8 )
        && intSelectedUsersIDs !== null
        && intSelectedUsersIDs.includes( parseInt( getItem( 'userID' ) ) )
      )
        actionType = 'update';

      // add case
      else        
        actionType = 'add';

      if( actionType !== null )
      {
        callWebservice(
          intentionLoaderID,
          actionType,
          actionType + '-intention',
          dispatch,
          selectedInstance,
          {
            keywords: keywords, 
            intention: values.value !== null ? values.value : null,
            user_id: parseInt( getItem( 'userID' ) ),
            currentDate: parseInt( currentDate ),
            device: selectedFilters.devLoc.deviceValue,
            location: selectedFilters.devLoc.cityValue
          },
          { 
            function: 'callbBackUpdateIntentionState'
          }
        );        
      }
    }
  }

  /** Update picto womanman VS computer when intention has changed  */ 
  useEffect(() => 
  {    
    // update intention value
    if( updatedIntention !== undefined )
      setUpdatedIntSelected( updatedIntention );

    // update action date
    if( lastUpdate !== undefined )
      setIntSelectedLastUpdate( lastUpdate );

    // update list users ids
    if( usersIDs !== undefined )
      setIntSelectedUsersIDs( usersIDs );

  }, [props]);

  return (
    <div className="intention-container pointer">

      <Loader 
        loaderID={intentionLoaderID} 
        loaderStyle={{width:'15', stroke:'#e43e21'}} 
        callBackFcts={{
          callbBackUpdateIntentionState: results => dispatch( updateDatas( { 'globalResult': updateIntentionState( results, datas.globalResult ) } ) ) 
        }}
        globalCallBack={undefined} 
      />

      <div
        data-tip={'intentionsHistory-' + id} 
        data-for={'intentionsHistory-' + id} 
        data-arrow-color="#e43e21" 
        data-place="right"
      >
        <Select 
          className="react-select"
          classNamePrefix="react-select"
          options={optionsTableIntentions}
          styles={getStylesSelectMenu()}
          placeholder={"intention"}
          value={
            updatedIntSelected !== null ? 
              optionsTableIntentions.filter( int => int.value === updatedIntSelected )
            : automaticIntention !== null ?
              optionsTableIntentions.filter( int => int.value === automaticIntention )
            : null
          }
          components={{
            IndicatorSeparator: () => null,
            Option: props => 
              <components.Option {...props}>
                {props.data.label}
                {
                  props.data.value
                  && props.data.value === automaticIntention ? 
                    getPicto( 'Gear', { size: '1rem' } ) 
                  : false
                }
              </components.Option>,
            ValueContainer: ({ children, ...props }) => 
              <components.ValueContainer {...props}>
                {children}
                <div className='picto'>
                  {
                    updatedIntSelected !== null ?
                      getPicto( 'GenderNeutral', { width: '1.1rem', height: '1.1rem', fill: 'white' } ) 
                    : automaticIntention !== null ?
                      getPicto( 'Gear', { size: '0.8rem' } ) 
                    : false
                  }
                </div>
              </components.ValueContainer>
          }}
          onChange={ ( values ) => handleChange( values ) }
        />
        <IntentionsHistory id={'intentionsHistory-' + id} keywords={keywords} />
      </div>
    </div>
  );
}

export default Intention;