/** Dependencies **/
import React from 'react';
import { 
  VictoryChart, 
  VictoryLine, 
  VictoryAxis, 
  VictoryGroup,
  VictoryScatter,
  createContainer,
  VictoryTooltip
} from 'victory';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import DataVizTooltipPerfVis from './../../DataVizTooltips/DataVizTooltipPerfVis';

/** Helpers **/
import { rewriteTrendObjectData } from './../../../../../helpers/barometer/charts.js';

function DataVizPerfVis( props ) 
{
  /** Get props **/
  const grpDomainDisplay = props.grpDomainDisplay;
  const compareDisplay = props.compareDisplay;

  /** Get state from redux store **/
  const instanceConfig = useSelector( state => state.config.value );  
  const togglers = useSelector( state => state.togglers.value ); 

  /** Get config values **/
  const grpDomains = instanceConfig.groups;

  /** Create Custom Victory Container for cursor and voronoi **/
  const VictoryCursorVoronoiContainer = createContainer( "voronoi", "cursor" );

  /** Init datas for chart **/
  let currentPerfTrend = {};
  let comparePerfTrend = {};
  let currentDetailsPerfTrend = {};
  let compareDetailsPerfTrend = {};

  /** Rewrite array datas **/
  if( 
    props.currentPerfTrend
    && props.currentPerfTrend.grpDomains
    && Object.keys( props.currentPerfTrend.grpDomains ).length > 0
  ){
    // Rewrite Current Perf Trend datas
    currentPerfTrend = rewriteTrendObjectData( 
      props.currentPerfTrend.grpDomains,
      togglers['radio-view-option'] ? 'month' : 'week'
    );

    // Set Current Details Perf Trend datas
    currentDetailsPerfTrend = props.currentPerfTrend.detailGrpDomains;

    if( 
      props.comparePerfTrend
      && props.comparePerfTrend.grpDomains
      && Object.keys( props.comparePerfTrend.grpDomains ).length > 0
    ){
      // Rewrite Compare Perf Trend datas
      comparePerfTrend = rewriteTrendObjectData( 
        props.comparePerfTrend.grpDomains,
        togglers['radio-view-option'] ? 'month' : 'week'
      );
      
      // Set Compare Details Perf Trend datas
      compareDetailsPerfTrend = props.comparePerfTrend.detailGrpDomains;
    }
  };

  /** Get if all displayed values = 0 to set default dependant axis values */
  // get current sum values
  let valuesSumDisplay = Object.keys( grpDomainDisplay ).filter( grp => grpDomainDisplay[grp] ).map( grp => 
    currentPerfTrend[grp] ? currentPerfTrend[grp].reduce( ( a, b ) => a + b.y, 0 ) : 0 
  ).reduce( (a, b) => a + b, 0 );

  // get compare sum values
  valuesSumDisplay += Object.keys( grpDomainDisplay ).filter( grp => grpDomainDisplay[grp] ).map( grp => 
    comparePerfTrend[grp] ? comparePerfTrend[grp].reduce( ( a, b ) => a + b.y, 0 ) : 0
  ).reduce( (a, b) => a + b, 0 );

  return (
    <React.Fragment>
      {( 
        Object.keys( currentPerfTrend ).length > 0 
        && Object.keys( grpDomainDisplay ).filter( grp => grpDomainDisplay[grp] ).length > 0 
      ) ?
        <VictoryChart
          width={1300}
          height={250}
          padding={{ top: 10, bottom: 30, left: 50, right: 20 }}
          minDomain={{y: 0}}
          containerComponent={
            <VictoryCursorVoronoiContainer 
              voronoiBlacklist={["currentLine", "compareLine"]}
              voronoiDimension="x"
              cursorDimension="x"
              labels={ () => ' ' } // space value needed to display label component
              labelComponent={ 
                <VictoryTooltip
                  {...props}
                  text={() => ''}
                  flyoutComponent={
                    <DataVizTooltipPerfVis
                      {...props}
                      currentDetails={currentDetailsPerfTrend} 
                      compareDetails={compareDetailsPerfTrend} 
                      grpDomainDisplay={grpDomainDisplay}
                      compareDisplay={compareDisplay}
                    /> 
                  }
                />
              }  
            />
          }
        >
          <VictoryAxis
            tickValues={currentPerfTrend[Object.keys( currentPerfTrend )[0]].map( elem => elem.tickValue )}
            style={{
              tickLabels: {
                fontSize: 6.5,
                fontFamily: "Roboto",
                fontWeight: "bold",
                fill: "#626262",
                angle: -45       
              },
              grid: {
                stroke: '#f5f2f2', 
                strokeWidth: 1
              }
            }}
          />
          <VictoryAxis dependentAxis 
            offsetX={50}
            tickValues={valuesSumDisplay === 0 ? [0] : []}
            tickFormat={ t => t + '%'}
            style={{
              tickLabels: {
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "300",
                fill: "#707070"
              },
              grid: {
                stroke: '#D8D8D8', 
                strokeWidth: 0.5
              }
            }}
          />
          {Object.keys( currentPerfTrend ).map( ( key, index ) => 
            grpDomainDisplay[key] ?
              <VictoryGroup 
                key={index}
                data={currentPerfTrend[key]}
              >
                <VictoryScatter
                  name={"currentScatter" + index}
                  style={{
                    data: {
                      fill: grpDomains.length > 0 ? grpDomains.filter( grp => grp.value === key )[0].currentColor : null
                    }
                  }}
                  size={({ active }) => active ? 5 : 3 } 
                />
                <VictoryLine            
                  name="currentLine"
                  style={{ 
                    data: { 
                      stroke: grpDomains.length > 0 ? grpDomains.filter( grp => grp.value === key )[0].currentColor : null
                    } 
                  }}
                />                
              </VictoryGroup>
            : false                
          )}
          {compareDisplay ?
            Object.keys( comparePerfTrend ).map( ( key, index ) =>
              grpDomainDisplay[key] ?
                <VictoryGroup 
                  key={index}
                  data={comparePerfTrend[key]}
                >
                  <VictoryScatter
                    name={"compareScatter" + index}
                    style={{
                      data: {
                        fill: grpDomains.length > 0 ? grpDomains.filter( grp => grp.value === key )[0].currentColor : null
                      }
                    }}
                    size={({ active }) => active ? 5 : 3 } 
                  />
                  <VictoryLine
                    name="compareLine"
                    style={{ 
                      data: { 
                        stroke: grpDomains.length > 0 ? grpDomains.filter( grp => grp.value === key )[0].currentColor : null,
                        strokeDasharray: 3,
                        strokeWidth: 1
                      }
                    }}
                  />                  
                </VictoryGroup>
              : false
            )
            : false
          }
        </VictoryChart>
        : false
      }
    </React.Fragment>
  )
}

export default DataVizPerfVis;