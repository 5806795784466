/** Components **/
import DefaultSelectFilterToolTip from './../../../components/Dashboards/Barometer/FilterToolTips/DefaultSelectFilterToolTip';
import TableCellIntention from './../../../components/Dashboards/Barometer/TableCells/Intention';
import TableHeaderTitleWithPicto from './../../../components/Dashboards/Barometer/TableHeaders/TitleWithPicto';

export const IntentionColumn = ( widthColumns ) => 
{
  return {
    accessor: "intention",
    width: widthColumns['intention'],
    title: "int.",
    className: "col-intention col-center",
    Filter: DefaultSelectFilterToolTip,
    filter: "multiFieldsCustomFilter",
    filterTitle: "Filter par intention de recherche",
    disableSortBy: true,
    Header: (props) => 
      <TableHeaderTitleWithPicto 
        {...props.getToggleAllPageRowsSelectedProps()} 
        id={props.column.id} 
        title={props.column.title} 
      />,
    Total: '',
    Cell: (props) =>
      <TableCellIntention 
        {...props.row.getToggleRowSelectedProps()} 
        id={props.row.id} 
        keywords={props.cell.row.original.keywords} 
        lastUpdate={
          props?.value?.lastUpdate !== undefined ? 
            props.value.lastUpdate 
            : null
        } 
        usersIDs={
          props?.value?.usersIDs !== undefined ? 
            props.value.usersIDs 
            : null
        }
        automaticIntention={
          props?.value?.automaticIntention !== undefined 
          && props.value.automaticIntention !== null ? 
            props.value.automaticIntention 
            : null
        } 
        updatedIntention={
          props?.value?.updatedIntention !== undefined 
          && props.value.updatedIntention !== null ? 
            props.value.updatedIntention 
            : null
        }
      />
  }
}