/** Dependencies **/
import React from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../../../reducers/selectedHeaderFilters';

/**
 * Define UI for Toggler Fitler
 * @param {object} props
 * @returns ReactDOM with Button filter
 */
function TogglerFilter ( props )
{
  /** Get props **/
  const id = props.column.id;
  const label = props.label;
  const title = props.title;
  const fieldName = props.fieldName;
  const typeFilter = props.typeFilter;
  const filterParams = props.filterParams;

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );

  /** Valid filter  **/
  const updateFilter = ( event ) => 
  {
    const label = event.currentTarget.textContent;

    let payload = { 
      value: { 
        result: true, 
        type: typeFilter,
        field: fieldName 
      }, 
      label: label, 
      type: 'text',
      params: filterParams
    };

    if( selectedHeaderFilters[id] )
    {
      // get current selected header filter with fieldName    
      let currentSelectedHeaderFilterSnippet = selectedHeaderFilters[id].filter( filter => 
        filter.value.field === fieldName
      );

      // set payload.value.result
      if( currentSelectedHeaderFilterSnippet.length === 1 )      
        payload = { value: null, field: fieldName };
    }

    // save value into redux store
    dispatch( updateSelectedHeaderFilters( { filterName: id, value: payload } ));   
  }

  return (
    <React.Fragment>
      { title !== '' ? <h5>{title}</h5> : '' }
      <ul className="preselect">
        <button 
          className={
            (
              selectedHeaderFilters[id]
              && selectedHeaderFilters[id].filter( filter => 
                filter.value.field === fieldName 
                && filter.value.result === true
              ).length > 0
            ) ? "on" : ""
          } 
          onClick={ e => updateFilter( e ) } 
        >{label}</button>
      </ul>
    </React.Fragment>
  )
}

export default TogglerFilter;