/** Dependencies **/
import React, {useState, useRef, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import Autosuggest from 'react-autosuggest';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateDatas } from './../../../../reducers/datas'

/** Components **/
import Loader from '../../../Loader';

/** Helpers **/
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';
import { updateExpectedURLState } from './../../../../helpers/barometer/table';
import { getPicto } from './../../../../helpers/pictos';
import {
  getSuggestionValue,
	onSuggestionsFetchRequested,
	renderSuggestion
} from './../../../../helpers/autosuggest';

function ExpectedURL( props ) 
{
	// Get props
	const{
		id,
		keywords,
		date,
		accountID,
		automaticExpectedUrl,
		updatedExpectedUrl,
		typeExpectedUrl
	}	= props;

	// Define ref
	const tooltipExpectedUrl = useRef( null );
  const inputElement = useRef( null );

  // /** Init state **/
	const [currentExpectedUrl, setCurrentExpectedUrl] = useState( null );
	const [suggestions, setSuggestions] = useState( [] );
	const [inputValue, setInputValue] = useState( '' );
	const [optionFilter, setOptionFilter] = useState( 'bestclickedurl' );
	const [gscUrls, setGscUrls] = useState( [] );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
	const selectedFilters = useSelector( state => state.selectedFilters.value );
	const selectedInstance = useSelector( state => state.selectedInstance.value );
	
	/** Return gsc urls to auto suggestion */
	const fetchRequestedExpectedURL = value => 
	{
		return gscUrls.filter( url => url.includes( value ) ).slice( 0, 10 );
	}

	/** Save current expected url to DB */
	const publishExpectedURL = ( urlValue, optionFilter ) => 
	{
		if( urlValue !== '' && urlValue !== null )
		{
			callWebservice(
				id,
				id,
				'add-expected-url',
				dispatch,
				selectedInstance,
				{
					keywords: keywords,
					expectedUrl: urlValue,
					typeExpectedUrl: optionFilter,
					currentDate: date,
					user_id: accountID,
					device: selectedFilters.devLoc.deviceValue,
					location: selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue
				},
				{ 
					function: 'callbBackUpdateExpectedURLState'
				}
			);
		}
	}

	/** Set option filters and current expected url when change option */
	const changeOptionHandler = ( typeValue, urlValue ) => 
	{
		// set option filter with selected filter
		setOptionFilter( typeValue );

		// set current expected url with selected value
		setCurrentExpectedUrl( urlValue );
	}

	/**
	 * Load GSC urls
	 */
	const loadGscUrls = () => 
	{
		// GSC
		if( selectedFilters.devLoc !== undefined )
		{  
			// GSC current details
			callWebservice(
				id,
				id + '-gscUrls',
				'gsc',
				dispatch,
				selectedInstance,
				{
					cols: ["DISTINCT url"],
					where: {
						locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
						devices: [selectedFilters.devLoc.deviceValue]
					}
				},
				{ function: 'setGscUrls' }
			);
		} else
			setGscUrls( [] );     
	}

	/** Set current expected url with saved value */
	useEffect( () => 
	{
		/** Set current expected URL */
		if( updatedExpectedUrl !== null )
			setCurrentExpectedUrl( updatedExpectedUrl );
		else if( automaticExpectedUrl !== null )
			setCurrentExpectedUrl( automaticExpectedUrl );

		/** Set option filter with current expected url */
		if( typeExpectedUrl !== null )
			setOptionFilter( typeExpectedUrl );	

	}, []);

	useEffect( () => 
	{
		/** Set input value if option other url */
		if( optionFilter === 'otherurl' )
			setInputValue( currentExpectedUrl );
		else
			setInputValue( '' );

	}, [currentExpectedUrl])

	// set Autosuggest input props
  const inputProps = {
    placeholder: '',
    value: inputValue || '',
    type: 'text',    
    onChange: ( e, { newValue } ) => setInputValue( newValue ),
		onFocus: () => setOptionFilter( 'otherurl' )
  };

	return (
		<ReactTooltip 
			id={props.id}
			ref={tooltipExpectedUrl}
			className="tooltip red expectedUrl"
			effect='solid'
			delayHide={200}
			delayShow={0}
			delayUpdate={100}
			afterShow={ () => loadGscUrls() }
			afterHide={() => {
				tooltipExpectedUrl.current.tooltipRef.style.left = null;
				tooltipExpectedUrl.current.tooltipRef.style.top = null;
			}}
			globalEventOff={'click'}
			isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
				let height = node.offsetHeight;
        
        return {
          left: - width / 2 - 170,
          top: - height / 2 + 52
        };
      }}
		>
			<div className='tooltip-container expectedUrl'>

				<Loader 
					loaderID={id} 
					loaderStyle={{ width: '15', stroke: '#e43e21' }} 
					callBackFcts={{
						callbBackUpdateExpectedURLState: results => dispatch( updateDatas( { 'globalResult': updateExpectedURLState( results, datas.globalResult ) } ) ) ,
						setGscUrls: results => setGscUrls( results.map( result => result.url ) )
					}}
					globalCallBack={ undefined } 
				/> 
				
				<h4>Association URL &lt;&gt; mot clé</h4>

				<div className='options-container'>

					{/* Best click url */}
					{automaticExpectedUrl !== null ?
						<div className='option'>
							<input 
								onChange={ e => changeOptionHandler( e.target.value, automaticExpectedUrl ) } 
								type="radio" 
								value="bestclickedurl" 
								name={"filter-type-" + id} 
								id={"option-filter" + id + "-1"} 
								checked={ optionFilter === 'bestclickedurl' ? true : false }
							/>
							<label htmlFor={"option-filter" + id + "-1"}>Plus grand nombre de clics : </label>
							<a target='_blank' rel="noreferrer" href={automaticExpectedUrl}>{automaticExpectedUrl}&nbsp;{getPicto( 'ExternalLinkOutline', {size: '1rem', color: 'white'} )}</a>
						</div>
						: false
					}

					{/* One url of all gsc urls */}
					{gscUrls.length > 0 ?
						<div className='option'>
							<input 
								onChange={ e => changeOptionHandler( e.target.value, inputElement.current.input.value ) } 
								type="radio" 
								value="otherurl" 
								name={"filter-type-" + id} 
								id={"option-filter" + id + "-2"} 
								checked={ optionFilter === 'otherurl' ? true : false }
							/>
							<label htmlFor={"option-filter" + id + "-2"}>Autre URL existante : </label>	
							{	optionFilter === 'otherurl' 
								&& inputValue !== null
								&& inputValue !== '' ?
									<a href={inputValue} target="_blank" rel="noreferrer">{inputValue}&nbsp;{getPicto( 'ExternalLinkOutline', {size: '1rem', color: 'white'} )}</a>
									: false
							}
							<Autosuggest
								suggestions={suggestions}
								onSuggestionsFetchRequested={ values => onSuggestionsFetchRequested( values, fetchRequestedExpectedURL, setSuggestions ) }
								alwaysRenderSuggestions={true}
								getSuggestionValue={ suggestion => 
								{
									// set option filter to other url
									setOptionFilter( 'otherurl' );

									// return value of selected suggestion
									return getSuggestionValue( suggestion );
								}}
								renderSuggestion={ suggestions => renderSuggestion( suggestions, inputValue ) }
								inputProps={inputProps}
								ref={inputElement}
							/>
						</div>
						: false
					}

					{/* New url */}
					<div className='option'>
						<input 
							type="radio" 
							value="newurl" 
							name={"filter-type-" + id} 
							id={"option-filter" + id + "-3"} 
							checked={ optionFilter === 'newurl' ? true : false }
							onChange={ e => 
							{
								changeOptionHandler( e.target.value, '' );
								setInputValue( '' );
							}}
						/>
						<label htmlFor={"option-filter" + id + "-3"}>Nouvelle page à créer : </label>	
						{	optionFilter === 'newurl' 
							&& currentExpectedUrl !== null 
							&& currentExpectedUrl !== automaticExpectedUrl 
							&& !gscUrls.includes( currentExpectedUrl ) ?
								<a target="_blank" rel="noreferrer" href={currentExpectedUrl}>{currentExpectedUrl}&nbsp;{getPicto( 'ExternalLinkOutline', {size: '1rem', color: 'white'} )}</a>
								: false
							}
						<input 
							type="text" 
							value={ 
								optionFilter === 'newurl'
								&& currentExpectedUrl !== automaticExpectedUrl 
								&& !gscUrls.includes( currentExpectedUrl ) ? 
									currentExpectedUrl : ''
							} 
							placeholder="www.domain.com/page.html"
							onChange={ e => setCurrentExpectedUrl( e.target.value ) } 
							onFocus={ () => setOptionFilter( 'newurl' ) }
						/>
					</div>
				</div>

				<div className="confirm-container">
					<button className="confirm" onClick={() => publishExpectedURL( optionFilter === 'otherurl' ? inputValue : currentExpectedUrl, optionFilter )}>APPLIQUER</button>
				</div>

			</div>			
		</ReactTooltip>
	);
}

export default ExpectedURL;