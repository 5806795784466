/** Dependencies **/
import React, {useState, useEffect} from 'react';
import Select, {components} from 'react-select';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateDatas } from './../../../../reducers/datas'

/** Helpers **/
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';
import { getCurrentDate } from './../../../../helpers/functions';
import { getStylesSelectMenu } from './../../../../helpers/ui';
import { getPicto } from './../../../../helpers/pictos';
import { updatePageTypeState } from '../../../../helpers/barometer/table';
import { getItem } from '../../../../services/LocaleStorage';

/** Components **/
import PageTypesHistory from './../TableTooltips/PageTypesHistory';
import Loader from '../../../Loader';

/** JSON */
import globalConfig from './../../../../assets/json/config.json';

function PageTypes( props ) 
{
  /** Get props */
  const {
    id,
    label,
    automaticPageTypeID,
    updatedPageTypeID,
    lastUpdate,
    usersIDs
  } = props;  

  /** Define page type loader ID */
  const pageTypeLoaderID = "pageType" + id;

  /** Get state from redux store */
  const barometerContext = useSelector( state => state.barometerContext.value );  
  const selectedInstance = useSelector( state => state.selectedInstance.value ); 
  const datas = useSelector( state => state.datas.value );
  const instanceConfig = useSelector( state => state.config.value );

  /** Init state **/
  const [updatedPageTypeSelectedID, setUpdatedPageTypeSelectedID] = useState( updatedPageTypeID );
  const [pageTypeSelectedLastUpdate, setPageTypeSelectedLastUpdate] = useState( null );
  const [pageTypeSelectedUsersIDs, setPageTypeSelectedUsersIDs] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get page types datas from config file */
  const pageTypes = instanceConfig.pageTypes;

  /** Get current date */
  const currentDate = getCurrentDate( "YYYYMMDDHHmmss" );
  
  /** Loading options page types from redux values **/
  let optionsTablePageType = [];
  if( pageTypes instanceof Array )
  {
    pageTypes.forEach( pageType => {
      optionsTablePageType.push({
        value: pageType.id,
        label: pageType.label.toUpperCase()
      })
    });
  }

  /** Call function to add/remove/update value for page type for a url **/
  const handleChange = ( values ) => 
  {
    if( updatedPageTypeID !== values.value )
    {
      let actionType = null;

      // update case
      if( 
        pageTypeSelectedLastUpdate !== null
        && pageTypeSelectedLastUpdate.toString().substring( 0, 8 ) === currentDate.toString().substring( 0, 8 )
        && pageTypeSelectedUsersIDs !== null
        && pageTypeSelectedUsersIDs.includes( parseInt( getItem( 'userID' ) ) )
      )
        actionType = 'update';

      // add case
      else        
        actionType = 'add';
      
      if( actionType !== null )
      {
        callWebservice(
          pageTypeLoaderID,
          actionType,
          actionType + '-page-type',
          dispatch,
          selectedInstance,
          {
            urls: [label], 
            pagetype_id: values.value !== null ? parseInt( values.value ) : null,
            user_id: parseInt( getItem( 'userID' ) ),
            currentDate: parseInt( currentDate ),
            context: barometerContext
          },
          { 
            function: 'callbBackUpdatePageTypeState'
          }
        );        
      }
    }
  }

  // update menu if mass page type used 
  useEffect(() => 
  {    
    // update page type ID
    if( updatedPageTypeID !== undefined )
      setUpdatedPageTypeSelectedID( updatedPageTypeID );

    // update page type date
    if( lastUpdate !== undefined )
      setPageTypeSelectedLastUpdate( lastUpdate );

    // update list users ids
    if( usersIDs !== undefined )
      setPageTypeSelectedUsersIDs( usersIDs );

  }, [props]);

  return (
    <div className="page-type-container pointer">

      <Loader 
        loaderID={pageTypeLoaderID} 
        loaderStyle={{width:'15', stroke:'#e43e21'}} 
        callBackFcts={{
          callbBackUpdatePageTypeState: results => dispatch( updateDatas( { 'globalResult': updatePageTypeState( results, datas.globalResult ) } ) ) 
        }}
        globalCallBack={undefined}
      />

      <div
        data-tip={'pageTypesHistory-' + id} 
        data-for={'pageTypesHistory-' + id} 
        data-arrow-color="#e43e21" 
        data-place="right"
      >
        <Select 
          className="react-select"
          classNamePrefix="react-select"
          options={optionsTablePageType}
          styles={getStylesSelectMenu()}
          placeholder={"Type de page"}
          value={
            updatedPageTypeSelectedID !== null ?
              optionsTablePageType.filter( int => parseInt( int.value ) === updatedPageTypeSelectedID )
            : automaticPageTypeID !== null ?
              optionsTablePageType.filter( int => parseInt( int.value ) === automaticPageTypeID )
            : null
          }
          components={{
            IndicatorSeparator: () => null,
            Option: props => 
              <components.Option {...props}>
                {props.data.label}
                {
                  props.data.value
                  && parseInt( props.data.value ) === automaticPageTypeID ? 
                    getPicto( 'Gear', { size: '1rem' } ) 
                  : false
                }
              </components.Option>,
            ValueContainer: ({ children, ...props }) => 
              <components.ValueContainer {...props}>
                {children}
                <div className='picto'>
                  {
                    updatedPageTypeSelectedID !== null ?
                      getPicto( 'GenderNeutral', { width: '1.1rem', height: '1.1rem', fill: 'white' } ) 
                    : automaticPageTypeID !== null ?
                      getPicto( 'Gear', { size: '0.8rem' } ) 
                    : false
                  }
                </div>
              </components.ValueContainer>
          }}
          onChange={ ( values ) => handleChange( values ) }
        />
        <PageTypesHistory 
          id={'pageTypesHistory-' + id} 
          label={label} 
        />
      </div>

    </div>
  );
}

export default PageTypes;