import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from './../store';

const initialState = {
  value: {}
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateConfig } = configSlice.actions

export default configSlice.reducer